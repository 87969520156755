import { createSlice, isAnyOf, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import {
  HeadOrganizationResponse,
  OrganisationHeadType,
  OrganisationStateType,
  OrganisationSubscriptionType,
  PaymentCardType,
  PlanType,
} from 'types';
import { clearAccountState, logout } from '../actions/common';
import {
  cancelSubscription,
  createHead,
  deactivateTelegram,
  deactivateWhatsApp,
  editHead,
  fetchHead,
  payForTelegram,
  payForWhatsApp,
  setPaymentCard,
  subscribe,
} from '../asyncActions/organisation';
import { ThunkCommon } from '../index';
import { RootState } from '../store';
import { extractHeadOrganization } from '../utils';

const initialState: OrganisationStateType = {
  isLoading: false,
  initialized: true,
  head: {
    id: '',
    currency: '',
    minTimeSlot: 0,
    photo: '',
    employeesNum: '',
    whatsApp: false,
    telegram: false,
    summary: null,
    address: null,
    registrationId: '',
    name: {
      id: '',
      langId: '',
      text: '',
    },
    contact: {
      id: '',
      phone: [
        {
          id: '',
          code: '',
          number: '',
          contactId: '',
        },
      ],
      email: {
        id: '',
        email: '',
      },
    },
    headOrgCategory: [],
    subscription: [],
    paymentCard: [],
  },
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    clearHead: () => initialState,
    setHeadInitialize: (state, action) => {
      state.initialized = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createHead.fulfilled, state => {
        state.initialized = false;
      })
      .addCase(fetchHead.fulfilled, (state, action: PayloadAction<HeadOrganizationResponse>) => {
        extractHeadOrganization(state, action.payload);
        state.head.subscription = action.payload.subscription;
      })
      .addCase(editHead.fulfilled, (state, action: PayloadAction<Omit<HeadOrganizationResponse, 'subscription'>>) => {
        extractHeadOrganization(state, action.payload);
      })
      .addCase(setPaymentCard.fulfilled, (state, action) => {
        state.head.paymentCard.forEach(paymentCard => {
          paymentCard.default = paymentCard.id === action.payload.id;
        });
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        const subscription = state.head.subscription.find(sub => sub.id === action.payload.id);
        if (subscription) subscription.cancelled = Boolean(action.payload.cancelled);
      })
      .addCase(ThunkCommon.activateSpecialist.fulfilled, state => {
        if (state.head.employeesNum) {
          state.head.employeesNum = `${Number(state.head.employeesNum) + 1}`;
        }
      })
      .addCase(payForTelegram.fulfilled, (state, action) => {
        if (action.payload.telegram) {
          state.head.telegram = action.payload.telegram;
          state.head.subscription[0].telegram = true;
        }
      })
      .addCase(payForWhatsApp.fulfilled, (state, action) => {
        if (action.payload.whatsApp) {
          state.head.whatsApp = action.payload.whatsApp;
          state.head.subscription[0].whatsApp = true;
        }
      })
      .addCase(deactivateTelegram.fulfilled, (state, action) => {
        if (action.payload.message === 'Success') {
          state.head.telegram = action.payload.telegram;
        }
      })
      .addCase(deactivateWhatsApp.fulfilled, (state, action) => {
        if (action.payload.message === 'Success') {
          state.head.whatsApp = action.payload.whatsApp;
        }
      })
      .addCase(clearAccountState, state => ({ ...state, initialized: state.initialized }))
      .addCase(logout, () => initialState)
      .addMatcher(isAnyOf(fetchHead.rejected, createHead.rejected), state => {
        state.head = initialState.head;
      })
      .addMatcher(isPending, state => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isRejected, state => {
        state.isLoading = false;
      });
  },
});

export const { clearHead, setHeadInitialize } = organisationSlice.actions;

export const selectHeadState = (state: RootState) => state.organisation;
export const selectHead = (state: RootState): OrganisationHeadType => state.organisation.head;
export const selectCurrency = (state: RootState): string => state.organisation.head.currency;
export const selectHeadOrgWhatsApp = (state: RootState): boolean => state.organisation.head.whatsApp;
export const selectHeadOrgTelegram = (state: RootState): boolean => state.organisation.head.telegram;
export const selectActiveSubscription = (state: RootState): OrganisationSubscriptionType | null =>
  isEmpty(state.organisation.head?.subscription) ? null : state.organisation.head.subscription[0];
export const selectEmployeeNum = (state: RootState): number => Number(state.organisation.head.employeesNum);
export const selectPaymentCards = (state: RootState): PaymentCardType[] => state.organisation.head.paymentCard;
export const selectHeadOrgAddressId = (state: RootState): Nullable<string> =>
  state.organisation.head.address?.id ?? null;

export const selectTelegramSubscription = (state: RootState): boolean =>
  Boolean(state.organisation.head.subscription[0].telegram);
export const selectSubscriptionPlan = (state: RootState): PlanType | null =>
  isEmpty(state.organisation.head?.subscription) ? null : state.organisation.head.subscription[0].plan;

export const ThunkOrganisation = {
  createHead,
  fetchHead,
  editHead,
  subscribe,
  setPaymentCard,
  cancelSubscription,
  payForTelegram,
  payForWhatsApp,
  deactivateTelegram,
  deactivateWhatsApp,
};

export default organisationSlice.reducer;
