import { BodyLarge, BodySmall, Caption, colors, Flex, Link, Separator, TrashIcon } from '@beauty/beauty-market-ui';
import { Box, IconBox } from '../../style';

type ServiceItemProps = {
  category: string;
  name: string;
  price: string;
  separator: boolean;
  isDesktop: boolean;
  onDelete?: () => void;
};

export const ServiceItem = ({ category, name, price, separator, onDelete, isDesktop }: ServiceItemProps) => (
  <>
    <Box alignItems="center" justifyContent="start" gap="16px" available={onDelete} width="100%">
      <Flex width="100%" alignItems="center" justifyContent="space-between" gap="8px">
        <Flex flexDirection="column">
          <Caption color={isDesktop ? colors.grey.dark : colors.black.standard}>{category}</Caption>
          <BodyLarge>{name}</BodyLarge>
        </Flex>
        <BodySmall large lowline minWidth="max-content">
          {price}
        </BodySmall>
      </Flex>
      <IconBox>
        <Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />
      </IconBox>
    </Box>
    {separator && <Separator m="16px 0" />}
  </>
);
