import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { BottomSheet, Button, Popup as PopupUI, Separator, MediaQueries } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../hooks';
import { Loader } from '../Loader';
import { Footer } from './style';

type PopUpPropsType = {
  title: string;
  description?: string;
  onSubmit: () => void;
  onClose: () => void;
  onBack?: () => void;
  cancel?: string;
  confirm?: string;
  isLoading?: boolean;
  children?: ReactNode;
  isOpen?: boolean;
  detent?: 'content-height' | 'full-height';
  mediaQuery?: MediaQueries;
  disabled?: boolean;
};

const Popup = ({
  title,
  description,
  onSubmit,
  onClose,
  cancel,
  confirm,
  isLoading,
  children,
  onBack,
  isOpen = true,
  detent = 'content-height',
  mediaQuery = 'md',
  disabled = false,
}: PopUpPropsType) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen(mediaQuery);

  const FooterBody = (
    <Footer>
      <Button design="secondary" size="large" width="100%" onClick={onClose} disabled={isLoading}>
        {cancel || t('button.cancel')}
      </Button>
      <Button size="large" width="100%" onClick={onSubmit} disabled={isLoading || disabled}>
        {isLoading ? <Loader type="spinner" /> : confirm || t('button.delete')}
      </Button>
    </Footer>
  );

  const Component = isDesktop ? (
    <PopupUI title={title} info={description} open handleClose={onClose} FooterBody={FooterBody}>
      {children}
      <Separator mt="40px" />
    </PopupUI>
  ) : (
    <BottomSheet
      label={title}
      descriptor={description}
      handleClose={onClose}
      onClose={onClose}
      onBack={onBack}
      FooterBody={FooterBody}
      isOpen={isOpen}
      content={children}
      detent={detent}
    />
  );

  return createPortal(Component, document.getElementById('root') as HTMLElement);
};

export default Popup;
