import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Popup as PopupUI,
  Button,
  Flex,
  Separator,
  Select,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { Loader } from 'components';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAppointments, ThunkAppointments } from 'store/redux-slices/appointmentsSlice';
import { AppointmentsAction, CancelAllAppointmentParams } from 'types/appointment';
import { InfoCheckbox } from './InfoCheckbox';

type ReasonType = {
  id: number;
  value: string;
};

const reasonOptions: ReasonType[] = [
  { id: 0, value: 'clientsRequest' },
  { id: 1, value: 'schedule' },
  { id: 2, value: 'technical' },
  { id: 3, value: 'emergency' },
  { id: 4, value: 'other' },
  { id: 5, value: 'no' },
];

type CancelAllPopupProps = {
  date: string;
  onClose: () => void;
  onSubmit: () => void;
  orgSpecId: string | null;
  orgId: string | null;
};

export const CancelAllPopup = ({ date, onClose, onSubmit, orgSpecId, orgId }: CancelAllPopupProps) => {
  const { t } = useTranslation();
  const { selectedAddress } = useAppSelector(selectAppointments);

  const options = reasonOptions.map(option => ({ ...option, value: t(`calendar.menu.reason.${option.value}`) }));
  const [isNotify, setNotify] = useState(false);
  const [selected, setSelected] = useState<ReasonType | null>(null);
  const [isLoading, setLoading] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const cancelAll = useRequest(ThunkAppointments.cancelAllAppointment, AppointmentsAction.CancelAll);

  const handleSubmit = () => {
    setLoading(true);
    const params: CancelAllAppointmentParams = { date, notification: isNotify };
    orgSpecId ? (params.orgSpecId = orgSpecId) : (params.orgId = selectedAddress ?? (orgId as string));

    if (selected && !!selected.id) params.reason = reasonOptions.find(option => option.id === selected.id)!.value;

    cancelAll(params)
      .then(value => onSubmit())
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    setNotify(false);
    onClose();
  };

  const FooterBody = (
    <Flex flexDirection="column" width="100%" gap="16px">
      <Button size="large" width="100%" onClick={handleSubmit} disabled={isLoading || !selected}>
        {isLoading ? <Loader type="spinner" /> : t('button.apply')}
      </Button>
      <Button design="secondary" size="large" width="100%" onClick={handleClose} disabled={isLoading}>
        {t('button.back')}
      </Button>
    </Flex>
  );

  const content = (
    <Flex flexDirection="column" gap="16px">
      <InfoCheckbox
        title={t('calendar.menu.notifyClients')}
        info={t('calendar.menu.notifyClientsInfo')}
        checked={isNotify}
        onChange={() => setNotify(!isNotify)}
      />
      <Select
        options={options}
        selected={selected}
        onSelect={setSelected}
        placeholder={t('calendar.menu.selectReason')}
      />
    </Flex>
  );

  return isMobile ? (
    <BottomSheet
      isOpen
      onClose={handleClose}
      FooterBody={FooterBody}
      label={t('calendar.menu.cancelAll')}
      descriptor={t('calendar.menu.cancelAllDescription')}
      content={content}
    />
  ) : (
    createPortal(
      <PopupUI
        title={t('calendar.menu.cancelAll')}
        info={t('calendar.menu.cancelAllDescription')}
        open
        handleClose={onClose}
        FooterBody={FooterBody}
      >
        {content}
        <Separator mt="40px" />
      </PopupUI>,
      document.getElementById('root') as HTMLElement,
    )
  );
};
