import { TFunction } from 'react-i18next';
import moment from 'moment';
import { AnyObject, Nullable } from 'tsdef';
import * as Yup from 'yup';
import { AppointmentStatus } from '@beauty/beauty-market-ui';
import { getFormattedCurrency, getMarkedTimeslots, isWorkingSlotNumber, timeToSlotNumber } from 'helpers/utils';
import { TimeItemType } from 'hooks/useTimeList';
import {
  AppointmentByIdResponse,
  Client,
  ClientOption,
  EventType,
  SpecialistDayScheduleType,
  SpecialistScheduleDayType,
  SpecParams,
  WorkDayType,
  WorkTimeslot,
} from 'types';
import {
  AppointmentPrefilledData,
  BreakType,
  GoogleEventType,
  ServiceOption,
  SpecialistSummaryType,
} from 'types/appointment';
import { SpecialistEvents } from 'types/event';
import { EventStatus, getSelectedLanguage, HHmm, HOURS_PER_DAY, MINUTES_PER_HOUR } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectAppointments, selectOrgServices } from '../../../store/redux-slices/appointmentsSlice';
import { OrgService } from '../../../types/service';
import { MIN_DURATION, MINUTES_PER_SLOT } from '../constant';
import { BreakFormFields } from './BreakSidebar/BreakSidebar.definitions';
import { AppointmentFormFields } from './NewAppointmentSidebar/AppointmentSidebar.definitions';

export const disabledStatuses = {
  [AppointmentStatus.PENDING]: [AppointmentStatus.COMPLETED, AppointmentStatus.NOSHOW],
  [AppointmentStatus.CONFIRMED]: [AppointmentStatus.COMPLETED, AppointmentStatus.NOSHOW],
  [AppointmentStatus.WAITING]: [AppointmentStatus.COMPLETED, AppointmentStatus.NOSHOW],
  [AppointmentStatus.COMPLETED]: [
    AppointmentStatus.PENDING,
    AppointmentStatus.CONFIRMED,
    AppointmentStatus.WAITING,
    AppointmentStatus.INPROGRESS,
  ],
  [AppointmentStatus.UNCLOSED]: [
    AppointmentStatus.PENDING,
    AppointmentStatus.CONFIRMED,
    AppointmentStatus.WAITING,
    AppointmentStatus.INPROGRESS,
  ],
};
export const statusList = [
  {
    item: 'pending',
    disabled: false,
    status: AppointmentStatus.PENDING,
  },
  {
    item: 'confirmed',
    disabled: false,
    status: AppointmentStatus.CONFIRMED,
  },
  {
    item: 'waiting',
    disabled: false,
    status: AppointmentStatus.WAITING,
  },
  {
    item: 'inprogress',
    disabled: false,
    status: AppointmentStatus.INPROGRESS,
  },
  {
    item: 'completed',
    disabled: false,
    status: AppointmentStatus.COMPLETED,
  },
  {
    item: 'no_show',
    disabled: false,
    status: AppointmentStatus.NOSHOW,
  },
  {
    item: 'cancelled',
    disabled: false,
    status: AppointmentStatus.CANCELLED,
  },
];

export const convertAllServicesToOptions = (
  serviceMap: Record<string, OrgService>,
  serviceIds: string[],
  currency: Nullable<string>,
  t: TFunction<'translation', undefined>,
) => {
  const currentLanguage = getSelectedLanguage();
  return serviceIds.flatMap(serviceId => {
    const { price, title, duration, deleted } = serviceMap[serviceId];
    if (deleted) return [];
    const formattedPrice =
      price === null
        ? t('settings.services.nullPrice')
        : getFormattedCurrency(Number(price), currency, currentLanguage);
    return {
      id: serviceId,
      title: title.text,
      description: `${duration} ${t('time.min')}`,
      price: formattedPrice,
      duration,
    };
  });
};

export const filterServicesBySpecialist = (
  services: ServiceOption[],
  specialist: string,
  orgSpecialists: SpecialistSummaryType[] | null,
) =>
  services.flatMap(service => {
    if (specialist && orgSpecialists) {
      const spec = orgSpecialists.find(item => item.orgSpecId === specialist);
      if (spec && spec.serviceIds.includes(service.id)) {
        return service;
      }
      return [];
    }
    return service;
  });

export const convertAllSpecialistsToOptions = (allSpecialists: SpecialistSummaryType[] | null) =>
  allSpecialists
    ? allSpecialists.map(specialist => ({
        id: specialist.orgSpecId,
        name: `${specialist.name} ${specialist.surname}`,
        avatarUrl: specialist.avatarUrl,
        phone: `${specialist.code} ${specialist.number}`,
        serviceIds: specialist.serviceIds,
      }))
    : [];

export const filterSpecialistsByService = (specialists: SpecParams, service: string) =>
  specialists.filter(specialist => (service ? specialist.serviceIds.includes(service) : true));

export const convertAllClientsToOptions = (
  allClients: Client[] | null,
  showPhone: boolean,
  isMobile: boolean,
  t: TFunction<'translation', undefined>,
) => {
  if (!allClients) return [];
  const options: ClientOption[] = allClients.map(client => ({
    id: client.id,
    name: `${client.name} ${client.surname}`,
    avatarUrl: client.avatar,
    phone: showPhone && client.number ? `${client.code} ${client.number}` : null,
    email: `${client.email}`,
    idNumber: client?.idNumber,
  }));
  if (isMobile)
    options.unshift({
      id: null,
      name: t('calendar.newAppointmentSidebar.addNewClient'),
      avatarUrl: undefined,
      phone: null,
      email: '',
      idNumber: undefined,
    });
  return options;
};

const getTimeOptions = (startHour: number, startMinutes: number, endHour: number) => {
  const timeList: TimeItemType[] = [];
  const minutesStep = 15;

  for (let hour = startHour; hour <= endHour; hour += 1) {
    const formattedHour = hour.toString().padStart(2, '0');
    const initialMinutes = hour === startHour ? startMinutes : 0;

    for (let minute = initialMinutes; minute < 60; minute += minutesStep) {
      const formattedMinute = minute.toString().padStart(2, '0');
      const time24Hour = `${formattedHour}:${formattedMinute}`;

      timeList.push({
        id: `${hour * 60 + minute}`,
        item: time24Hour,
        hour,
        minute,
        disabled: false,
      });

      if (hour === endHour) {
        break;
      }
    }
  }
  return timeList;
};

const getRoundedMinutes = (currentMinutes: number) => Math.ceil(currentMinutes / 15) * 15;

export const getTimeDropdownOptions = (date: string, organisationWorktime: WorkDayType[] | null) => {
  const currentDate = moment();
  const selectedDate = moment(date, 'DD.MM.YYYY');
  const selectedDay = selectedDate.isoWeekday();
  const isToday = currentDate.isSame(selectedDate, 'day');
  let timeOptions: TimeItemType[] = [];

  const selectedDayWorkTime = organisationWorktime ? organisationWorktime[selectedDay - 1] : null;

  if (selectedDayWorkTime) {
    const organisationStartTime = moment(selectedDayWorkTime.start, 'HH:mm');
    const organisationEndTime = moment(selectedDayWorkTime.end, 'HH:mm');

    const initialStartOptionHour = isToday ? currentDate.get('hour') : organisationStartTime.get('hour');
    const initialStartOptionMinutes = isToday
      ? getRoundedMinutes(currentDate.get('minute'))
      : organisationStartTime.get('minute');

    const initialEndOptionHour = organisationEndTime.get('hour');

    timeOptions = getTimeOptions(initialStartOptionHour, initialStartOptionMinutes, initialEndOptionHour);
  }

  return timeOptions;
};

export const isWorkday = (date: Date, organisationWorkTime: WorkDayType[] | null) => {
  const day = date.getUTCDay();

  if (organisationWorkTime) {
    return organisationWorkTime[day].isWorkDay;
  }

  return true;
};

const getTime = (date?: string | Date) => moment(date).format('HH:mm');
export const getDate = (date?: string | Date) => moment(date).format('DD.MM.YYYY');

export const getDefaultDate = (date: string | Date) =>
  moment(date).isBefore(moment(), 'day') ? getDate() : getDate(date);

export const getEndTimeIndex = (startIndex: number, timeList: TimeItemType[], duration?: string | number | null) => {
  const difference = duration && +duration > 0 ? duration : MIN_DURATION;
  const endTime = Number(timeList[startIndex].id) + Number(difference);
  const endIndex = timeList.findIndex(time => Number(time.id) === endTime);
  return endIndex !== -1 ? endIndex : startIndex;
};

export const getScrollTo = (
  date: string | Date,
  timeList: TimeItemType[],
  organisationWorkTime: WorkDayType[] | null,
  duration?: string | number | null,
  startTime?: number,
) => {
  // 27 - 06-45
  // 28 - 07-00
  const defaultValues = { preScrolledToStart: 27, preScrolledToEnd: 28 };
  const isToday = moment(date).isSame(moment(), 'day');

  if (isToday) {
    const now = moment().hours() * 60 + moment().minutes();
    const roundedNow = Math.round((now + 15) / 15) * 15;
    const startTimeIndex = timeList.findIndex(time => Number(time.id) === roundedNow);
    const startIndex = startTimeIndex !== -1 ? startTimeIndex : defaultValues.preScrolledToStart;
    return {
      preScrolledToStart: startIndex,
      preScrolledToEnd: startIndex !== timeList.length - 1 ? startIndex + 1 : 0,
    };
  }

  if (!organisationWorkTime) {
    return defaultValues;
  }

  const dayOfWeek = moment(date).format('dddd').toUpperCase();
  const workDay = organisationWorkTime.find(day => day.dayOfWeek === dayOfWeek && day.isWorkDay);

  if (workDay) {
    const startIndex = startTime || timeList.findIndex(time => time.item === workDay.start);
    const endIndex = getEndTimeIndex(startIndex, timeList, duration);

    return {
      preScrolledToStart: startIndex,
      preScrolledToEnd: endIndex,
    };
  }

  return defaultValues;
};

export const getPreselectedValues = (
  selectedWeekday: string,
  appointmentData: AppointmentByIdResponse | GoogleEventType | BreakType | null,
  newAppointmentData: AppointmentPrefilledData | null,
  organisationClients: Client[] | null,
  organisationSpecialists: SpecialistSummaryType[] | null,
  organisationWorkTime: WorkDayType[] | null,
  timeList: TimeItemType[],
  dateFromMenu: string | null,
  isGoogle?: boolean,
) => {
  const { selectedSpecialist, selectedClient } = useAppSelector(selectAppointments);
  const { data, ids } = useAppSelector(selectOrgServices);
  const clients = organisationClients ?? [];

  const appointmentStart = appointmentData
    ? getTime(appointmentData.start)
    : newAppointmentData && getTime(newAppointmentData.start);

  let appointmentEnd = appointmentData && getTime(appointmentData.end);
  if (
    (appointmentData && new Date(appointmentData.start).getDate() !== new Date(appointmentData.end).getDate()) ||
    appointmentEnd === '23:59'
  )
    appointmentEnd = '24:00';

  // TODO Update if start time is in another day
  const appointmentDate = appointmentData
    ? getDate(appointmentData?.start)
    : (newAppointmentData && getDate(newAppointmentData.start)) ||
      (dateFromMenu && getDefaultDate(dateFromMenu)) ||
      getDefaultDate(selectedWeekday);

  const preselectedStart = appointmentStart ? timeList?.findIndex(time => time.item === appointmentStart) : -1;

  const preselectedEnd = appointmentEnd
    ? timeList?.findIndex(time => time.item === appointmentEnd)
    : (newAppointmentData &&
        timeList?.findIndex(time => time.item === appointmentStart) &&
        timeList.findIndex(time => time.item === appointmentStart) + 1) ||
      -1;
  const preselectedDate = appointmentDate;

  const preSelectedClient =
    appointmentData && !isGoogle
      ? clients.findIndex(client => client.id === (appointmentData as AppointmentByIdResponse).clientId)
      : clients.findIndex(client => client.headOrgClientId === selectedClient);

  const preSelectedServiceData =
    appointmentData && !isGoogle && ids.includes((appointmentData as AppointmentByIdResponse).orgServId)
      ? data[(appointmentData as AppointmentByIdResponse).orgServId]
      : undefined;

  const { id: preSelectedService = '', duration: preSelectedDuration = -1 } = preSelectedServiceData || {};

  const preSelectedSpecialist =
    appointmentData?.orgSpecId ||
    newAppointmentData?.orgSpecialist ||
    selectedSpecialist ||
    (organisationSpecialists?.length === 1 && organisationSpecialists[0].orgSpecId);

  const { preScrolledToStart, preScrolledToEnd } = getScrollTo(
    selectedWeekday,
    timeList,
    organisationWorkTime,
    preSelectedDuration,
  );

  return {
    preselectedStart,
    preselectedEnd,
    preselectedDate,
    preSelectedClient,
    preSelectedService,
    preSelectedSpecialist,
    preScrolledToStart,
    preScrolledToEnd,
  };
};

const isTimeAfterCurrent = (date, time) => {
  const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm');
  return dateTime.isAfter(moment(), 'minute');
};

const isEndAfterStart = (startTime, endTime) => {
  const startMoment = moment(startTime, 'HH:mm');
  const endMoment = moment(endTime, 'HH:mm');
  return endMoment.isAfter(startMoment, 'minute');
};

export function compareBreakStartAndNow(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const date = this.parent[BreakFormFields.BreakDate];
  return start ? isTimeAfterCurrent(date, start) : true;
}

export function compareBreakStartAndEnd(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const end = this.parent[BreakFormFields.End];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareBreakEndAndNow(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const date = this.parent[BreakFormFields.BreakDate];
  return end ? isTimeAfterCurrent(date, end) : true;
}

export function compareBreakEndAndStart(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const start = this.parent[BreakFormFields.Start];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareAppointmentStartAndNow(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const date = this.parent[AppointmentFormFields.AppointmentDate];
  const status = this.parent[AppointmentFormFields.Status];
  const shouldCheckTime = status === EventStatus.NOSHOW;
  const isValidStart = start ? isTimeAfterCurrent(date, start) : true;
  return shouldCheckTime ? isValidStart : true;
}

export function compareAppointmentStartAndEnd(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const end = this.parent[AppointmentFormFields.End];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareAppointmentEndAndNow(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const date = this.parent[AppointmentFormFields.AppointmentDate];
  return end ? isTimeAfterCurrent(date, end) : true;
}

export function compareAppointmentEndAndStart(this, end) {
  const start = this.parent[AppointmentFormFields.Start];
  return start && end ? isEndAfterStart(start, end) : true;
}

export const getActualPrice: (currencySign: string, updatedPrice?: string, price?: string | number | null) => string = (
  currencySign,
  updatedPrice,
  price,
) => `${currencySign}${updatedPrice || price}`;

const setEndNumber: (schedule?: SpecialistScheduleDayType) => Nullable<number> = schedule => {
  if (!schedule) return null;
  if (schedule.end !== '24:00') return timeToSlotNumber(moment(schedule.end, HHmm)) - 1;
  return 95;
};

export const getAllWorkTimeslots: (specialistsDaySchedule: SpecialistDayScheduleType) => {
  [id: string]: Nullable<WorkTimeslot>;
} = specialistsDaySchedule => {
  const workSlots: { [id: string]: Nullable<WorkTimeslot> } = {};
  Object.entries(specialistsDaySchedule).forEach(([orgSpecId, schedule]) => {
    const slotsNumber = schedule
      ? Math.trunc(moment(schedule.end, HHmm).diff(moment(schedule.start, HHmm), 'minute') / MINUTES_PER_SLOT) - 1
      : 0;

    workSlots[orgSpecId] = schedule
      ? {
          slotsNumber,
          startNumber: schedule ? timeToSlotNumber(moment(schedule.start, HHmm)) : null,
          endNumber: setEndNumber(schedule),
          breaks: schedule?.orgSpecDayBreak?.map(dayBreak => ({
            startNumber: timeToSlotNumber(moment(dayBreak.start, HHmm)),
            endNumber: timeToSlotNumber(moment(dayBreak.end, HHmm)) - 1,
          })),
        }
      : null;
  });
  return workSlots;
};

export const getSeparateBySpecialistTimeslots: (
  events: EventType[],
  specialistsDaySchedule: SpecialistDayScheduleType,
) => { [orgSpecId: string]: SpecialistEvents } | undefined = (events, specialistsDaySchedule) => {
  const allWorkTimeslots: { [id: string]: Nullable<WorkTimeslot> } = getAllWorkTimeslots(specialistsDaySchedule);

  const separateSlots = Object.keys(specialistsDaySchedule)?.reduce((res, orgSpecId) => {
    const workTimeslot = allWorkTimeslots[orgSpecId];
    res[orgSpecId] = {
      events: [],
      timeslots: workTimeslot
        ? Array((HOURS_PER_DAY * MINUTES_PER_HOUR) / MINUTES_PER_SLOT)
            .fill(null)
            .map((_, index) => (isWorkingSlotNumber(index, workTimeslot) ? false : null))
        : null,
    };
    return res;
  }, {});

  events?.forEach(event => separateSlots && separateSlots[event?.resourceId].events.push(event));

  separateSlots &&
    Object.keys(separateSlots).forEach(spec => {
      separateSlots[spec].timeslots = separateSlots[spec].timeslots
        ? getMarkedTimeslots(separateSlots[spec].events, separateSlots[spec].timeslots)
        : null;
    });

  return separateSlots;
};

export const setNotesFromGoogle = selectedAppointmentData => {
  const data = selectedAppointmentData as GoogleEventType;
  return `${data.title ? `${data.title}${data.description ? ': ' : ''}` : ''}${data.description ?? ''}`;
};

export const statusOptions = (status: AppointmentStatus, t: TFunction<'translation', undefined>) =>
  statusList.map(option => ({
    ...option,
    item: t(`status.${option.item}`),
    disabled: disabledStatuses[status]?.includes(option.status),
  }));
