import styled, { css } from 'styled-components';
import { CloseIcon, Div, Flex, Icon, Logo, mediaQueries } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const NavbarWrapper = styled(Flex)<{ isOpen: boolean }>`
  position: fixed;
  overflow: hidden;
  transition: width 0.2s ease-out;
  z-index: ${zIndex.mainMenuColumn};
  inset: 0;
  background-color: ${({ theme }) => theme.colors.black.standard};
  border-radius: 0;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  width: ${({ isOpen }) => (isOpen ? '100%' : '0')};

  ${mediaQueries.md} {
    border-radius: 10px;
    width: ${({ isOpen }) => (isOpen ? '260px' : '64px')};
    inset: ${({ theme }) => (theme.rtl ? '4px 4px 4px auto' : '4px auto 4px 4px')};
  }
`;

export const MenuWrapper = styled(Div)`
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-column-gap: 0;
  grid-row-gap: 8px;
  justify-content: start;
  align-items: center;
  padding: 14px 8px 16px;
`;

export const LogoWrapper = styled(Flex)`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: start;
  ${({ theme }) => (theme.rtl ? 'padding-right: 6px' : 'padding-left: 6px')};
`;

export const StyledLogo = styled(Logo)`
  flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
`;
export const StyledCloseIcon = styled(CloseIcon)`
  ${({ theme }) => (theme.rtl ? 'margin-right: auto' : 'margin-left: auto')};

  path {
    stroke: ${({ theme }) => theme.colors.white.standard};
  }
`;

export const SectionWrapper = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

export const StyledScroll = styled(Div)`
  width: 100%;
  height: 100%;
`;

export const CutWrapper = styled(Flex)<{ isOpen: boolean }>`
  position: absolute;
  height: 100%;
  width: inherit;

  ${({ isOpen }) => css`
    opacity: ${isOpen ? '0' : '1'} !important;
    display: ${isOpen ? 'none' : 'block'} !important;
  `}
`;

export const FullWrapper = styled(Flex)<{ isOpen: boolean }>`
  position: absolute;
  height: 100%;
  width: inherit;

  ${({ isOpen }) => css`
    opacity: ${isOpen ? '1' : '0'} !important;
  `}/* transition: opacity 0.5s linear, visibility 0s ease-out 0.5s; */
`;

export const FooterWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  z-index: 5;
  inset: auto 0 5px;
`;

export const LanguageList = styled(Flex)<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.black.standard};
  box-shadow: ${({ theme }) => ` 0px -30px 28px 0px ${theme.colors.black.standard}`};
  z-index: 4;
  transition: opacity 0.2s linear;

  transform: ${({ isOpen }) => (isOpen ? 'translateY(-100%)' : 'translateY(100%)')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export const LanguageItem = styled(Flex)<{ event?: boolean }>`
  position: relative;
  z-index: 11;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  padding: 8px 14px;
  width: 100%;
  box-sizing: border-box;

  pointer-events: ${({ event }) => (event ? 'all' : 'none')};

  ${mediaQueries.md} {
    padding: 10px 12px;
  }

  &:hover {
    background-color: #171721;

    div div div:nth-child(2) div svg {
      opacity: 1;
    }

    div div div:first-child,
    div div div:nth-child(2) div svg {
      cursor: pointer;
    }
  }
`;

export const BlockSeparator = styled(Div)<{ show: boolean }>`
  height: 8px;
  width: 100%;
  border-top: 1px solid #ffffff14;
  transition: opacity 0.2s linear;
  background-color: ${({ theme }) => theme.colors.black.standard};

  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const MobileMenuWrapper = styled(Flex)`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.black.standard};
  border-radius: 60px;
  padding: 14px 24px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  z-index: 6;

  svg {
    width: 20px;
    height: 20px;

    :hover {
      cursor: pointer;
    }

    :active {
      stroke: ${({ theme }) => theme.colors.white.standard};
    }

    path {
      stroke: ${({ theme }) => theme.colors.grey.dark};
    }
  }
`;

export const NavIcon = styled(Icon)<{ isActive: boolean }>`
  width: 20px;
  height: 20px;

  svg path {
    stroke: ${({ theme, isActive }) => (isActive ? theme.colors.white.standard : theme.colors.grey.dark)};
  }
`;
