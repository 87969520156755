export const editHeadOrgFields = {
  photo: false,
  name: true,
  registrationId: true,
  currency: true,
  employee: false,
  phone: true,
  email: true,
  topCategories: true,
};

export const newHeadOrgFields = {
  photo: true,
  name: true,
  registrationId: true,
  currency: false,
  employee: true,
  phone: true,
  email: true,
  topCategories: true,
};
