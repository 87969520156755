import styled from 'styled-components';
import { ContextMenu, Div } from '@beauty/beauty-market-ui';

export const ActionsWrapper = styled(Div)`
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

export const ActionsDropdown = styled(ContextMenu)`
  position: absolute;

  [dir='ltr'] & {
    right: 0;
  }

  [dir='rtl'] & {
    left: 0;
  }
`;
