import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, colors, RatingStars, Flex } from '@beauty/beauty-market-ui';
import { useMediaScreen } from 'hooks';
import { Customer } from 'types/calendar';
import { RatingCard } from './RatingCard';
import { BodyWrapper } from './style';

type SidebarBodyProps = {
  title: string;
  client: Customer;
  stars: number;
  setStars: Dispatch<SetStateAction<number>>;
};

export const SidebarBody = ({ title, client, stars, setStars }: SidebarBodyProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen('md');

  const { avatarUrl, clientName } = client;

  return (
    <BodyWrapper>
      <RatingCard avatarUrl={avatarUrl} title={clientName} info={t('review.client')} isMobile={isMobile} />
      <Flex flexDirection="column">
        <RatingStars rating={stars} onClick={setStars} />
        <BodySmall lowline color={colors.grey.dark} mb="81px">
          {stars ? t('review.yourRating') : title}
        </BodySmall>
      </Flex>
    </BodyWrapper>
  );
};
