import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Nullable } from 'tsdef';
import { Flex, H6, InfoIcon } from '@beauty/beauty-market-ui';
import { CustomMap, FormikInput, FormikTextfield } from 'components/index';
import { organisationAPI } from 'helpers';
import { GoogleStatusCode } from '../../../../constants';
import { EditHeadOrganisationFormFields, EditHeadOrganisationFormTypes } from './EditHeadOrganisation.definitions';

type LocationFormProps = {
  title?: Nullable<string>;
};

export const FormComponent = ({ title }: LocationFormProps) => {
  const { t } = useTranslation();
  const { handleBlur, values, setFieldValue, handleChange, touched, errors } =
    useFormikContext<EditHeadOrganisationFormTypes>();
  const { Country, City, Street, Building, Office, Post, Description, Longitude, Latitude, FullAddress } =
    EditHeadOrganisationFormFields;

  const handleCoordinates = async () => {
    const res = await organisationAPI.fetchCoordinates(values);
    if (res && res.status === GoogleStatusCode.OK) {
      const { geometry, formatted_address: fullAddress } = res.results[0];
      const { lat, lng } = geometry.location;
      lng && (await setFieldValue(Longitude, lng));
      lat && (await setFieldValue(Latitude, lat));
      fullAddress && (await setFieldValue(FullAddress, fullAddress));
    }
  };

  const handleInputBlur = async (e: HTMLInputElement) => {
    handleBlur(e);
    await handleCoordinates();
  };

  useEffect(() => {
    const controller = new AbortController();
    handleCoordinates();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Flex flexDirection="column" gap="16px">
      {title && <H6>{title}</H6>}
      <FormikInput
        id={Country}
        name={Country}
        width="100%"
        design="white"
        value={values[Country]}
        onBlur={handleInputBlur}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.country')}
        captionIcon={touched[Country] && !!errors[Country] && <InfoIcon />}
        caption={touched[Country] && !!errors[Country] ? errors[Country] : ''}
        required
      />
      <FormikInput
        id={City}
        name={City}
        width="100%"
        design="white"
        value={values[City]}
        onBlur={handleInputBlur}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.city')}
        captionIcon={touched[City] && !!errors[City] && <InfoIcon />}
        caption={touched[City] && !!errors[City] ? errors[City] : ''}
        required
      />
      <FormikInput
        id={Street}
        name={Street}
        width="100%"
        design="white"
        onBlur={handleInputBlur}
        value={values[Street]}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.street')}
        captionIcon={touched[Street] && !!errors[Street] && <InfoIcon />}
        caption={touched[Street] && !!errors[Street] ? errors[Street] : ''}
        required
      />
      <FormikInput
        id={Building}
        name={Building}
        width="100%"
        design="white"
        onBlur={handleInputBlur}
        value={values[Building]}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.building')}
        captionIcon={touched[Building] && !!errors[Building] && <InfoIcon />}
        caption={touched[Building] && !!errors[Building] ? errors[Building] : ''}
        required
      />
      <FormikInput
        id={Office}
        name={Office}
        width="100%"
        design="white"
        onBlur={handleBlur}
        value={values[Office]}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.officeOrApt')}
        captionIcon={touched[Office] && !!errors[Office] && <InfoIcon />}
        caption={touched[Office] && !!errors[Office] ? errors[Office] : ''}
      />
      <FormikInput
        id={Post}
        name={Post}
        width="100%"
        design="white"
        onBlur={handleBlur}
        value={values[Post]}
        onChange={handleChange}
        placeholder={t('settings.addresses.placeholder.postIndex')}
        captionIcon={touched[Post] && !!errors[Post] && <InfoIcon />}
        caption={touched[Post] && !!errors[Post] ? errors[Post] : ''}
      />
      <FormikTextfield
        id={Description}
        name={Description}
        design="white"
        rows={3}
        value={values[Description]}
        defaultValue={values[Description]}
        placeholder={t('settings.addresses.placeholder.description')}
        onChange={handleChange}
      />
      <CustomMap lat={values[Latitude]} lng={values[Longitude]} />
    </Flex>
  );
};

export const LocationForm = memo(FormComponent);
