import { useDropdown } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../hooks';
import { LogoutSidebar, Menu, MobileMenu } from './components';
import { CutWrapper, FullWrapper, NavbarWrapper } from './style';

export const Navbar = () => {
  const { isDesktop } = useMediaScreen('md');
  const { open, onOpen, onClose } = useDropdown();

  return (
    <>
      <NavbarWrapper
        isOpen={open}
        onMouseOver={isDesktop ? onOpen : undefined}
        onMouseOut={isDesktop ? onClose : undefined}
      >
        <FullWrapper isOpen={open}>
          <Menu isFull onClose={isDesktop ? undefined : onClose} />
        </FullWrapper>
        <CutWrapper isOpen={open}>
          <Menu />
        </CutWrapper>
      </NavbarWrapper>
      <MobileMenu isOpen={open} onOpen={onOpen} />
      <LogoutSidebar />
    </>
  );
};
