import styled from 'styled-components';
import { Flex, Div } from '@beauty/beauty-market-ui';

export const GoogleMapWrapper = styled(Div)`
  border-radius: 16px;
  overflow: hidden;
`;

export const ZoomButtonsWrapper = styled(Flex)`
  margin-right: 24px;
  flex-direction: column;
  justify-content: center;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.08),
    0 2px 24px rgba(0, 0, 0, 0.08);
`;

export const CustomMapWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

export const MapPointLocationWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);

  & path {
    fill: ${({ theme }) => theme.colors.blue.standard};
  }
`;
