import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Nullable } from 'tsdef';
import { Caption, colors, H6, Select, TabCategory, TabStack, UploadSinglePhoto } from '@beauty/beauty-market-ui';
import { FormikInput, FormikPhone } from 'components/index';
import { getPhotoImage } from 'helpers';
import { ColumnFlex } from 'style';
import { IconType, OptionType } from 'types';
import { EditHeadOrganisationFormFields, EditHeadOrganisationFormTypes } from './EditHeadOrganisation.definitions';
import { CategoriesWrapper, SidebarContentSection, SidebarContentWrapper } from './style';

type EditableFields =
  | 'photo'
  | 'name'
  | 'currency'
  | 'employee'
  | 'phone'
  | 'email'
  | 'topCategories'
  | 'registrationId';

type GeneralInformationFormProps = {
  fields: Record<Partial<EditableFields>, boolean>;
  currencyOptions: OptionType[];
  topCategories: IconType[];
};

const FormComponent = ({ fields, currencyOptions, topCategories }: GeneralInformationFormProps) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, initialValues } = useFormikContext<EditHeadOrganisationFormTypes>();
  const { Photo, Code, Currency, Phone, Email, Name, EmployeesNum, Category, RegistrationId } =
    EditHeadOrganisationFormFields;

  return (
    <SidebarContentWrapper>
      {fields.photo && (
        <ColumnFlex mb="24px">
          <H6>{t('settings.sidebarOrganisationSettings.logotype')}</H6>
          <Caption mb="16px" lowline color={colors.grey.dark}>
            {t('settings.sidebarOrganisationSettings.logoHint')}
          </Caption>
          <UploadSinglePhoto
            id={Photo}
            name={Photo}
            image={getPhotoImage(values[Photo])}
            error={errors[Photo]}
            text={errors[Photo]}
            onUpload={(imageData: Nullable<File>) => setFieldValue(Photo, imageData)}
            width="101px"
          />
        </ColumnFlex>
      )}
      {(fields.name || fields.currency || fields.registrationId) && (
        <SidebarContentSection>
          <H6>{t('settings.sidebarOrganisationSettings.title')}</H6>
          {fields.name && (
            <FormikInput
              id={Name}
              name={Name}
              placeholder={t('settings.sidebarOrganisationSettings.namePlaceholder')}
              design="white"
              required
            />
          )}
          {fields.registrationId && (
            <FormikInput
              id={RegistrationId}
              name={RegistrationId}
              placeholder={t('settings.sidebarOrganisationSettings.registrationIdPlaceholder')}
              caption={t('settings.sidebarOrganisationSettings.registrationIdCaption')}
              design="white"
              required
            />
          )}
          {fields.currency && (
            <Field
              id={Currency}
              name={Currency}
              options={currencyOptions}
              selected={values[Currency]}
              onSelect={(item: OptionType) => setFieldValue(Currency, item)}
              placeholder={t('settings.sidebarOrganisationSettings.currency')}
              component={Select}
              width="102px"
              mr="8px"
            />
          )}
        </SidebarContentSection>
      )}
      {fields.employee && (
        <SidebarContentSection>
          <H6>{t('settings.sidebarOrganisationSettings.allEmployees')}</H6>
          <TabStack
            id={EmployeesNum}
            name={EmployeesNum}
            items={[`${t('settings.sidebarOrganisationSettings.justMe')}`, '2', '3', '4', '5', '6', '7+']}
            active={values[EmployeesNum]}
            onStackClick={(index: number) => setFieldValue(EmployeesNum, index)}
          />
        </SidebarContentSection>
      )}
      {(fields.email || fields.phone) && (
        <SidebarContentSection>
          <H6>{t('settings.sidebarOrganisationSettings.contact')}</H6>
          {fields.phone && (
            <FormikPhone
              phoneId={Phone}
              codeId={Code}
              placeholder={t('settings.sidebarOrganisationSettings.phone')}
              required
            />
          )}
          {fields.email && (
            <FormikInput
              id={Email}
              name={Email}
              placeholder={t('settings.sidebarOrganisationSettings.email')}
              design="white"
              width="100%"
              required
            />
          )}
        </SidebarContentSection>
      )}
      {fields.topCategories && (
        <SidebarContentSection>
          <H6>{t('settings.sidebarOrganisationSettings.occupation')}</H6>
          <CategoriesWrapper>
            <FieldArray
              name={Category}
              render={arrayHelpers =>
                topCategories.map(({ id, icon, name }) => (
                  <TabCategory
                    id={id}
                    key={id}
                    icon={icon}
                    title={name}
                    size="medium"
                    selected={values[Category].includes(id)}
                    onClick={async () => {
                      if (initialValues[Category].includes(id)) return;
                      if (values[Category].includes(id)) {
                        arrayHelpers.remove(values[Category].indexOf(id));
                      } else {
                        arrayHelpers.push(id);
                      }
                    }}
                  />
                ))
              }
            />
          </CategoriesWrapper>
        </SidebarContentSection>
      )}
    </SidebarContentWrapper>
  );
};

export const GeneralInformationForm = memo(FormComponent);
