import styled from 'styled-components';
import { BodySmall, Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Paper } from 'components';

export const MetricsWrapper = styled(Div)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 372px repeat(4, 116px);
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  ${mediaQueries.md} {
    grid-template-columns: 200px 200px minmax(116px, 1fr) minmax(116px, 1fr);
    grid-template-rows: 178px 178px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
`;

export const MetricMainItem = styled(Paper)`
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  grid-area: 1;

  ${mediaQueries.md} {
    padding: 24px;
    grid-area: 1 / 1 / 3 / 3;
  }
`;

export const MetricSubItem = styled(Paper)<{ order: number }>`
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 10px;
  grid-area: ${({ order }) => {
    switch (order) {
      case 0:
        return '2';
      case 1:
        return '3';
      case 2:
        return '4';
      case 3:
        return '5';
      default:
        return undefined;
    }
  }};

  ${mediaQueries.md} {
    padding: 24px;
    grid-area: ${({ order }) => {
      switch (order) {
        case 0:
          return '1 / 3 / 2 / 4';
        case 1:
          return '1 / 4 / 2 / 5;';
        case 2:
          return '2 / 3 / 3 / 4;';
        case 3:
          return '2 / 4 / 3 / 5; ';
        default:
          return undefined;
      }
    }};

`;

export const BadgeItem = styled(BodySmall)`
  width: 115px;
  text-align: center !important;
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 200px;
  background-color: ${({ theme }) => theme.colors.grey.light};
`;

export const Round = styled(Div)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const CircleInfo = styled(Flex)`
  flex-direction: column-reverse;
`;

export const SubMetricContent = styled(Flex)`
  align-items: baseline;
  gap: 4px;
  max-width: 100%;
`;
