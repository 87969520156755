import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Button, Flex, Loader } from '@beauty/beauty-market-ui';
import { SidebarSheet } from 'components/SidebarSheet';
import { getFormattedTimeLeft } from 'helpers/utils';
import { Customer } from 'types/calendar';
import { CLOSE_AFTER } from '../../constants';
import { useStepper } from '../../hooks/useStepper';
import { FeedbackForm } from './FeedbackForm';
import { ResultInfo } from './ResultInfo';
import { SidebarBody } from './SidebarBody';

export type RatingSidebarProps = {
  appointmentId: string;
  client: Customer;
  date: string;
  address: string;
  headOrgClientId: string;
  handleClose: () => void;
  setOpen?: (isOpen: boolean) => void;
};

export const RatingSidebar = ({
  appointmentId,
  client,
  date,
  address,
  headOrgClientId,
  handleClose,
  setOpen,
}: RatingSidebarProps) => {
  const { t } = useTranslation();

  const [stars, setStars] = useState(0);
  const [score, setScore] = useState(0);
  const [valid, setValid] = useState(false);
  const [responseStatus, setResponseStatus] = useState<string | null>(null);
  const [secondsLeft, setSecondsLeft] = useState(CLOSE_AFTER);
  const [isLoading, setLoading] = useState(false);

  const formRef = useRef<FormikProps<{ feedback: string }>>(null);

  const title = [t('review.evaluateClient'), t('review.feedback')];

  const { currentStep, StepperComponent, handleReset, handleNextStep, handleBackStep } = useStepper({
    title,
    count: 2,
    isReview: true,
  });

  const handleClick = () => {
    switch (currentStep) {
      case 0:
        setScore(stars);
        handleNextStep();
        break;
      case 1:
        formRef.current?.handleSubmit();
        handleNextStep();
        break;
      default:
        setStars(0);
        setOpen && setOpen(false);
        handleClose();
    }
  };

  const content = (
    <Flex flexDirection="column">
      {currentStep < 2 && StepperComponent}
      {currentStep === 1 && (
        <FeedbackForm
          ref={formRef}
          appointmentId={appointmentId}
          headOrgClientId={headOrgClientId}
          clientId={client.id}
          score={score}
          setLoading={setLoading}
          setSecondsLeft={setSecondsLeft}
          setResponseStatus={setResponseStatus}
          setValid={setValid}
        />
      )}
      {currentStep === 2 && !isLoading && <ResultInfo responseStatus={responseStatus} />}
      {currentStep === 2 && isLoading && (
        <Flex alignItems="center" justifyContent="center" mt="50%">
          <Loader />
        </Flex>
      )}

      {!currentStep && <SidebarBody title={title[currentStep]} client={client} stars={stars} setStars={setStars} />}
    </Flex>
  );

  const buttonLabel =
    (currentStep === 0 && t('button.continue')) ||
    (currentStep === 1 && t('button.publishFeedback')) ||
    `${t(`button.close`)} ${getFormattedTimeLeft(secondsLeft)}`;

  const footer = (
    <Flex flexDirection="column" width="100%">
      <Button disabled={currentStep ? !valid : !stars} design="primary" width="100%" size="large" onClick={handleClick}>
        {buttonLabel}
      </Button>
      {currentStep < 2 && (
        <Button
          design="secondary"
          width="100%"
          size="large"
          onClick={!currentStep ? () => setOpen && setOpen(false) : handleBackStep}
        >
          {currentStep ? t('button.back') : t('button.cancel')}
        </Button>
      )}
    </Flex>
  );

  const sidebarProps = {
    isOpen: true,
    onClose: () => {
      setOpen && setOpen(false);
      handleReset();
      handleClose();
    },
    label: t('review.rateAppointment'),
    descriptor: `${date}, ${address}`,
    FooterBody: footer,
  };

  useEffect(() => {
    if (!secondsLeft) {
      setOpen && setOpen(false);
      handleClose();
    }
  }, [secondsLeft]);

  return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
};
