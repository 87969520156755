import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Nullable } from 'tsdef';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAllAddresses } from '../../store/redux-slices/addressSlice';
import { selectAppointments, setSelectedAddress } from '../../store/redux-slices/appointmentsSlice';
import { selectSpecialist, ThunkSpecialist } from '../../store/redux-slices/specialistSlice';
import { selectUserId } from '../../store/redux-slices/userSlice';
import { NamedAddressType, SpecialistOrganizationType } from '../../types';
import { useAccess } from '../index';

export const useAddressOptions = (withStub = false) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const specialist = useAppSelector(selectSpecialist);
  const allAddress = useAppSelector(selectAllAddresses);

  // TODO: refactor to use select from addressSlice
  const { selectedAddress } = useAppSelector(selectAppointments);

  const { isSuperAdmin } = useAccess();

  const onSelect = useCallback(
    (id: Nullable<string>) => {
      dispatch(setSelectedAddress(id));
    },
    [dispatch],
  );

  const addressOptions = useMemo(() => {
    const stub = { id: null, title: t('address.allAddress') };

    const getSpecialistAddressOptions = (addresses?: SpecialistOrganizationType[]) => {
      if (!Array.isArray(addresses)) return [];
      return addresses.map(address => ({
        id: address.orgId,
        title: address.name,
        icon: address.mainPhoto,
        description: address.address?.fullAddress || '',
      }));
    };

    const getAllAddressOptions = (addresses: NamedAddressType[]) => {
      if (!Array.isArray(addresses)) return [];
      return addresses.map(address => ({
        id: address.id,
        title: address.name,
        icon: address.mainPhoto?.url,
        description: address.address.fullAddress,
      }));
    };

    const options = isSuperAdmin
      ? getAllAddressOptions(allAddress)
      : getSpecialistAddressOptions(specialist.organization);
    return withStub ? [stub, ...options] : options;
  }, [isSuperAdmin, allAddress, specialist.organization, withStub]);

  useEffect(() => {
    if (!isEmpty(addressOptions)) {
      const index = addressOptions.findIndex(item => item.id === selectedAddress);
      if (index === -1) {
        dispatch(setSelectedAddress(addressOptions[0].id));
      }
    }
  }, [addressOptions, selectedAddress, withStub]);

  useEffect(() => {
    if (!isSuperAdmin && userId) {
      dispatch(ThunkSpecialist.fetchSpecialist(userId));
    }
  }, [userId, isSuperAdmin]);

  return {
    onSelect,
    options: addressOptions,
    selected: selectedAddress,
  };
};
