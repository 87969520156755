import { Dispatch, forwardRef, memo, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { ClientReviewPayload } from 'types';
import { CLOSE_AFTER } from '../../constants';
import { sendReview } from '../../helpers/clientAPI';
import { useAppSelector } from '../../store/hooks';
import { selectUserHead } from '../../store/redux-slices/userSlice';
import { FormikTextfield } from '../functional/formik/formik-textfield/FormikTextfield';
import { FeedbackFormFields, FeedbackFormValidationSchema, initialValues } from './FeedbackForm.definitions';
import { FormikWrapper } from './style';

type FeedbackFormProps = {
  appointmentId: string;
  headOrgClientId: string;
  clientId: string;
  score: number;
  setLoading: (isLoading: boolean) => void;
  setSecondsLeft: Dispatch<SetStateAction<number>>;
  setResponseStatus: Dispatch<SetStateAction<string | null>>;
  setValid: (isValid: boolean) => void;
};

export const FeedbackForm = memo(
  forwardRef((props: FeedbackFormProps, ref) => {
    const { appointmentId, headOrgClientId, clientId, score, setLoading, setSecondsLeft, setResponseStatus, setValid } =
      props;
    const { t } = useTranslation();
    const userHead = useAppSelector(selectUserHead);

    const [feedback, setFeedback] = useState('');

    const handleReviewSubmit = async () => {
      setLoading(true);
      const payload: ClientReviewPayload = {
        appointmentId,
        headOrgSpecId: userHead?.id ?? '',
        headOrgClientId,
        score,
        summary: feedback,
      };

      const response = await sendReview(clientId, payload);
      setResponseStatus(response.statusCode ? 'error' : 'success');
      const interval = setInterval(() => setSecondsLeft(seconds => seconds - 1), 1000);
      setTimeout(() => clearInterval(interval), CLOSE_AFTER * 1000);
      response && setLoading(false);
    };

    const formikContextValue = {
      initialValues,
      validationSchema: FeedbackFormValidationSchema(t, score),
      onSubmit: handleReviewSubmit,
      validateOnMount: true,
    };

    return (
      <FormikWrapper>
        <Formik innerRef={ref as (instance: FormikProps<{ feedback: string }> | null) => void} {...formikContextValue}>
          {({ values, isValid }) => {
            useEffect(() => setFeedback(values.feedback), [values]);
            useEffect(() => setValid(isValid), [isValid]);
            return (
              <Form>
                <FormikTextfield
                  id={FeedbackFormFields.Feedback}
                  name={FeedbackFormFields.Feedback}
                  design="white"
                  placeholder={t('review.typeHere')}
                  rows={4}
                  infoCaption={`${feedback.length}/1000`}
                />
              </Form>
            );
          }}
        </Formik>
      </FormikWrapper>
    );
  }),
);
