import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { SidebarFooter, SidebarSheet } from 'components/index';
import { EditDescriptionFormFields, EditDescriptionFormType, schema } from './EditDescription.defenitions';

type EditDescriptionProps = {
  title: string;
  subtitle: string;
  onClose: () => void;
  onSubmit: (data: EditDescriptionFormType) => Promise<void>;
  onBack?: () => void;
  defaultValue?: string | null;
  mb?: string;
};

export const EditDescription = ({
  defaultValue,
  onClose,
  onSubmit,
  title,
  subtitle,
  onBack,
  mb,
}: EditDescriptionProps) => {
  const { t } = useTranslation();
  const { Description } = EditDescriptionFormFields;

  const formikContextValue = {
    initialValues: { description: defaultValue ?? '' },
    validationSchema: schema(t),
    validateOnMount: false,
    onSubmit,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, errors, values, handleChange, dirty, touched, isSubmitting, handleSubmit: handleSubmitForm }) => (
        <Form>
          <SidebarSheet
            label={title}
            descriptor={subtitle}
            onClose={onClose}
            onBack={onBack}
            FooterBody={
              <SidebarFooter
                onSubmit={handleSubmitForm}
                onBack={onClose}
                isLoading={isSubmitting}
                disabled={!isValid || !dirty}
                save
              />
            }
          >
            <Flex flexDirection="column" mb={mb}>
              <FormikTextfield
                id={Description}
                name={Description}
                rows={8}
                width="100%"
                design="white"
                onChange={handleChange}
                value={values[Description]}
                defaultValue={values[Description]}
                placeholder={t('common.description')}
                caption={touched[Description] && !!errors[Description] ? errors[Description] : ''}
              />
            </Flex>
          </SidebarSheet>
        </Form>
      )}
    </Formik>
  );
};
