import { AccessRange } from '../constants';
import { useAppSelector } from '../store/hooks';
import { selectUserHead } from '../store/redux-slices/userSlice';
import { AccessLevel } from '../types';

const hasAccess = (role: keyof typeof AccessRange, accessLevel?: AccessLevel) =>
  accessLevel ? AccessRange[role].includes(accessLevel) : false;

export const useAccess = () => {
  const userHead = useAppSelector(selectUserHead);
  const accessLevel = userHead?.accessLevel;

  return {
    isOwner: hasAccess('OWNER', accessLevel),
    isAdmin: hasAccess('ADMIN', accessLevel),
    isSuperAdmin: hasAccess('SUPERADMIN', accessLevel),
    isSpecialist: hasAccess('SPECIALIST', accessLevel),
    isGuest: !userHead,
  };
};
