import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getErrorMessage, getFullAddress, parsePhone } from 'helpers';
import { useGetTopCategories } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectHeadOrgAddressId } from 'store/redux-slices/organisationSlice';
import { EditHeadOrganisationType, ErrorDataType } from 'types';
import { getCurrencyOptions, getSelectedLanguage, InvalidVariants } from '../../../../constants';
import {
  EditHeadOrganisationFormFields,
  EditHeadOrganisationFormTypes,
  getInitialValues,
  schema,
} from './EditHeadOrganisation.definitions';
import { GeneralInformation } from './GeneralInformation';
import { editHeadOrgFields, newHeadOrgFields } from './helpers';
import { Location } from './Location';

enum EditHead {
  EditGeneralInformation = 1,
  EditLocation,
}

type EditHeadOrganisationProps = {
  editMode?: boolean;
  onClose?: () => void;
  onSubmit: (values: EditHeadOrganisationType) => Promise<void>;
};

export const EditHeadOrganisation = ({ onSubmit, onClose, editMode }: EditHeadOrganisationProps) => {
  const { t } = useTranslation();
  const addressId = useAppSelector(selectHeadOrgAddressId);

  const [step, setStep] = useState(EditHead.EditGeneralInformation);

  const language = getSelectedLanguage();
  const currencyOptions = getCurrencyOptions(t);

  const { generalInformation, location } = {
    generalInformation: {
      label: editMode
        ? t('settings.sidebarOrganisationSettings.editCompany')
        : t('settings.sidebarOrganisationSettings.organisationSettings'),
      descriptor: editMode
        ? t('settings.sidebarOrganisationSettings.updateCommon')
        : t('settings.sidebarOrganisationSettings.titleDescriptor'),
    },
    location: {
      label: t('settings.sidebarOrganisationSettings.officialOrganisationInfo'),
      descriptor: t('settings.sidebarOrganisationSettings.infoWillBeUsed'),
    },
  };

  const { topCategoriesData: topCategories, isLoading } = useGetTopCategories();

  const onNext = () => setStep(prev => prev + 1);
  const onBack = () => setStep(prev => prev - 1);

  const onFormSubmit = (data: EditHeadOrganisationFormTypes, { setFieldError }) => {
    const params: EditHeadOrganisationType = {
      language,
      name: data.name,
      code: data.code,
      email: data.email,
      category: data.category,
      currency: data.currency?.id,
      employeesNum: String(data.employeesNum + 1),
      number: parsePhone(data.code, data.number)?.nationalNumber ?? '',
      photo: data.photo || null,
      registrationId: data.registrationId,
      address: {
        id: addressId,
        country: data.country,
        city: data.city,
        street: data.street,
        building: data.building,
        office: data.office || null,
        postal: data.postal || null,
        description: data.description || null,
        fullAddress: getFullAddress(data),
        lat: data.lat,
        lng: data.lng,
      },
    };
    onSubmit(params).catch((err: ErrorDataType) => {
      switch (err.message) {
        case InvalidVariants.FileLarge:
          setFieldError(EditHeadOrganisationFormFields.Photo, getErrorMessage(t, err.message));
          setStep(EditHead.EditGeneralInformation);
          break;
        default:
          break;
      }
    });
  };

  const formikContextValue = {
    initialValues: getInitialValues(currencyOptions),
    validationSchema: schema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      <Form>
        {step === EditHead.EditGeneralInformation && (
          <GeneralInformation
            onClose={onClose}
            onSubmit={onNext}
            label={generalInformation.label}
            descriptor={generalInformation.descriptor}
            topCategories={topCategories}
            currencyOptions={currencyOptions}
            buttonLabel={t('settings.sidebarOrganisationSettings.proceed')}
            fields={editMode ? editHeadOrgFields : newHeadOrgFields}
            isLoading={isLoading}
          />
        )}
        {step === EditHead.EditLocation && (
          <Location
            onBack={onBack}
            onClose={onClose}
            label={location.label}
            descriptor={location.descriptor}
            title={editMode ? t('settings.addresses.sidebar.addressInformation') : null}
          />
        )}
      </Form>
    </Formik>
  );
};
