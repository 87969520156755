import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { useAddressOptions } from 'hooks';

export const FilterByAddress = () => {
  const { t } = useTranslation();
  const { options, selected, onSelect } = useAddressOptions();

  return !isEmpty(options) ? (
    <FilterDropdown
      onSelect={onSelect}
      options={options}
      selectedOptionId={selected}
      placeholder={t('button.search')}
    />
  ) : null;
};
