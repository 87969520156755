export { AccountSetting } from './AccountSetting/AccountSetting';
export { CustomCalendar } from './Calendar/CustomCalendar';
export { Home } from './Home/Home';
export { LoginIntro } from './Login/LoginIntro';
export { WelcomeCrm } from './Test/WelcomeCrm';
export { PersonalProfile } from './PersonalProfile/PersonalProfile';

export * from './ProfileSettings';
export * from './Instruments';
export * from './Clients';
export * from './Reports';

export { default as EmailVerification } from './EmailVerification/EmailVerification';
export { default as LoginSMS } from './Login/componets/LoginSMS';
export { default as LogInto } from 'page/Login/componets/LogInto';
export { default as Login } from 'page/Login/Login';
export { default as PhoneVerification } from './Login/Registration/PhoneVerification';
export { default as Registration } from './Login/Registration/Registration';
export { default as ResetPassword } from './Login/ResetPassword/ResetPassword';
export { default as UpdatePassword } from './Login/ResetPassword/UpdatePassword';
export { default as SentEmailNotification } from './Login/SentEmailNotification/SentEmailNotification';
export { default as SignUp } from './Login/SignUp/SignUp';
export { default as ServiceUnavailable } from './ServiceUnavailable/ServiceUnavailable';
