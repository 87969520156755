import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Nullable } from 'tsdef';
import { CalendarIcon, DateSelector, TabStack, TagDropdown, useDropdown } from '@beauty/beauty-market-ui';
import { useMediaScreen } from 'hooks';
import { DateMode } from './helpers';
import { FilterWrapper, SmallCalendar } from './style';
import { useDateSelector } from './useDateSelector';

type FilterByDateProps = {
  placeholder: string;
  onChangeRange: (range: [Nullable<string>, Nullable<string>]) => void;
};

export const FilterByDate = ({ placeholder, onChangeRange }: FilterByDateProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { ref, open, onOpen, onClose } = useDropdown();
  const { locale, date, mode, onResetDate, onChangeDate, onChangeMode } = useDateSelector({
    placeholder,
    onChangeRange,
  });

  return (
    <FilterWrapper ref={ref} width="max-content">
      <TagDropdown
        value={date}
        onClick={onOpen}
        onReset={onResetDate}
        placeholder={moment(placeholder, 'YYYY-MM-DD').format('DD.MM.YYYY')}
        iconRight={<CalendarIcon width={16} height={16} />}
      />
      {open && (
        <SmallCalendar>
          <DateSelector
            locale={locale}
            size={isDesktop ? 'lg' : 'md'}
            minDate={null}
            selectsRange={mode === DateMode.Range}
            buttonLabel={t('date.selectDate')}
            onSelectDateClick={onChangeDate}
            onClose={onClose}
            header={
              <TabStack items={[t('date.day'), t('date.period')]} active={mode} onStackClick={onChangeMode} mb="16px" />
            }
          />
        </SmallCalendar>
      )}
    </FilterWrapper>
  );
};
