import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { BodySmall, Caption, colors, MenuItem } from '@beauty/beauty-market-ui';
import { checkAccess, isRtl } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAddressState } from 'store/redux-slices/addressSlice';
import { selectService } from 'store/redux-slices/serviceSlice';
import { selectSpecialistState } from 'store/redux-slices/specialistSlice';
import { Tooltip } from '../../Tooltip';
import { disabledLinksByHeadOrgConditions, getTooltipInfo } from '../helpers';
import { SectionWrapper } from '../style';
import { MenuSectionProps } from '../types';
import { ItemFlex, MenuItemListWrapper } from './style';

export const MenuSection = ({ isFull, title, items, onClose }: MenuSectionProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const { allSpecialists } = useAppSelector(selectSpecialistState);
  const { allServices } = useAppSelector(selectService);
  const { allAddress } = useAppSelector(selectAddressState);

  const navigate = useNavigate();
  const rtl = isRtl();

  const staff = allSpecialists.filter(specialist => specialist.role === 'SPECIALIST');
  const shouldBeDisabled = isEmpty(staff) || isEmpty(allServices) || isEmpty(allAddress);
  const acceptance = {
    address: isEmpty(allAddress),
    service: isEmpty(allServices),
    specialist: isEmpty(staff),
  };

  const mappedItems = items.map(item => {
    const { id, count, itemsList, isStroke = false, accessRange } = item;

    const disableLink = disabledLinksByHeadOrgConditions.includes(id) && shouldBeDisabled;
    const tooltipInfo = disableLink ? getTooltipInfo(acceptance, t) : '';

    const hasAccess = checkAccess(accessRange);
    const hasView = isDesktop ? true : item.isMobile;

    const handleMainMenuClick = () => {
      item.href && navigate(item.href);
      onClose && onClose();
    };

    return hasAccess && hasView ? (
      <Tooltip id={id} key={id} place="top" content={tooltipInfo} show={disableLink}>
        <MenuItem
          data-tooltip-id={id}
          title={item.title}
          count={count}
          isFull={isFull}
          icon={<item.icon width="24" height="24" />}
          isStroke={isStroke}
          onClick={disableLink ? undefined : handleMainMenuClick}
          cursor={disableLink ? 'auto' : 'pointer'}
          color={disableLink ? colors.grey.darker : colors.grey.standard}
          width="100%"
          isDropdown={!!itemsList}
        >
          {itemsList && (
            <MenuItemListWrapper>
              {itemsList.map(subItem => {
                const handleSubMenuClick = e => {
                  e.stopPropagation();
                  subItem.href && navigate(subItem.href);
                  onClose && onClose();
                };

                return (
                  <ItemFlex key={subItem.title} onClick={handleSubMenuClick}>
                    <BodySmall>{subItem.title}</BodySmall>
                    <Caption large>{subItem.count}</Caption>
                  </ItemFlex>
                );
              })}
            </MenuItemListWrapper>
          )}
        </MenuItem>
      </Tooltip>
    ) : null;
  });

  const filteredItems = mappedItems.filter(item => item !== null);

  if (!filteredItems.length) return null;

  return (
    <SectionWrapper>
      {isFull ? (
        <BodySmall color="#4C4C5F" margin={rtl ? '0 14px 0 0' : '0 0 0 14px'}>
          {title}
        </BodySmall>
      ) : (
        <BodySmall color="#4C4C5F" margin={rtl ? '0 20px 0 0' : '0 0 0 20px'}>
          •
        </BodySmall>
      )}
      {filteredItems}
    </SectionWrapper>
  );
};
