import styled, { keyframes } from 'styled-components';
import { Flex, Div } from '@beauty/beauty-market-ui';

export const LoaderWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Spinner = styled(Div)<{ color?: string; size?: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 2px solid ${({ color, theme }) => color ?? theme.colors.white.standard};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
`;

const blink = keyframes`
  0%, 20%, 100% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
`;

export const Dot = styled.span`
  animation: ${blink} 2s infinite;
  color: currentColor;

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.8s;
  }
`;
