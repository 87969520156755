import moment from 'moment';
import { Review } from '@beauty/beauty-market-ui';
import { getFullName, getTranslation } from 'helpers';
import { ClientReviews } from 'types';
import { getSelectedLanguage } from '../../../../constants';

type ReviewsListProps = {
  reviews: ClientReviews[];
};

export const ReviewsList = ({ reviews }: ReviewsListProps) => {
  const language = getSelectedLanguage();
  return (
    <>
      {reviews.map(review => (
        <Review
          key={review.id}
          text={review.summary}
          rating={review.score}
          avatarUrl={review.author?.avatarUrl}
          specialist={getFullName(review.spec)}
          username={getFullName(review.author)}
          service={getTranslation(review?.service, language)}
          date={moment(review.date).format('DD MMMM YYYY')}
          p="16px 0"
        />
      ))}
    </>
  );
};
