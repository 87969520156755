import styled from 'styled-components';
import { Flex, Div, Icon, mediaQueries } from '@beauty/beauty-market-ui';

export const BackWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const BlueIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  & path {
    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const LinkWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
`;

export const Overlay = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`;

export const StyledIcon = styled(Icon)`
  width: 16px !important;
  height: 16px !important;

  path {
    stroke: none !important;
    fill: ${({ theme }) => theme.colors.grey.standard};
  }
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  margin-bottom: 200px;

  ${mediaQueries.md} {
    margin-bottom: 0;
  }
`;
