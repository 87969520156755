import { useTranslation } from 'react-i18next';
import upperCase from 'lodash/upperCase';
import { Scroll } from '@beauty/beauty-market-ui';
import { sectionsBody } from '../helpers';
import { MenuWrapper, StyledScroll } from '../style';
import { MenuProps } from '../types';
import { MenuFooter } from './MenuFooter';
import { MenuHeader } from './MenuHeader';
import { MenuSection } from './MenuSection';

export const Menu = ({ isFull, onClose }: MenuProps) => {
  const { t } = useTranslation();

  return (
    <MenuWrapper>
      <MenuHeader isFull={isFull} onClose={onClose} />

      <StyledScroll>
        <Scroll scrollColor="#141520">
          {sectionsBody.map((section, i) => {
            const data = section(t);
            const title = upperCase(data.title);
            return <MenuSection key={i} isFull={isFull} title={title} items={data.items} onClose={onClose} />;
          })}
        </Scroll>
      </StyledScroll>

      <MenuFooter isFull={isFull} />
    </MenuWrapper>
  );
};
