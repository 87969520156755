import { useCallback, useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { Nullable } from 'tsdef';
// import { DownloadIcon } from '@beauty/beauty-market-ui';
// import { AdditionalAction, AdditionalActions } from 'components';
import { AsyncComponent } from 'hoc';
import { useGetClientsReports, useMediaScreen } from 'hooks';
import { Report, SortOrder } from 'types';
import { getSelectedLanguage } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentOrgId } from '../../../store/redux-slices/appointmentsSlice';
import { selectCurrency, selectHead } from '../../../store/redux-slices/organisationSlice';
import { Filter, FilterByAddress, FilterByDate, MainMetric, SubMetric, TableComponent } from '../components';
import { MetricsWrapper } from '../components/Metrics/style';
import { Page, ReportHeader, ReportTitle } from '../style';
import { parseClientReport } from './helpers';

export const Clients = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');

  const orgId = useAppSelector(selectCurrentOrgId);
  const headOrg = useAppSelector(selectHead);
  const currency = useAppSelector(selectCurrency);

  const language = getSelectedLanguage();
  const today = moment().format('YYYY-MM-DD');

  const [dateRange, setDateRange] = useState<[Nullable<string>, Nullable<string>]>([today, today]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortBy, setSortBy] = useState<Nullable<string>>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);
  const [isPending, startTransition] = useTransition();

  const { data, isLoading, isInitial } = useGetClientsReports({
    id: headOrg.id,
    start: dateRange[0] ? dateRange[0] : '',
    end: dateRange[1] ? dateRange[1] : '',
    orgId,
    page,
    pageSize,
    sortBy,
    sortOrder,
  });

  const { headers, body, mainMetric, subMetrics, pagination } = useMemo(
    (): Report => parseClientReport(data, language, currency, t),
    [data, language, currency, t],
  );

  const onChangePageSize = useCallback(
    (value: number) => {
      if (value === pageSize) return;
      startTransition(() => {
        setPage(1);
        setPageSize(value);
      });
    },
    [startTransition, setPage, setPageSize, pageSize],
  );

  const onHeaderCellClick = useCallback(
    (id: string) => {
      startTransition(() => {
        setSortBy(id);
        if (sortBy === id) {
          const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
          setSortOrder(newSortOrder);
        } else {
          setSortOrder('asc');
        }
      });
    },
    [startTransition, setSortBy, setSortOrder, sortOrder, sortBy],
  );

  // const downloadActions = [
  //   {
  //     body: (
  //       <AdditionalAction key="1" text={t('reports.download', { file: 'PDF' })} icon={<DownloadIcon />} padding="20px 8px" />
  //     ),
  //     onClick: () => console.log('PDF'),
  //   },
  //   {
  //     body: (
  //       <AdditionalAction
  //         key="2"
  //         text={t('reports.download', { file: 'Excel' })}
  //         icon={<DownloadIcon />}
  //         padding="20px 8px"
  //       />
  //     ),
  //     onClick: () => console.log('Excel'),
  //   },
  //   {
  //     body: (
  //       <AdditionalAction key="3" text={t('reports.download', { file: 'CSV' })} icon={<DownloadIcon />} padding="20px 8px" />
  //     ),
  //     onClick: () => console.log('CSV'),
  //   },
  // ];

  return (
    <AsyncComponent isLoading={!isInitial}>
      <Page>
        <ReportHeader>
          <ReportTitle>{capitalize(t('reports.clients.clients.many'))}</ReportTitle>
          {isDesktop && <FilterByAddress placeholder={t('reports.search')} stub />}
          <FilterByDate placeholder={today} onChangeRange={setDateRange} />
          {isMobile && <Filter label={t('common.filter')} descriptior={t('common.filterOptions')} />}
          {/* TODO: backend part not ready yet for this functionality, ask @Marat */}
          {/* <AdditionalActions items={downloadActions} /> */}
        </ReportHeader>
        <MetricsWrapper>
          <MainMetric
            title={t('reports.clients.metrics.totalClients')}
            value={mainMetric.total}
            progresses={mainMetric.progresses}
          />
          {subMetrics.map((item, index) => (
            <SubMetric
              key={item.title}
              title={item.title}
              subtitle={item?.subtitle}
              value={item?.value}
              badge={item?.badge}
              tooltip={item?.tooltip}
              order={index}
            />
          ))}
        </MetricsWrapper>
        <TableComponent
          body={body}
          headers={headers}
          page={page}
          pageSize={pageSize}
          sortOrder={sortOrder}
          sortBy={sortBy}
          onChangePage={setPage}
          onChangePageSize={onChangePageSize}
          onHeaderCellClick={onHeaderCellClick}
          isLoading={isLoading || isPending}
          totalPages={pagination.totalPages}
          caption={t('reports.pagesOf', {
            count: pagination.recordsRange as never,
            total: pagination.totalRecords,
          })}
        />
      </Page>
    </AsyncComponent>
  );
};
