import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Button, H6, Flex } from '@beauty/beauty-market-ui';
import { getActualPrice } from 'components/Calendar/Sidebars/helpers';
import { Loader, SidebarSheet, Widget } from 'components/index';
import { getFullName } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectClient, ThunkClient } from 'store/redux-slices/clientSlice';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { selectUserHead } from 'store/redux-slices/userSlice';
import { AppointmentByIdResponse } from 'types';
import { currencySymbol, EventStatus } from '../../../../constants';
import { InfoItem } from './InfoItem';
import { ReviewClient } from './ReviewClient';

type AppointmentInfoProps = {
  appointment: AppointmentByIdResponse;
  company: {
    name: string;
    email: string;
    address: string;
  };
  onClose: () => void;
  isLoading: boolean;
  price: number | string;
};

export const AppointmentInfo = ({ appointment, company, onClose, isLoading = false, price }: AppointmentInfoProps) => {
  const { t } = useTranslation();
  const userHead = useAppSelector(selectUserHead);
  const client = useAppSelector(selectClient);
  const currency = useAppSelector(selectCurrency);
  const currencySign = currencySymbol[currency];
  const actualPrice = getActualPrice(currencySign, appointment?.price ?? undefined, String(price));

  const dispatch = useAppDispatch();

  const [startReview, setStartReview] = useState(false);

  const handleStartReview = () => setStartReview(true);
  const handleCloseReview = () => setStartReview(false);

  const handleSubmit = async ({ score, summary }) => {
    if (userHead?.id) {
      const data = {
        score,
        summary,
        headOrgClientId: client.headOrgClientId,
        appointmentId: appointment.id,
        headOrgSpecId: userHead.id,
      };
      await dispatch(ThunkClient.sendReview({ id: client.headOrgClientId, data })).unwrap();
    }
  };

  const isShowReview = !appointment.clientReviewed && appointment.status !== EventStatus.NOSHOW;

  return (
    <SidebarSheet
      onClose={onClose}
      label={t('clients.profile.appointments.appointmentInfo')}
      descriptor=""
      FooterBody={
        <Flex width="100%" flexDirection="column">
          {isShowReview && (
            <Button onClick={handleStartReview} type="button" width="100%">
              {t('button.reviewClient')}
            </Button>
          )}
          <Button design="secondary" onClick={onClose} type="button" width="100%">
            {t('button.close')}
          </Button>
        </Flex>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <H6 mb="8px">{t('clients.profile.dateAndTime')}</H6>
          <InfoItem
            title={t('clients.profile.appointments.date')}
            subtitle={moment(appointment.end).format('DD.MM.YYYY')}
          />
          <InfoItem title={t('clients.profile.appointments.time')} subtitle={moment(appointment.end).format('HH:mm')} />
          <H6 mt="16px" mb="8px">
            {t('clients.profile.appointments.company')}
          </H6>
          <InfoItem title={t('clients.profile.appointments.name')} subtitle={company.name} />
          <InfoItem title={t('clients.profile.appointments.address')} subtitle={company.address} />
          <InfoItem title={t('clients.profile.appointments.email')} subtitle={company.email} />
          <H6 mt="16px" mb="8px">
            {t('clients.profile.appointments.customer')}
          </H6>
          <InfoItem title={t('clients.profile.clientName')} subtitle={getFullName(client)} />
          <InfoItem title={t('clients.profile.idClient')} subtitle={appointment.client?.idClientNumber} />
          <H6 mt="16px" mb="8px">
            {t('clients.profile.appointments.paymentMethod')}
          </H6>
          <InfoItem
            title={t('clients.profile.typeOfSale')}
            subtitle={
              appointment?.cardNumber ? t('clients.profile.appointments.card') : t('clients.profile.appointments.cash')
            }
          />
          <InfoItem title={t('clients.profile.cardNumber')} subtitle={appointment?.cardNumber} />
          <H6 mt="16px" mb="8px">
            {t('clients.profile.appointments.order')}
          </H6>
          <InfoItem
            subtitle={appointment.orgService.headOrgService.title.text}
            description={actualPrice /* getFormattedCurrency(appointment?.price ?? price, currency, language) */}
          />
          <H6 mt="16px" mb="8px">
            {t('clients.profile.appointments.payment')}
          </H6>
          <InfoItem
            subtitle={t('clients.profile.appointments.subTotal')}
            description={actualPrice /* getFormattedCurrency(appointment?.price ?? price, currency, language) */}
          />
          <Widget
            subtitle={<H6>{t('clients.profile.total')}</H6>}
            p="16px 0"
            description={actualPrice /* getFormattedCurrency(appointment?.price ?? price, currency, language) */}
            m="0 0 16px"
          />
          {startReview && (
            <ReviewClient
              client={appointment.client}
              descriptor={`${moment(appointment.end).format('DD.MM.YYYY')}, ${company.address}`}
              onSubmit={handleSubmit}
              onBack={handleCloseReview}
              onClose={onClose}
            />
          )}
        </>
      )}
    </SidebarSheet>
  );
};
