import { PinFilledIcon } from '@beauty/beauty-market-ui';
import { MapPointLocationWrapper } from './style';

interface ClusterMarkerProps {
  lat?: number;
  lng?: number;
  zIndex?: number;
  onClick?: () => void;
}

const MapPointLocation = ({ onClick }: ClusterMarkerProps) => (
  <MapPointLocationWrapper onClick={onClick}>
    <PinFilledIcon width="40px" height="40px" />
  </MapPointLocationWrapper>
);

export default MapPointLocation;
