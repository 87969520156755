import styled from 'styled-components';
import { Div, mediaQueries } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../../constants';

export const FilterWrapper = styled(Div)`
  position: relative;
`;
export const SmallCalendar = styled(Div)`
  position: absolute;
  z-index: ${zIndex.dropdown};
  top: calc(100% + 4px);

  [dir='ltr'] & {
    right: -32px;
  }

  [dir='rtl'] & {
    left: -32px;
  }

  ${mediaQueries.xs} {
    [dir='ltr'] & {
      right: 0;
    }

    [dir='rtl'] & {
      left: 0;
    }
  }
`;
