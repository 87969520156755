import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  BodySmall,
  Caption,
  colors,
  ExtendedSearchInput,
  Flex,
  Scroll,
  useDropdown,
} from '@beauty/beauty-market-ui';
import { checkAccess, getFullContact, getFullName } from 'helpers';
import { useClientSearch, useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes';
import { AccessRange } from '../../../constants';
import { Banner } from '../Banner';
import { CRMHeaderContainer, CRMHeaderWrapper, OptionsList, OptionWrapper, SearchWrapper } from '../style';
import { HeaderTools } from './ServiceTools/HeaderTools';

export const CRMHeader = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { open, ref, onOpen, onClose } = useDropdown();
  const { filteredClients, onSearch, clients, onReset } = useClientSearch();

  const navigate = useNavigate();
  const isAdmin = checkAccess(AccessRange.ADMIN);

  return (
    <CRMHeaderContainer>
      <Banner />
      <CRMHeaderWrapper>
        <SearchWrapper ref={ref}>
          <ExtendedSearchInput
            onlyService
            placeholder={t('clients.placeholder')}
            maxWidth="100%"
            onChange={onSearch}
            onFocus={onOpen}
            onCrossClick={onReset}
            zIndex={1}
            inputDisabled={!isAdmin || !clients.length}
          />
          {open && (
            <OptionsList>
              <Scroll maxHeight="285px">
                {filteredClients.map(item => (
                  <OptionWrapper
                    key={item.id}
                    onClick={() => {
                      navigate(generatePath(RouterUrl.ClientPageData, { id: item.headOrgClientId }));
                      onClose();
                    }}
                  >
                    <Avatar size="xs" url={item?.avatar} anonymos="default" />
                    <Flex flexDirection="column" width="100%">
                      <BodySmall width="100%">{getFullName(item)}</BodySmall>
                      <Caption color={colors.grey.dark}>
                        {getFullContact(
                          isDesktop
                            ? item
                            : {
                                ...item,
                                email: null,
                              },
                          ' • ',
                        )}
                      </Caption>
                    </Flex>
                  </OptionWrapper>
                ))}
              </Scroll>
            </OptionsList>
          )}
        </SearchWrapper>
        <HeaderTools />
      </CRMHeaderWrapper>
    </CRMHeaderContainer>
  );
};
