import { useTranslation } from 'react-i18next';
import { H6 } from '@beauty/beauty-market-ui';
import { CardStub } from 'components/features/Payment/CardStub';

type PaymentMethodRadioProps = {
  isCash: boolean;
  setIsCash: (isCash: boolean) => void;
};
export const PaymentMethodRadio = ({ isCash, setIsCash }: PaymentMethodRadioProps) => {
  const { t } = useTranslation();
  return (
    <>
      <H6 mt="8px">{t('calendar.newAppointmentSidebar.details.paymentMethod')}</H6>
      <CardStub
        mb="0"
        checked={isCash}
        onChange={() => setIsCash(true)}
        title={t('calendar.newAppointmentSidebar.details.byCash')}
        info={t('calendar.newAppointmentSidebar.details.cashInfo')}
        notIcons
      />
      <CardStub
        mb="0"
        checked={!isCash}
        onChange={() => setIsCash(false)}
        title={t('calendar.newAppointmentSidebar.details.byCard')}
        info={t('calendar.newAppointmentSidebar.details.cardInfo')}
      />
    </>
  );
};
