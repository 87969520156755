import { TFunction } from 'react-i18next';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { Nullable } from 'tsdef';
import { colors } from '@beauty/beauty-market-ui';
import {
  getCurrencySymbol,
  getFullName,
  getGenderTranslate,
  getPaymentTranslate,
  getPluralForm,
  getTranslation,
  sortProgresses,
} from 'helpers';
import { ClientHeaders, ClientReportResponse, Language, Report } from 'types';
import { RouterUrl } from '../../../routes';

const parseMetrics = (
  t: TFunction<'translation', undefined>,
  language: string,
  currency: Nullable<string>,
  generalInfo?: ClientReportResponse['generalInfo'],
): { mainMetric: Report['mainMetric']; subMetrics: Report['subMetrics'] } => {
  const translatedService = getTranslation(generalInfo?.popularService?.title, language);
  const translatedMethod = getPaymentTranslate(t, generalInfo?.popularPaymentMethod?.method);
  const currencySymbol = getCurrencySymbol(currency, language);
  const suffix = getPluralForm(Number(generalInfo?.popularSource?.count ?? 0));

  const metrics = [
    {
      value: generalInfo?.totalClients?.regular ?? 0,
      text: t('reports.clients.metrics.regular'),
      tooltip: t('reports.clients.metrics.regular'),
      color: colors.blue.medium,
    },
    {
      value: generalInfo?.totalClients?.new ?? 0,
      text: t('reports.clients.metrics.new'),
      tooltip: t('reports.clients.metrics.new'),
      color: colors.blue.darker,
    },
  ];

  return {
    mainMetric: {
      total: generalInfo?.totalClients?.total ?? 0,
      progresses: sortProgresses(metrics),
    },
    subMetrics: [
      {
        title: t('reports.clients.metrics.source'),
        subtitle: t(`reports.clients.clients.${suffix}`),
        value: generalInfo?.popularSource?.count ?? 0,
        badge: generalInfo?.popularSource?.source,
      },
      {
        title: t('reports.clients.metrics.averageAge'),
        value: generalInfo?.averageAge ?? 0,
      },
      {
        title: t('reports.clients.metrics.popularService'),
        value: translatedService,
        tooltip: translatedService,
      },
      {
        title: t('reports.clients.metrics.paymentMethod'),
        subtitle: generalInfo?.popularPaymentMethod?.totalAmount ? currencySymbol : null,
        value: generalInfo?.popularPaymentMethod?.totalAmount ?? '-',
        badge: translatedMethod,
      },
    ],
  };
};

const parseTable = (
  t: TFunction<'translation', undefined>,
  tableInfo: ClientReportResponse['tableInfo'],
): { headers: Report['headers']; body: Report['body'] } => ({
  headers: [
    {
      id: ClientHeaders.Name,
      title: t('reports.clients.table.client'),
      baseUrl: RouterUrl.ClientPageData,
      target: '_blank' as const,
      sortable: true,
    },
    {
      id: ClientHeaders.PhoneNumber,
      title: t('reports.clients.table.phoneNumber'),
      tooltip: t('reports.clients.table.phoneNumberCaption'),
    },
    { id: ClientHeaders.Gender, title: t('reports.clients.table.gender') },
    { id: ClientHeaders.Age, title: t('reports.clients.table.age') },
    {
      id: ClientHeaders.Income,
      title: t('reports.clients.table.income'),
      tooltip: t('reports.clients.table.incomeCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.Rating,
      title: t('reports.clients.table.rating'),
      tooltip: t('reports.clients.table.ratingCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.TotalBookings,
      title: t('reports.clients.table.totalBookings'),
      tooltip: t('reports.clients.table.totalBookingsCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.NoShow,
      title: t('reports.clients.table.noShow'),
      tooltip: t('reports.clients.table.noShowCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.Future,
      title: t('reports.clients.table.future'),
      sortable: true,
    },
    {
      id: ClientHeaders.Cancelled,
      title: t('reports.clients.table.cancelled'),
      tooltip: t('reports.clients.table.cancelledCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.FirstVisit,
      title: t('reports.clients.table.firstVisit'),
      tooltip: t('reports.clients.table.firstVisitCaption'),
      sortable: true,
    },
    {
      id: ClientHeaders.LastVisit,
      title: t('reports.clients.table.lastVisit'),
      tooltip: t('reports.clients.table.lastVisitCaption'),
      sortable: true,
    },
  ],
  body: tableInfo.map(item => ({
    id: item.clientId,
    [ClientHeaders.Name]: getFullName(item),
    [ClientHeaders.PhoneNumber]: item.phone_number ? parsePhoneNumber(item.phone_number).formatInternational() : null,
    [ClientHeaders.Gender]: getGenderTranslate(t, item.gender) || null,
    [ClientHeaders.Age]: item.age,
    [ClientHeaders.Income]: item.income,
    [ClientHeaders.Rating]: item.rating,
    [ClientHeaders.TotalBookings]: item.totalBookings,
    [ClientHeaders.NoShow]: item.noShow,
    [ClientHeaders.Future]: item.future,
    [ClientHeaders.Cancelled]: item.cancelled,
    [ClientHeaders.FirstVisit]: item.firstVisit,
    [ClientHeaders.LastVisit]: item.lastVisit,
  })),
});

export const parsePagination = (language: string, pagination?: ClientReportResponse['pagination']) => {
  const pageSize = pagination?.pageSize ?? 10;
  const recordsRange = pagination?.recordsRange ?? `${0}-${pageSize}`;
  return {
    pageSize,
    totalPages: pagination?.totalPages ?? 1,
    currentPage: pagination?.currentPage ?? 1,
    recordsRange: language === Language.Hebrew ? recordsRange.split('-').reverse().join('-') : recordsRange,
    totalRecords: pagination?.totalRecords ?? 0,
  };
};

export const parseClientReport = (
  report: Nullable<ClientReportResponse>,
  language: string,
  currency: string,
  t: TFunction,
): Report => {
  const { mainMetric, subMetrics } = parseMetrics(t, language, currency, report?.generalInfo);
  const { headers, body } = parseTable(t, report?.tableInfo ?? []);
  const pagination = parsePagination(language, report?.pagination);

  return {
    headers,
    body,
    mainMetric,
    subMetrics,
    pagination,
  };
};
