import { memo } from 'react';
import { generatePath } from 'react-router';
import { Link } from '@beauty/beauty-market-ui';
import { BodyCellWrapper } from './style';

const TBodyRow = ({ item, headers }) => (
  <tr key={item.id}>
    {headers.map(headerKey => {
      const cellContent = item[headerKey.id] ?? '-';
      return (
        <BodyCellWrapper key={`${headerKey.id} ${item.id}`}>
          {headerKey.baseUrl ? (
            <Link
              design="blue"
              size="xs"
              target={headerKey.target}
              href={generatePath(headerKey.baseUrl, { id: item.id })}
            >
              {cellContent}
            </Link>
          ) : (
            cellContent
          )}
        </BodyCellWrapper>
      );
    })}
  </tr>
);

export const BodyRow = memo(TBodyRow);
