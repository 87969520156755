import { Flex, Avatar, H5, BodySmall, colors } from '@beauty/beauty-market-ui';
import { ImageType } from '../../types';

type RatingCardProps = {
  avatarUrl: ImageType;
  title: string;
  info: string;
  isMobile?: boolean;
};

export const RatingCard = ({ avatarUrl, title, info, isMobile }: RatingCardProps) => (
  <Flex flexDirection="column" alignItems="center">
    <Avatar size={isMobile ? 'l' : 'xl'} url={avatarUrl} />
    <Flex flexDirection="column" justifyContent="end" mt="16px" mb="104px" maxWidth="240px">
      <H5 mb="5px">{title}</H5>
      <BodySmall lowline color={colors.grey.dark}>
        {info}
      </BodySmall>
    </Flex>
  </Flex>
);
