import { useEffect } from 'react';
import { EditHeadOrganisation } from 'components';
import { useMediaScreen, useRequest, useToggle } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ThunkAddress } from 'store/redux-slices/addressSlice';
import { selectHead, ThunkOrganisation } from 'store/redux-slices/organisationSlice';
import { ThunkService } from 'store/redux-slices/serviceSlice';
import { ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { selectUserHead } from 'store/redux-slices/userSlice';
import { EditHeadOrganisationType, OrganizationAction } from 'types';
import OrganisationCard from './OrganisationCard/OrganisationCard';
import { AddressSection, ServiceSection, SpecialistsSection, WelcomeSection } from './OrganisationInfo';
import { OrganisationInfoContainer } from './OrganisationInfo/style';

export const AccountSetting = () => {
  const { isDesktop, isMobile } = useMediaScreen('md');

  const userHead = useAppSelector(selectUserHead);
  const headOrg = useAppSelector(selectHead);
  const dispatch = useAppDispatch();

  const [open, toggleOpen] = useToggle(!userHead);

  const createHeadOrganisation = useRequest(ThunkOrganisation.createHead, OrganizationAction.CreateOrganisation);

  const onCreateOrganisation = (data: EditHeadOrganisationType) =>
    createHeadOrganisation(data).then(() => toggleOpen());

  const isCompanyExist = headOrg && userHead;
  const headOrgId = userHead?.headOrgId;

  useEffect(() => {
    if (headOrgId) {
      dispatch(ThunkOrganisation.fetchHead(headOrgId));
      dispatch(ThunkSpecialist.fetchAllSpecialists(headOrgId));
      dispatch(ThunkAddress.fetchAllAddresses(headOrgId));
      dispatch(ThunkService.fetchAllServices(headOrgId));
      dispatch(ThunkService.fetchAllCategory(headOrgId));
    }
  }, [headOrgId]);

  return (
    <>
      <OrganisationInfoContainer>
        {isCompanyExist && isMobile && <OrganisationCard />}
        <WelcomeSection />
        <AddressSection />
        <SpecialistsSection />
        <ServiceSection />
      </OrganisationInfoContainer>
      {isCompanyExist && isDesktop && <OrganisationCard />}
      {open && <EditHeadOrganisation onSubmit={onCreateOrganisation} />}
    </>
  );
};
