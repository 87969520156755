import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { LeftIcon, RightIcon } from '@beauty/beauty-market-ui';
import { CustomCalendarHeader, DatePickerWrapper, StyledNavButton } from '../../../Calendar/Sidebars/style';

type SmallCalendarProps = {
  date: string;
  onChange: (date: Date) => void;
};

// TODO: remove and try to use DataPicker from library
export const SmallCalendar = ({ date, onChange }: SmallCalendarProps) => (
  <DatePickerWrapper>
    <DatePicker
      selected={moment(date, 'DD.MM.yyyy').toDate()}
      minDate={new Date()}
      onChange={onChange}
      inline
      renderCustomHeader={({
        date: headerDate,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <CustomCalendarHeader>
          <StyledNavButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} design="tertiaryBlack">
            <LeftIcon />
          </StyledNavButton>
          {moment(headerDate).format('MMMM')}
          <StyledNavButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} design="tertiaryBlack">
            <RightIcon />
          </StyledNavButton>
        </CustomCalendarHeader>
      )}
      onSelect={onChange}
      calendarStartDay={1}
    />
  </DatePickerWrapper>
);
