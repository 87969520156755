import styled from 'styled-components';
import { Div, Flex, ArrowRightIcon, mediaQueries } from '@beauty/beauty-market-ui';

export const SidebarContentWrapper = styled(Flex)`
  flex-direction: column;
`;

export const SidebarContentSection = styled(Flex)`
  flex-direction: column;
  margin-bottom: 24px;
  gap: 16px;
`;

export const PhoneInputsWrapper = styled(Div)`
  display: grid;
  grid-template-columns: 105px 1fr;
`;

export const CategoriesWrapper = styled(Div)`
  gap: 8px;
  display: grid;

  & > div {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    & > div {
      height: 100%;
      justify-content: center;
    }
  }

  @media (max-width: 475px) {
    grid-template-columns: repeat(3, 33.3%);
    width: calc(100% - 16px);
  }
  @media (min-width: 476px) and (max-width: 676px) {
    grid-template-columns: repeat(4, 25%);
    width: calc(100% - 24px);
  }
  @media (min-width: 677px) {
    grid-template-columns: repeat(5, 20%);
    width: calc(100% - 32px);
  }

  ${mediaQueries.md} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 4px);
    & > div {
      width: 106px;
      height: 106px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
  span {
    text-align: center !important;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;
