import styled, { css } from 'styled-components';
import {
  BodySmallBasicStyles,
  Button,
  CaptionBasicStyles,
  Icon,
  Flex,
  mediaQueries,
  Pagination,
  Div,
} from '@beauty/beauty-market-ui';
import { Loader } from 'components';

export const Content = styled(Flex)<{ fullScreen: boolean }>`
  position: relative;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  flex-grow: 1;
  padding: 0;
  border-radius: 10px;

  height: 500px;

  margin-bottom: 80px;

  ${mediaQueries.md} {
    margin-bottom: 0;
  }

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      inset: 8px;
      height: calc(100% - 16px);
      width: calc(100% - 16px);
      z-index: 100;
    `};
`;

export const Table = styled.table`
  position: relative;
  display: block;
  flex-grow: 1;
  width: 100%;
  max-height: inherit;
  border-radius: 16px;
  overflow: auto;
  border-collapse: collapse;
  table-layout: fixed;

  ::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.grey.standard};

    & {
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white.standard};
  box-shadow: 0 2px 2px 0 #00000014;
`;

export const Arrow = styled(Icon)<{ isShow: boolean }>`
  flex-shrink: 0;
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
`;

const CellStyle = css`
  width: 100%;
  max-width: max-content;
  white-space: nowrap;
  box-sizing: border-box;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};

  [dir='ltr'] & {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }
  [dir='rtl'] & {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
`;

export const HeaderCellWrapper = styled.th<{ cursor: string }>`
  ${CellStyle};
  ${BodySmallBasicStyles(true, false)};

  padding: 18px 16px;
  width: 100%;

  &:first-child {
    padding: 18px 24px;

    [dir='ltr'] & {
      border-left: 1px solid transparent;
    }

    [dir='rtl'] & {
      border-right: 1px solid transparent;
    }
  }

  &:hover {
    cursor: ${({ cursor }) => cursor};
  }
`;

export const BodyCellWrapper = styled.td<{ selectable?: boolean }>`
  ${CellStyle};
  ${CaptionBasicStyles(false, false)};

  padding: 12px 16px;
  width: 100%;

  &:first-child {
    padding: 12px 24px;

    [dir='ltr'] & {
      border-left: 1px solid transparent;
    }

    [dir='rtl'] & {
      border-right: 1px solid transparent;
    }
  }
`;

export const TableFooter = styled(Pagination)`
  margin-top: 4px;
  border-radius: 0 0 10px 10px;
`;

export const FullScreenButton = styled(Button)`
  box-sizing: border-box;
  padding: 9px;
  width: 40px;
  height: 40px;
`;

export const LoaderOverlay = styled(Div)`
  position: absolute;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.white.standard};
  opacity: 0.5;
  z-index: 100;
`;

export const TableLoader = styled(Loader)`
  position: absolute;
  inset: 0;
  top: 50%;
  left: 50%;
`;
