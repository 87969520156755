import { Flex, Avatar, BodyLarge, BodySmall, colors, Checkbox, Separator, Caption } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';

export const DataCheckbox = ({ image, separator, title, subtitle, onChange, checked, isDesktop, p = '8px 0' }) => (
  <>
    <Flex alignItems="center" justifyContent="space-between" p={p}>
      <Flex gap="8px">
        {typeof image === 'string' || typeof image === 'undefined' ? <Avatar url={image} rating="" size="s" /> : image}
        <Flex flexDirection="column" width={isDesktop ? '330px' : '65vw'} justifyContent="center" gap="4px">
          <Adaptive mobile={BodyLarge} desktop={BodySmall} truncated large={!isDesktop} title={title}>
            {title}
          </Adaptive>
          {subtitle && (
            <Adaptive mobile={Caption} desktop={BodySmall} truncated color={colors.grey.dark} title={subtitle}>
              {subtitle}
            </Adaptive>
          )}
        </Flex>
      </Flex>
      <Checkbox shape="round" onCheckboxClick={onChange} checked={checked} />
    </Flex>
    {separator && <Separator />}
  </>
);
