import { ReactNode } from 'react';
import {
  Avatar,
  AvatarSizesKey,
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  colors,
  Flex,
  H6,
  Separator,
} from '@beauty/beauty-market-ui';
import { getDir } from 'helpers/utils';
import { Adaptive } from 'hoc';
import { ContactInfo, Container } from '../style';

export type ClientCardProps = {
  icon: ReactNode;
  heading: string;
  subtitle: string;
  onClick?: () => void;
  avatar?: string | null | File;
  avatarSize?: AvatarSizesKey;
  isMobile?: boolean;
};

export const ClientCard = ({
  heading,
  subtitle,
  onClick,
  icon,
  avatar,
  isMobile,
  avatarSize = 'xs',
}: ClientCardProps) => (
  <>
    <Container onClick={onClick} width="100%" justifyContent="space-between">
      <Flex alignItems="start" gap="8px" maxWidth="83%">
        <Avatar size={avatarSize} url={avatar || undefined} anonymos="default" />
        <Flex flexDirection="column" aligItems="start" maxWidth="93%">
          <Adaptive desktop={H6} mobile={BodyLarge} large={isMobile} truncated directed dir={getDir(heading)}>
            {heading}
          </Adaptive>
          <ContactInfo desktop={BodySmall} mobile={Caption} color={colors.grey.dark} truncated dir="ltr">
            {subtitle}
          </ContactInfo>
        </Flex>
      </Flex>
      <Button design="tertiaryBlack" prefix={icon} justyfySelf="end" p="10px" />
    </Container>
    <Separator ml="56px" />
  </>
);
