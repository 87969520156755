import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataList, Section, Separator } from '@beauty/beauty-market-ui';
import { Container, EditHeadOrganisation } from 'components';
import { useRequest, useToggle } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { ThunkOrganisation } from 'store/redux-slices/organisationSlice';
import { selectUserHead } from 'store/redux-slices/userSlice';
import { EditHeadOrganisationType, OrganizationAction } from 'types';
import OrganisationCard from '../../AccountSetting/OrganisationCard/OrganisationCard';
import { IconWrapper, LinkWrapper } from '../style';
import { getSettings } from './helpers';

export const ProfileSettings = () => {
  const { t } = useTranslation();
  const settings = getSettings(t);
  const navigate = useNavigate();
  const userHead = useAppSelector(selectUserHead);

  const [open, toggleOpen] = useToggle(false);

  const editHeadOrganisation = useRequest(ThunkOrganisation.editHead, OrganizationAction.EditOrganisation);

  const onSubmit = useCallback(
    (data: EditHeadOrganisationType) => editHeadOrganisation(data).then(() => toggleOpen()),
    [editHeadOrganisation, toggleOpen],
  );

  return (
    <>
      <Container width="100%" padding={0}>
        <Section title={`${t('profile.organisationSettings')}`} items={[]} button={null}>
          {settings.map((item, index, arr) => {
            const handleClick = () => (item.link ? navigate(item.link) : toggleOpen());

            return (
              <Fragment key={item.id}>
                <LinkWrapper onClick={handleClick}>
                  <IconWrapper>{item.icon}</IconWrapper>
                  <DataList label={item.label} description={item.subtitle} />
                </LinkWrapper>
                {index !== arr.length - 1 && <Separator />}
              </Fragment>
            );
          })}
        </Section>
      </Container>
      {userHead && <OrganisationCard />}
      {open && <EditHeadOrganisation onSubmit={onSubmit} onClose={toggleOpen} editMode />}
    </>
  );
};
