import { useTranslation } from 'react-i18next';
import { Caption, colors, Flex, getMinWidthMediaQuery, H6, TagButton, useMediaQuery } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers';
import { useGetProperties } from '../../../hooks';

type PropertiesProps = {
  limit: number;
  selected: string[];
  onSelect: (value: string[]) => void;
};

export const Properties = ({ selected, onSelect, limit }: PropertiesProps) => {
  const { t } = useTranslation();
  const { properties } = useGetProperties();
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return (
    <Flex flexDirection="column" gap="16px" width="100%">
      <Flex alignItems="center" justifyContent="space-between">
        <H6>{t('organisation.properties')}</H6>
        <Caption lowline color={colors.grey.dark}>
          {selected.length === 0 && isDesktop
            ? t('organisation.maximumProperties', { limit })
            : `${selected.length}/${limit} ${t('organisation.selected')}`}
        </Caption>
      </Flex>
      <Flex flexWrap="wrap" gap="8px">
        {properties.map(item => {
          const isSelected = selected.includes(item.id);
          const isOverLimit = !isSelected && selected.length >= limit;

          const handleChange = () => {
            const updatedProperties = isSelected ? selected.filter(id => id !== item.id) : [...selected, item.id];
            !isOverLimit && onSelect(updatedProperties);
          };

          return (
            <TagButton
              flexGrow={1}
              key={item.id}
              text={getTranslation(item.title, language)}
              design={isSelected ? 'active' : 'default'}
              onClick={handleChange}
              hovered={isSelected || !isOverLimit}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
