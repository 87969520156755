import styled, { css, keyframes } from 'styled-components';
import { colors, Flex } from '@beauty/beauty-market-ui';

export const CircleContainer = styled(Flex)<{ size: number }>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

export const CircleSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

export const BackgroundCircle = styled.circle<{ defaultColor?: string }>`
  fill: none;
  stroke: ${({ defaultColor }) => defaultColor || colors.grey.light};
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px` || '10px'};
`;

const fillRound = (value: number) => keyframes`
  100% {
    stroke-dashoffset: ${value};
  }
`;

const countCircleLength = (radius?: string | number) => 2 * Math.PI * Number(radius);
const countDashOffset = (progress: number, radius?: string | number) => (1 - progress) * countCircleLength(radius);

export const ProgressCircle = styled.circle<{
  progress: number;
  color: string;
  animated?: boolean;
}>`
  stroke: ${({ color }) => color};
  stroke-dasharray: ${({ r }) => `${countCircleLength(r)}`};
  stroke-dashoffset: ${({ r, progress, animated }) => (animated ? countCircleLength(r) : countDashOffset(progress, r))};

  fill: none;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;

  ${({ progress, r, animated }) =>
    animated &&
    css`
      animation: ${fillRound(countDashOffset(progress, r))} 1.2s linear forwards;
    `};
`;

export const CircleContent = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
`;
