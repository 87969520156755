import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Div } from '@beauty/beauty-market-ui';

export const Background = styled(Div)<{ bgColor?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ bgColor }) => bgColor || 'rgba(0, 0, 0, 0.5)'};
  z-index: 99;
`;

type OverlayProps = {
  show?: boolean;
  bgColor?: boolean;
  children?: ReactNode;
};

export const Overlay = ({ children, show, bgColor }: OverlayProps) => (
  <>
    {show && createPortal(<Background bgColor={bgColor} />, document.getElementById('root') as HTMLElement)}
    {children}
  </>
);
