import { ReactNode } from 'react';
import { MenuDotsIcon, useDropdown } from '@beauty/beauty-market-ui';
import { ActionsDropdown, ActionsWrapper } from './style';

type AdditionalActionsProps = {
  items: Array<{ body: ReactNode; onClick: () => void }>;
};

export const AdditionalActions = ({ items }: AdditionalActionsProps) => {
  const { open, onOpen, ref, onClose } = useDropdown();

  return (
    <ActionsWrapper onClick={onOpen} ref={ref}>
      <MenuDotsIcon width={22} height={22} />
      {open && <ActionsDropdown items={items} onClick={onClose} />}
    </ActionsWrapper>
  );
};
