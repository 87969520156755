import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  ExtendedSearchInput,
  Flex,
  H3,
  H4,
  LeftIcon,
  PlusIcon,
  RightIcon,
  Scroll,
  Separator,
} from '@beauty/beauty-market-ui';
import { getFullContact, getFullName, isRtl } from 'helpers';
import { Adaptive } from 'hoc';
import { useClientSearch, useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { InputWrapper, StyledButton, Wrapper } from '../style';
import { ClientCard } from './ClientCard';

export const ClientSection = ({ onAddClick }) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');
  const { filteredClients, onReset, onSearch } = useClientSearch();

  const rtl = isRtl();
  const navigate = useNavigate();

  return (
    <Wrapper maxWidth="1280px" borderRadius="10px" delta={isDesktop ? '170px' : '72px'}>
      <Flex width="100%" justifyContent="space-between" pb={isDesktop ? '16px' : '24px'} alignItems="center">
        <Adaptive desktop={H3} mobile={H4}>
          {t('clients.title')}
        </Adaptive>
        <StyledButton size="small" prefix={<PlusIcon />} onClick={onAddClick}>
          {isDesktop ? t('clients.add') : ''}
        </StyledButton>
      </Flex>
      {isDesktop && <Separator mb="24px" />}
      <InputWrapper>
        <ExtendedSearchInput
          onlyService
          placeholder={t('clients.placeholder')}
          maxWidth="100%"
          onChange={onSearch}
          onCrossClick={onReset}
          zIndex={1}
        />
      </InputWrapper>
      <Scroll scrollColor={colors.grey.light} mt="100px">
        {filteredClients.map(item => (
          <ClientCard
            key={item.headOrgClientId}
            avatar={item.avatar}
            isMobile={isMobile}
            heading={getFullName(item)}
            subtitle={getFullContact(isDesktop ? item : { ...item, email: null }, ' • ')}
            onClick={() => navigate(generatePath(RouterUrl.ClientPageData, { id: item.headOrgClientId }))}
            icon={rtl ? <LeftIcon width="20px" height="20px" /> : <RightIcon height="20px" width="20px" />}
          />
        ))}
      </Scroll>
    </Wrapper>
  );
};
