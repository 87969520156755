export enum RouterUrl {
  Homepage = '/',
  AllOffers = '/all-offers',
  LoginIntro = '/login-intro',
  LogOut = '/logout',
  Login = '/login',
  LoginSMS = '/login/sms',
  Welcome = '/welcome',
  Business = '/login/business',
  EveryOne = '/login/everyone',
  SignUp = '/login/signup',
  EmailVerification = '/login/email-verification',
  Registration = '/login/registration',
  PhoneVerification = '/login/phone-verification',
  UpdatePassword = '/login/update-password',
  ResetPassword = '/login/reset-password',
  SentEmailNotification = '/login/reset-password/sent-email-notification',
  NotPermission = '/not-permission',
  Client = '/client',
  ClientProfile = '/client/profile',
  AccountSetting = '/account-setting',
  Subscription = 'subscription',
  WelcomeCrm = '/welcome-crm',
  ForBusiness = '/for-business',
  Calendar = '/calendar',
  ProfileSettings = '/profile-settings',
  CurrentPlanPage = '/profile-settings/current-plan',
  ChangePlanPage = '/profile-settings/change-plan',
  UnsubscribePage = '/profile-settings/unsubscribe',
  OrganisationSettings = '/profile-settings/organisation',
  NotificationSettings = '/profile-settings/notification',
  PersonalProfile = '/personal-profile',
  Clients = '/clients',
  ClientPageData = '/clients/:id/client-data',
  ClientAppointments = '/clients/:id/appointments',
  ClientReviews = '/clients/:id/reviews',
  ClientPayments = '/clients/:id/payments',
  EmployeeList = '/employee-list',
  EmployeePage = '/employee-list/:id',
  EmployeeGeneralData = '/employee-list/:id/general-data',
  EmployeePersonalInfo = '/employee-list/:id/personal-info',
  AddressList = '/address-list',
  AddressPage = '/address-list/:id',
  AddressGeneralData = '/address-list/:id/general-data',
  AddressAboutCompany = '/address-list/:id/about-company',
  ServiceList = '/service-list',
  Reports = '/reports',
  ClientReports = '/reports/clients',
}
