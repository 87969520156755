import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { Nullable } from 'tsdef';
import * as Yup from 'yup';
import { getShortCountryByCode, photoValidation, getPluralForm } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectHead } from 'store/redux-slices/organisationSlice';
import { ImageType, OptionType } from 'types';
import {
  BELARUS_POSTAL_CODE_REGEXP,
  DIGITS_ONLY_REGEXP,
  emailReg,
  ISRAEL_POSTAL_CODE_REGEXP,
  MAX_PHOTO_SIZE,
  OTHER_POSTAL_CODE_REGEXP,
  POLAND_POSTAL_CODE_REGEXP,
  RUSSIA_POSTAL_CODE_REGEXP,
  UK_POSTAL_CODE_REGEXP,
  UKRAINE_POSTAL_CODE_REGEXP,
  USA_POSTAL_CODE_REGEXP,
} from '../../../../constants';

export enum EditHeadOrganisationFormFields {
  Photo = 'photo',
  Name = 'name',
  EmployeesNum = 'employeesNum',
  Email = 'email',
  RegistrationId = 'registrationId',
  Code = 'code',
  Phone = 'number',
  Category = 'category',
  Currency = 'currency',
  Country = 'country',
  City = 'city',
  Street = 'street',
  Building = 'building',
  Office = 'office',
  Post = 'postal',
  Description = 'description',
  Latitude = 'lat',
  Longitude = 'lng',
  FullAddress = 'fullAddress',
}

export interface EditHeadOrganisationFormTypes {
  [EditHeadOrganisationFormFields.Photo]: Nullable<ImageType>;
  [EditHeadOrganisationFormFields.Name]: string;
  [EditHeadOrganisationFormFields.RegistrationId]: Nullable<string>;
  [EditHeadOrganisationFormFields.EmployeesNum]: number;
  [EditHeadOrganisationFormFields.Email]: string;
  [EditHeadOrganisationFormFields.Code]: string;
  [EditHeadOrganisationFormFields.Phone]: string;
  [EditHeadOrganisationFormFields.Category]: string[];
  [EditHeadOrganisationFormFields.Currency]: Nullable<OptionType>;
  [EditHeadOrganisationFormFields.Country]: string;
  [EditHeadOrganisationFormFields.City]: string;
  [EditHeadOrganisationFormFields.Street]: string;
  [EditHeadOrganisationFormFields.Building]: string;
  [EditHeadOrganisationFormFields.Office]: Nullable<string>;
  [EditHeadOrganisationFormFields.Post]: Nullable<string>;
  [EditHeadOrganisationFormFields.Description]: Nullable<string>;
  [EditHeadOrganisationFormFields.FullAddress]: Nullable<string>;
  [EditHeadOrganisationFormFields.Latitude]: Nullable<number>;
  [EditHeadOrganisationFormFields.Longitude]: Nullable<number>;
}

export const getInitialValues = (currencyOptions: OptionType[]) => {
  const head = useAppSelector(selectHead);
  const currencyItem = currencyOptions.find(item => head?.currency === item.id);
  return {
    photo: head.photo || null,
    name: head.name?.text || '',
    employeesNum: head.employeesNum ? Number(head.employeesNum) : 0,
    code: head.contact?.phone[0]?.code ?? '',
    number: head.contact?.phone[0]?.number ?? '',
    email: head.contact?.email?.email,
    registrationId: head.registrationId ?? '',
    category: head.headOrgCategory.map(item => item.serviceCategoryId),
    currency: currencyItem || null,
    country: head.address?.country ?? '',
    city: head.address?.city ?? '',
    street: head.address?.street ?? '',
    building: head.address?.building ?? '',
    office: head.address?.office ?? '',
    postal: head.address?.postal ?? '',
    description: head.address?.description ?? '',
    lat: head.address?.lat ?? null,
    lng: head.address?.lng ?? null,
    fullAddress: head.address?.fullAddress ?? '',
  };
};

export const schema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EditHeadOrganisationFormFields.Photo]: photoValidation(t, MAX_PHOTO_SIZE),
    [EditHeadOrganisationFormFields.Name]: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .trim()
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.RegistrationId]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.${getPluralForm(2)}`, { count: 2 }))
      .max(30, t(`validation.minCharacters.${getPluralForm(30)}`, { count: 30 }))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.EmployeesNum]: Yup.string(),
    [EditHeadOrganisationFormFields.Email]: Yup.string()
      .trim()
      .email()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.Code]: Yup.string().required(t('validation.phoneRequired')),
    [EditHeadOrganisationFormFields.Phone]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent[EditHeadOrganisationFormFields.Code];
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[EditHeadOrganisationFormFields.Code];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
    [EditHeadOrganisationFormFields.Category]: Yup.array().min(1, t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.Country]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.many`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.City]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.many`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.Street]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.few`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.Building]: Yup.string()
      .trim()
      .min(1, t(`validation.minCharacters.few`, { count: 1 }))
      .max(4, t(`validation.maxCharacters.many`, { count: 4 }))
      .matches(DIGITS_ONLY_REGEXP, t('validation.onlyDigits'))
      .required(t('validation.notEmpty')),
    [EditHeadOrganisationFormFields.Post]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(20, t(`validation.maxCharacters.many`, { count: 20 }))
      .test('valid-postal-code', t('validation.incorrectPostalCode'), value => {
        if (!value) return true;
        return (
          RUSSIA_POSTAL_CODE_REGEXP.test(value) ||
          USA_POSTAL_CODE_REGEXP.test(value) ||
          POLAND_POSTAL_CODE_REGEXP.test(value) ||
          UK_POSTAL_CODE_REGEXP.test(value) ||
          OTHER_POSTAL_CODE_REGEXP.test(value) ||
          ISRAEL_POSTAL_CODE_REGEXP.test(value) ||
          UKRAINE_POSTAL_CODE_REGEXP.test(value) ||
          BELARUS_POSTAL_CODE_REGEXP.test(value) ||
          DIGITS_ONLY_REGEXP.test(value)
        );
      }),
    [EditHeadOrganisationFormFields.Office]: Yup.string()
      .trim()
      .min(1, t(`validation.minCharacters.one`, { count: 1 }))
      .max(4, t(`validation.maxCharacters.few`, { count: 4 }))
      .matches(DIGITS_ONLY_REGEXP, t('validation.onlyDigits')),
    [EditHeadOrganisationFormFields.Description]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(150, t(`validation.maxCharacters.many`, { count: 150 })),
  });
