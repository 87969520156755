import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { CloseIcon, colors, Expand } from '@beauty/beauty-market-ui';
import { Overlay } from 'hoc';
import { useMediaScreen, useToggle } from 'hooks';
import { ReportBody, ReportHeader, SortOrder } from '../../../../types';
import { BodyRow } from './BodyRow';
import { HeaderCell } from './HeaderCell';
import { Content, FullScreenButton, LoaderOverlay, Table, TableFooter, TableHeader, TableLoader } from './style';

type TableProps = {
  body: ReportBody[];
  headers: ReportHeader[];
  isLoading?: boolean;
  sortBy: Nullable<string>;
  sortOrder: SortOrder;
  page: number;
  totalPages: number;
  pageSize: number;
  caption: string;
  onHeaderCellClick: (id: string) => void;
  onChangePage: (value: number) => void;
  onChangePageSize: (value: number) => void;
};

const pageSizes = [10, 20, 30, 40];

const Component = ({
  body,
  headers,
  page,
  pageSize,
  totalPages,
  isLoading,
  sortBy,
  sortOrder,
  onChangePage,
  onChangePageSize,
  onHeaderCellClick,
  caption,
}: TableProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');
  const { isDesktop: isTablet } = useMediaScreen('sm');

  const [fullScreen, toggleFullScreen] = useToggle(false);

  return (
    <Overlay show={fullScreen} bgColor={colors.grey.light}>
      <Content fullScreen={fullScreen}>
        {isLoading && (
          <LoaderOverlay>
            <TableLoader text={t('common.loading')} type="text" color={colors.blue.standard} />
          </LoaderOverlay>
        )}
        <Table>
          <TableHeader>
            <tr>
              {headers.map(({ tooltip, id, title, sortable }) => (
                <HeaderCell
                  key={id}
                  id={id}
                  tooltip={tooltip}
                  title={title}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  sortable={sortable}
                  onHeaderCellClick={onHeaderCellClick}
                />
              ))}
            </tr>
          </TableHeader>
          <tbody>
            {body.map(item => (
              <BodyRow key={item.id} item={item} headers={headers} />
            ))}
          </tbody>
        </Table>
        <TableFooter
          totalPages={totalPages}
          page={page}
          pageSize={pageSize}
          options={pageSizes}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          rangeLimit={(isDesktop && 10) || (isTablet && 5) || (isMobile && 0)}
          caption={caption}
          rightIcon={
            <FullScreenButton
              design="secondary"
              onClick={toggleFullScreen}
              prefix={fullScreen ? <CloseIcon width={20} height={20} /> : <Expand width={20} height={20} />}
            />
          }
        />
      </Content>
    </Overlay>
  );
};

export const TableComponent = memo(Component);
