import { WidgetText } from './style';

type MonthHeaderProps = {
  title: string;
};

export const MonthHeader = ({ title }: MonthHeaderProps) => (
  <WidgetText width="100%" truncated>
    {title}
  </WidgetText>
);
