import { FilterIcon, useDropdown } from '@beauty/beauty-market-ui';
import { useAddressOptions, useMediaScreen } from 'hooks';
import { FilterBottomSheet } from './FilterBottomSheet';

export const Filter = ({ label, descriptior }) => {
  const { isDesktop } = useMediaScreen('md');
  const { selected, onSelect, options } = useAddressOptions(true);
  const { onOpen, open, onClose } = useDropdown();

  return (
    <>
      <FilterIcon onClick={onOpen} />
      {open && (
        <FilterBottomSheet
          open={open}
          onSubmit={onSelect}
          onClose={onClose}
          options={options}
          label={label}
          descriptor={descriptior}
          defaultValue={selected}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
};
