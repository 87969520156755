import { ReactNode } from 'react';
import { BodySmall, Flex } from '@beauty/beauty-market-ui';

type AdditionalActionProps = {
  text: string;
  icon?: ReactNode;
  padding?: string;
};

export const AdditionalAction = ({ text, icon, padding, ...rest }: AdditionalActionProps) => (
  <Flex gap="8px" cursor="pointer" p={padding} {...rest}>
    {icon}
    <BodySmall lowline>{text}</BodySmall>
  </Flex>
);
