import { Nullable } from 'tsdef';
import { CreatedBy, GenderType, PaymentType, TextWithTranslations } from './general';

export type SubMetric = Partial<{
  title: string;
  subtitle: Nullable<string>;
  value: Nullable<number | string>;
  badge: Nullable<string>;
  tooltip: Nullable<string>;
}>;
export type MainProgress = Partial<{ text: string; value: number; color: string; tooltip: string }>;

export enum ClientHeaders {
  Name = 'name',
  Cancelled = 'cancelled',
  NoShow = 'noShow',
  Future = 'future',
  Rating = 'rating',
  Income = 'income',
  TotalBookings = 'totalBookings',
  Age = 'age',
  Gender = 'gender',
  PhoneNumber = 'phone_number',
  FirstVisit = 'firstVisit',
  LastVisit = 'lastVisit',
}

export type ReportHeader = {
  id: ClientHeaders;
  title: string;
  tooltip?: string;
  baseUrl?: string;
  target?: '_blank';
  sortable?: boolean;
};

export type ReportBody = Record<ClientHeaders, Nullable<string | number>> & { id: string };

export type Pagination = {
  currentPage: number;
  pageSize: number;
  recordsRange: string;
  totalPages: number;
  totalRecords: number;
};

export type Report = {
  headers: ReportHeader[];
  body: ReportBody[];
  mainMetric: {
    total: number;
    progresses: MainProgress[];
  };
  subMetrics: SubMetric[];
  pagination: Pagination;
};

type ClientGeneralInfo = {
  averageAge: string;
  popularPaymentMethod: Nullable<{
    count: number;
    totalAmount: number;
    method: PaymentType;
  }>;
  popularService: {
    id: string;
    count: number;
    title: TextWithTranslations;
  };
  popularSource: {
    count: number;
    source: CreatedBy;
  };
  totalClients: {
    new: number;
    regular: number;
    total: number;
  };
};

export type ClientTableResponse = {
  clientId: string;
  createdAt: string;
  name: string;
  surname: string;
  cancelled: number;
  noShow: number;
  rating: number;
  future: number;
  income: number;
  totalBookings: number;
  age: Nullable<string>;
  phone_number: Nullable<string>;
  firstVisit: Nullable<string>;
  lastVisit: Nullable<string>;
  gender: Nullable<Uppercase<GenderType>>;
};

export type ClientReportResponse = {
  generalInfo: ClientGeneralInfo;
  pagination: Pagination;
  tableInfo: ClientTableResponse[];
};
