import styled from 'styled-components';
import { Flex, mediaQueries, H4 } from '@beauty/beauty-market-ui';
import { Paper } from 'components';

export const Page = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1px;

  ${mediaQueries.md} {
    gap: 16px;
  }
`;

export const ReportHeader = styled(Paper)`
  flex-grow: 0;
  min-height: 88px;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
`;

export const ReportTitle = styled(H4)`
  [dir='ltr'] & {
    margin-right: auto;
  }
  [dir='rtl'] & {
    margin-left: auto;
  }
`;
