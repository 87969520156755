import { Caption, Flex, H2, H6 } from '@beauty/beauty-market-ui';
import { Circle } from 'components';
import { CircleInfo, MetricMainItem, Round } from './style';

export const MainMetric = ({ title, value, progresses }) => (
  <MetricMainItem>
    <H6>{title}</H6>
    <Circle strokeWidth={12} size={224} margin="26px auto 0" animated progresses={progresses}>
      <H2>{value}</H2>
    </Circle>
    <CircleInfo>
      {progresses.map(({ text, color }, index: number) => (
        <Flex key={`${text}-${index}`} alignItems="center" gap="8px" p="2px">
          <Round color={color} />
          <Caption lowline>{text}</Caption>
        </Flex>
      ))}
    </CircleInfo>
  </MetricMainItem>
);
