import { Nullable } from 'tsdef';
import { Currency } from '../constants';
import { HeadOrganizationServiceType, OrganisationType, TitleType } from './appointment';
import { CreateClientParams } from './client';
import { ImageType, PhotoType, SocialNetwork, Text, TextWithTranslations } from './general';
import { OrganisationServiceResponse, RelatedCategoryType } from './service';
import { Role, SpecialistScheduleType } from './specialist';
import { SubscriptionPlanType, SubscriptionPriceType, SubscriptionStatusType } from './subscription';
import { HeadOrganizationType } from './user';

type OrganisationName = string;

export type CodeNumber = {
  code: string;
  number: string;
};

export type PhoneType = CodeNumber & {
  id: string;
  contactId?: string;
};

type EmailType = {
  id: string;
  email: string;
};

type SocialNetworkType = {
  id: string;
  label: SocialNetwork;
  link: string;
  value: Nullable<string>;
};

type ContactType = {
  id: string;
  phone: PhoneType[];
  email: EmailType;
  socialNetwork?: SocialNetworkType[];
};

export const WeekDay = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
export const WeekDayHebrew = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export type TimeType = {
  start: string;
  end: string;
};

export type TimeslotType = {
  startNumber: number;
  endNumber: number;
};

export type WorkTimeType = TimeType & {
  breakStart?: string | null;
  breakEnd?: string | null;
};

export type DayType = WorkTimeType & {
  dayOfWeek: string;
};

export type DayOrgType = DayType & {
  id: string;
  orgId: string;
};

export type WorkDayType = DayType & {
  isWorkDay: boolean;
};

export type WorkTimeslot = {
  slotsNumber: number;
  startNumber: number | null;
  endNumber: number | null;
  breaks?: TimeslotType[];
  dayOfWeek?: string;
};

export type GeolocationType = {
  lng: Nullable<number>;
  lat: Nullable<number>;
  language?: string;
};

export type AddressBaseType = {
  id: string;
  addressId: string;
  country: string;
  city: string;
  street: string;
  building: string;
  postal: string | null;
  office: string | null;
  lat: number;
  lng: number;
  fullAddress: string | null;
  description: string | null;
  state?: string | null;
  district?: string | null;
};

export type AddressOrgType = {
  id: string;
  address: AddressBaseType[];
};

export type MainPhotoType = {
  id: string;
  url: string;
  organizationId: string;
} | null;

export type NamedAddressType = {
  id: string;
  name: OrganisationName;
  address: AddressBaseType;
  published: boolean;
  mainPhoto?: MainPhotoType;
};

export type PhotoStoreType = {
  mainPhoto: ImageType;
  photos: ImageType[];
  deletePhotos: PhotoType[];
  photoStore: PhotoType[];
};

export type AddressFormType = {
  id?: Nullable<string>;
  country: string;
  city: string;
  street: string;
  building: string;
  postal?: Nullable<string>;
  office?: Nullable<string>;
  description?: Nullable<string>;
  fullAddress?: Nullable<string>;
  lat?: Nullable<number>;
  lng?: Nullable<number>;
};

export type EditHeadOrganisationType = {
  registrationId?: Nullable<string>;
  photo?: Nullable<ImageType>;
  name: string;
  language: string;
  employeesNum: string;
  currency?: string;
  code?: string;
  number?: string;
  email?: string;
  category?: string[];
  account?: string;
  address?: AddressFormType;
};

export type OrganisationServiceType = {
  id: string;
  orgId: string;
  headOrgServId: string;
  price: number;
  headOrgService: HeadOrganizationServiceType;
  organization?: OrganisationType;
};

type ServiceBaseType = {
  id: string;
  listPrice: number;
  availableFor: string;
  duration: string;
  title: Text;
  category?: {
    id: string;
    title: TitleType;
  };
  deleted?: boolean;
};

export type ServiceFullType = ServiceBaseType & {
  categoryId: string;
  duration: number;
  headOrgId: string;
  descr: Text;
  descrTid: string;
  photo: PhotoType[];
};

export type ServiceShortType = ServiceBaseType & {
  langId: string;
  text: string;
  headOrgId: string;
  price: string | null;
};

export type OrganisationHeadType = HeadOrganizationType & {
  summary: null;
  name: Text;
  contact: ContactType;
  address: Nullable<AddressFormType>;
};

export type HeadOrganizationResponse = HeadOrganizationType & {
  summary: null;
  name: Text;
  contact: ContactType;
  address: AddressOrgType;
};

export type TodaysPriceType = {
  employee: number;
  telegram: number;
  whatsApp: number;
};

export type PlanType = {
  id: string;
  planType: SubscriptionPlanType;
  public: boolean;
  orgLimit: number;
  photoLimit: number;
  price: SubscriptionPriceType[];
  telegram: boolean;
  whatsApp: boolean;
};

export type OrganisationSubscriptionType = {
  id: string;
  planId: string;
  headOrgId: string;
  start: string;
  end: string;
  employeeCount: number;
  currency: Currency;
  period: string;
  cancellationNote: Nullable<string>;
  cancelled: boolean;
  invoiceId: Nullable<string>;
  status: SubscriptionStatusType;
  totalPriceForPeriod: number;
  daysLeft: number;
  telegram: boolean;
  plan: PlanType;
  whatsApp: boolean;
  todaysPrice: TodaysPriceType;
  renewed: boolean;
};

export type OrganisationStateType = {
  isLoading: boolean;
  initialized: boolean;
  head: OrganisationHeadType;
};

export type AddressSpecialistType = {
  headOrgSpecId: string;
  orgSpecId: string;
  name: string;
  surname: string;
  email: string;
  code: string;
  number: string;
  avatarUrl: string | null;
  specialization: string;
  published: boolean;
  role: Role;
  publishedCheck: {
    service: boolean;
    schedule: boolean;
  };
  orgSpecSchedule: SpecialistScheduleType[];
  scheduleStart: string;
  orgSpecService?: { orgService: { id: string } }[];
};

export type PropertyType = {
  id: string;
  iconUrl: string;
  title: TextWithTranslations;
};

export type AddressType = {
  id: string;
  name: string;
  email: string;
  description: string | null;
  address: AddressFormType;
  property: PropertyType[];
  phone: {
    id: string;
    code: string;
    number: string;
  };
  social: {
    instagram?: string | null;
    telegram?: string;
    whatsapp?: string;
  };
  photos: PhotoStoreType;
  isSave: boolean;
  schedule: WorkDayType[];
  allDayTemplate: WorkDayType[] | null;
  prevSchedule: WorkDayType[];
  published: boolean;
  orgSpecialist: AddressSpecialistType[];
  orgSpecialistService: OrganisationServiceType[];
  orgService: OrganisationServiceResponse[];
  categories: RelatedCategoryType[];
  rating?: Nullable<number>;
};

export type AddressPayloadType = {
  id: string;
  name: string;
  description: string | null;
  headOrgId: string;
  photo: PhotoType[];
  mainPhoto: PhotoType;
  address: { address: AddressOrgType };
  contact: ContactType;
  published: boolean;
  deleted: boolean;
  orgBusinessHours: DayOrgType[];
  orgSpecialist?: AddressSpecialistType[];
  orgService?: OrganisationServiceResponse[];
  category?: RelatedCategoryType[];
  property?: PropertyType[];
  rating?: Nullable<number>;
};

export type AddressStateType = {
  allAddress: NamedAddressType[];
  newAddress: AddressType;
  geolocation: GeolocationType;
  isLoading: boolean;
  initialValues: Partial<AddressType>;
  selectedOrgId?: Nullable<string>;
};

export type OrganizationPayloadType = {
  id: string;
  headOrgId: string;
  name: OrganisationName;
  address: AddressOrgType;
  mainPhoto?: MainPhotoType;
  published?: boolean;
  deleted?: boolean;
};

export type AddressComponentType = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GeocodingResponseType = {
  address_components: AddressComponentType[];
  formatted_address: string;
};

export type OrganisationClientsType = {
  code: string | null;
  name: string;
  number: string | null;
  headOrgClientId: string;
  id: string | null;
  surname: string;
  orgClientId?: string | string[];
  avatar?: string | null;
  verified?: boolean;
  language?: string;
  email?: string | null;
  idNumber?: string | null;
};

export type Client = {
  id: string;
  headOrgClientId: string;
  createdBy: string;
  clientId: string;
  accountId: string;
  verified: boolean;
  orgClientIds?: string[];
} & CreateClientParams;

export enum PublishErrorType {
  ServiceEmpty = 'SERVICE',
  ScheduleEmpty = 'SCHEDULE',
  SpecialistEmpty = 'SPECIALIST',
  UnpublishedSpecialist = 'PUBLISHED_SPECIALIST',
  All = 'ALL',
}

export type PublishLogType = {
  id: string;
  name: string;
  errors: PublishErrorType[];
};

export type PublishPayloadType = {
  success: boolean;
  publishedCheck: boolean;
  publishedLog: PublishLogType[];
};

export enum AddressFilterBy {
  Online = 'online',
  Offline = 'offline',
  All = 'all',
}

export enum AddressAction {
  AddAddress = 'ADD_ADDRESS',
  AddPhotos = 'ADD_PHOTOS',
  EditPhotos = 'EDIT_PHOTOS',
  EditDescription = 'EDIT_DESCRIPTION',
  EditMainPhoto = 'EDIT_MAIN_PHOTO',
  EditAddress = 'EDIT_ADDRESS',
  EditWorkingTime = 'EDIT_WORKING_TIME',
  EditEmployees = 'EDIT_EMPLOYEES',
  EditCurrentEmployee = 'EDIT_CURRENT_EMPLOYEE',
  DeleteEmployee = 'DELETE_EMPLOYEE',
  DeleteAddress = 'DELETE_ADDRESS',
  Publish = 'PUBLISH_ADDRESS',
  Unpublish = 'UNPUBLISH_ADDRESS',
  None = 'NONE',
}

export enum OrganizationAction {
  UpdateCard = 'UPDATE_PAYMENT_CARD',
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  PaySubscription = 'PAY_SUBSCRIPTION',
  ActivateSpecialist = 'ACTIVATE_HEAD_ORG_SPECIALIST',
  PayForTelegram = 'PAY_FOR_TELEGRAM',
  PayForWhatsApp = 'PAY_FOR_WHATSAPP',
  PayForSpecialist = 'PAY_FOR_HEAD_ORG_SPECIALIST',
  ActivateTelegram = 'ACTIVATE_HEAD_ORG_TELEGRAM',
  DeactivateTelegram = 'DEACTIVATE_HEAD_ORG_TELEGRAM',
  ActivateWhatsApp = 'ACTIVATE_HEAD_ORG_WHATSAPP',
  DeactivateWhatsApp = 'DEACTIVATE_HEAD_ORG_WHATSAPP',
  EditOrganisation = 'EDIT_HEAD_ORGANISATION',
  CreateOrganisation = 'CREATE_HEAD_ORGANISATION',
  CloseDay = 'CLOSE_DAY',
}
