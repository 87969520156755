import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMap from 'google-maps-react-markers';
import { Nullable } from 'tsdef';
import { BodySmall, colors } from '@beauty/beauty-market-ui';
import { GeolocationType, GoogleMapType, MapOptions } from 'types';
import { mapCenter } from '../../../constants';
import { getRightBottomButtons, getZoomControlButtons } from './helpers';
import MapPointLocation from './MapPointLocation';
import { CustomMapWrapper, GoogleMapWrapper } from './style';

const options: MapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  mapId: '4a89a47c8aa391b1',
  clickableIcons: false,
  gestureHandling: 'greedy',
};

const Component = ({ lat, lng }: Partial<GeolocationType>) => {
  const { t } = useTranslation();

  const [mapBounds, setMapBounds] = useState({});

  const mapRef = useRef<Nullable<GoogleMapType>>(null);
  const mapsControlsRef = useRef<any>(null);
  const mapZoom = 12;

  const onMapChange = ({ bounds, zoom }: { bounds: google.maps.LatLngBounds; zoom: number }) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom });
  };

  const onCenter = useCallback(() => {
    if (mapRef.current && lng && lat) {
      mapRef.current?.panTo({ lat, lng });
    }
  }, [lat, lng, mapRef.current]);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsControlsRef.current = maps;

    const rightBottomButtons = getRightBottomButtons(map, onCenter);
    const zoomButtons = getZoomControlButtons(map, mapZoom);

    mapRef?.current?.controls[mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM].push(rightBottomButtons);
    mapRef?.current?.controls[mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM].push(zoomButtons);
  };

  useEffect(() => {
    onCenter();
  }, [onCenter]);

  return (
    <CustomMapWrapper>
      <GoogleMapWrapper>
        <GoogleMap
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          defaultCenter={{ lat: lat || mapCenter.lat, lng: lng || mapCenter.lng }}
          defaultZoom={mapZoom}
          options={options}
          mapMinHeight="260px"
          onChange={onMapChange}
          onGoogleApiLoaded={onGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY,
            libraries: ['geometry'],
          }}
        >
          <MapPointLocation lat={lat ?? mapCenter.lat} lng={lng ?? mapCenter.lng} />
        </GoogleMap>
      </GoogleMapWrapper>
      <BodySmall color={colors.grey.dark}>{t('settings.addresses.sidebar.dragMap')}</BodySmall>
    </CustomMapWrapper>
  );
};

export const CustomMap = memo(Component);
