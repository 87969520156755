import { ChangeEvent, useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectClients } from '../../store/redux-slices/clientSlice';

export const useClientSearch = () => {
  const clients = useAppSelector(selectClients);

  const [query, setQuery] = useState('');
  const deferredQuery = useDeferredValue(query);

  const onReset = useCallback(() => {
    setQuery('');
  }, []);

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const filteredClients = useMemo(() => {
    const toSearchWithoutSpaces = deferredQuery.toLowerCase().trim().split(' ').join('');
    return clients.filter(({ name, surname, email, code, number, idNumber }) => {
      const combinedProperties = [name, surname, email, code, number, idNumber]
        .filter(Boolean)
        .join(' | ')
        .toLowerCase();
      return combinedProperties.includes(toSearchWithoutSpaces);
    });
  }, [deferredQuery, clients]);

  return {
    clients,
    filteredClients,
    onSearch,
    onReset,
    query: deferredQuery,
  };
};
