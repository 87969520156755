import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet, Flex, H6 } from '@beauty/beauty-market-ui';
import { SidebarFooter, DataCheckbox } from 'components';

export const FilterBottomSheet = ({ label, descriptor, open, onSubmit, onClose, defaultValue, options, isDesktop }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(defaultValue);

  const handleSubmit = () => {
    onSubmit(selected);
    onClose();
  };

  return (
    <BottomSheet
      isOpen={open}
      label={label}
      descriptor={descriptor}
      onClose={onClose}
      handleClose={onClose}
      content={
        <Flex flexDirection="column" gap="16px">
          <H6>{label}</H6>
          <Flex flexDirection="column" gap="8px">
            {options.map(item => (
              <DataCheckbox
                key={item.id}
                image={item.icon}
                title={item.title}
                subtitle={item.description}
                onChange={() => setSelected(item.id ?? null)}
                checked={selected === item.id}
                separator={false}
                isDesktop={isDesktop}
                p="0"
              />
            ))}
          </Flex>
        </Flex>
      }
      FooterBody={
        <SidebarFooter
          onBack={onClose}
          onSubmit={handleSubmit}
          confirmationLabel={t('button.apply')}
          cancelLabel={t('button.cancel')}
        />
      }
    />
  );
};
