import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  BodySmall,
  Button,
  Caption,
  colors,
  Flex,
  H5,
  Stepper,
  Textfield,
  RatingStars,
} from '@beauty/beauty-market-ui';
import ErrorImage from 'assets/fixing-bugs.png';
import SuccessImage from 'assets/trendy-people.png';
import { SidebarSheet } from 'components/SidebarSheet';
import { getFullName } from 'helpers';
import { useTimeout } from 'hooks';
import { ClientType } from 'types/appointment';
import { Stub } from '../../../Stub';
import { getErrorMessage } from '../helpers';

type ReviewClientProps = {
  client: ClientType;
  descriptor: string;
  onClose: () => void;
  onBack: () => void;
  onSubmit: ({ score, summary }: { score: number; summary: string }) => Promise<void>;
};

export const ReviewClient = ({ client, descriptor, onSubmit, onClose, onBack }: ReviewClientProps) => {
  const { t } = useTranslation();

  const [time, setTime] = useTimeout(-2, -1, 1000, onClose);
  const [step, setStep] = useState(1);
  const [score, setScore] = useState(0);
  const [summary, setSummary] = useState('');
  const [isValid, setValid] = useState(true);
  const [error, setError] = useState('');

  const stepTitles = [t('review.evaluateClient'), t('review.feedback')];
  const handleChangeScore = (value: number) => {
    setScore(value);
    setError('');
    setValid(value > 2 || (value < 3 && !!summary));
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const message = getErrorMessage(value, score < 3);
    setValid(!message);
    setError(t(message));
    setSummary(value);
  };

  const handleNext = () => {
    if (step === 2) {
      onSubmit({ score, summary: summary || ' ' })
        .catch(setError)
        .finally(() => setTime(10));
    }
    setStep(prev => prev + 1);
  };

  const handlePrev = () => {
    if (step === 1) {
      onBack();
    } else {
      setStep(prev => prev - 1);
    }
  };

  const renderFooterBody = () => {
    if (step === 1) {
      return (
        <Flex width="100%" flexDirection="column">
          <Button type="button" design="primary" onClick={handleNext} disabled={!score}>
            {t('button.continue')}
          </Button>
          <Button type="button" design="secondary" onClick={handlePrev}>
            {t('button.cancel')}
          </Button>
        </Flex>
      );
    }
    if (step === 2) {
      return (
        <Flex width="100%" flexDirection="column">
          <Button type="submit" design="primary" onClick={handleNext} disabled={!isValid || !!error}>
            {t('button.publishFeedback')}
          </Button>
          <Button type="button" design="secondary" onClick={handlePrev}>
            {t('button.back')}
          </Button>
        </Flex>
      );
    }
    return (
      <Button width="100%" type="button" onClick={onClose}>{`${t('button.close')} 00:${time
        .toString()
        .padStart(2, '0')}`}</Button>
    );
  };

  const content = (
    <>
      {step !== 3 && <Stepper title={stepTitles[step - 1]} stepsCount={2} currentStep={step} />}
      {step === 1 && (
        <Flex flexDirection="column" alignItems="center" width="304px" m="80px auto 76px">
          <Avatar name={getFullName(client)} size="xl" url={client?.avatarUrl} />
          <H5 mt="16px">{getFullName(client)}</H5>
          <BodySmall mb="64px" color={colors.grey.dark}>
            {t('review.client')}
          </BodySmall>
          <RatingStars rating={score} onClick={handleChangeScore} />
          <BodySmall color={colors.grey.dark}>{score ? t('review.yourRating') : t('review.evaluateClient')}</BodySmall>
        </Flex>
      )}
      {step === 2 && (
        <Flex flexDirection="column" mt="24px">
          <Textfield
            rows={6}
            value={summary}
            caption={error}
            nonValid={!!error}
            onBlur={handleChangeText}
            onChange={handleChangeText}
            placeholder={t('subscriptions.writeTheReason')}
          />
          <Caption lowline color={!error ? colors.grey.dark : colors.red.standard} ml="auto">
            {`${summary.length}/1000`}
          </Caption>
        </Flex>
      )}
      {step === 3 && (
        <Stub
          margin="80px 44px"
          src={error ? ErrorImage : SuccessImage}
          alt={error ? t('review.oops') : t('review.youAreAmazing')}
          title={error ? t('review.oops') : t('review.youAreAmazing')}
          subtitle={error ? t('review.errorSubmit') : t('review.successSubmit')}
        />
      )}
    </>
  );

  const sidebarProps = {
    isOpen: true,
    onClose,
    label: t('review.rateAppointment'),
    descriptor,
    FooterBody: renderFooterBody(),
  };

  return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
};
