import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { useAppSelector } from 'store/hooks';
import { selectSpecialistInfo } from 'store/redux-slices/specialistSlice';
import { Language, Level, OptionType, SpecialistLanguage, SpokenLanguage } from 'types';
import { getSpokenLanguages, getSpokenLevels } from '../../../../../constants';
import { LanguageField } from './LanguageField';

type EditLanguagesProps = {
  onClose: () => void;
  onSubmit: (languages: SpokenLanguage) => Promise<void>;
  defaultValue?: SpecialistLanguage | null;
};

export const EditLanguages = ({ defaultValue, onSubmit, onClose }: EditLanguagesProps) => {
  const { t } = useTranslation();
  const { spokenLanguage } = useAppSelector(selectSpecialistInfo);

  const [selected, setSelected] = useState<string[]>(() => spokenLanguage.map(item => item.language));
  const [language, setLanguage] = useState<Language | null>(defaultValue?.language ?? null);
  const [level, setLevel] = useState<Level | null>(defaultValue?.level ?? null);
  const [isSubmitting, setSubmitting] = useState(false);

  const languageMap = getSpokenLanguages(t, selected);
  const levelsMap = getSpokenLevels(t);

  const handleChangeLanguage = (value: OptionType) => {
    const updatedSelected = selected.map(item => (item === language ? value.id : item));
    setLanguage(value.id as Language);
    setSelected(updatedSelected);
  };

  const handleChangeLevel = (value: OptionType) => {
    setLevel(value.id as Level);
  };

  const handleSubmit = async () => {
    if (language && level) {
      try {
        setSubmitting(true);
        await onSubmit({ language, level });
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <SidebarSheet
      onClose={onClose}
      onBack={onClose}
      label={defaultValue ? t('specialists.personalInfo.editLanguage') : t('specialists.personalInfo.addLanguage')}
      descriptor={t('specialists.personalInfo.updateInformation')}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onClose}
          confirmationLabel={t('button.save')}
          cancelLabel={t('button.cancel')}
          disabled={!language || !level || (defaultValue?.language === language && defaultValue?.level === level)}
          isLoading={isSubmitting}
        />
      }
    >
      <Flex flexDirection="column" gap="16px" mb="135px">
        <LanguageField
          languages={Object.values(languageMap)}
          levels={Object.values(levelsMap)}
          language={language ? languageMap[language] : null}
          level={level ? levelsMap[level] : null}
          onSelectLanguage={handleChangeLanguage}
          onSelectLevel={handleChangeLevel}
        />
      </Flex>
    </SidebarSheet>
  );
};
