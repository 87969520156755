import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { Flex, Button, AppointmentStatus } from '@beauty/beauty-market-ui';
import { SidebarSheet } from 'components/SidebarSheet';
import { Company, Customer } from 'types/calendar';
import { Order, Payment, PaymentMethod } from 'types/general';
import { DetailsSection } from './DetailsSection';

type AppointmentDetailsSidebarProps = {
  date: string;
  time: string;
  company: Company;
  customer: Customer;
  customerId: string;
  paymentMethod?: PaymentMethod;
  order?: Order;
  payment?: Payment;
  handleClose: () => void;
  isReviewed: boolean;
  status: AppointmentStatus;
  onOpenReview: (clientId: string) => void;
};

export const AppointmentDetailsSidebar = ({
  date,
  time,
  company,
  customer,
  customerId,
  paymentMethod,
  order,
  payment,
  handleClose,
  isReviewed,
  status,
  onOpenReview,
}: AppointmentDetailsSidebarProps) => {
  const { t } = useTranslation();

  const prefix = 'calendar.newAppointmentSidebar.details';
  const detailsSections = {
    dateAndTime: {
      date,
      time,
    },
    company,
    customer: omit(customer, ['avatarUrl', 'id']),
    paymentMethod,
    order,
    payment,
  };

  if (status === AppointmentStatus.NOSHOW) {
    delete detailsSections.paymentMethod;
    delete detailsSections.order;
    delete detailsSections.payment;
  }

  const footerBody = (
    <Flex flexDirection="column" width="100%" gap="16px">
      {customer.id && status !== AppointmentStatus.NOSHOW && (
        <Button
          design="primary"
          width="100%"
          size="large"
          type="submit"
          onClick={() => onOpenReview(customerId)}
          disabled={isReviewed}
        >
          {t('button.reviewClient')}
        </Button>
      )}
      <Button design="secondary" width="100%" size="large" onClick={handleClose}>
        {t('button.close')}
      </Button>
    </Flex>
  );

  const sidebarProps = {
    isOpen: true,
    onClose: handleClose,
    FooterBody: footerBody,
    label: t('calendar.newAppointmentSidebar.infoAppointment'),
  };

  const content = Object.entries(detailsSections).map(section => (
    <DetailsSection
      key={section[0]}
      title={t(`${prefix}.${section[0]}`)}
      body={
        section[0] !== 'order'
          ? Object.entries(section[1] || {}).map(bodyItem => ({
              label: t(`${prefix}.${bodyItem[0]}`),
              value: bodyItem[1],
            }))
          : (section[1] as Order).map(orderInfo => ({
              label: Object.values(orderInfo)[0],
              value: Object.values(orderInfo)[1],
            }))
      }
      isRow={section[0] === 'order' || section[0] === 'payment'}
      isPayment={section[0] === 'payment'}
    />
  ));

  return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
};
