import { Flex, H3, H6, H7 } from '@beauty/beauty-market-ui';
import { Tooltip } from 'components';
import { BadgeItem, MetricSubItem, SubMetricContent } from './style';

export const SubMetric = ({ title, subtitle, value, badge, order, tooltip }) => (
  <MetricSubItem order={order}>
    <H6>{title}</H6>
    <Flex alignItems="center" justifyContent="space-between" gap="8px">
      <Tooltip id={title} content={tooltip} offset={0} show={!!tooltip} place="top-start">
        <SubMetricContent data-tooltip-id={`${title}`}>
          <H3 truncated>{value}</H3>
          <H7>{subtitle}</H7>
        </SubMetricContent>
      </Tooltip>
      {badge && (
        <BadgeItem lowline large>
          {badge}
        </BadgeItem>
      )}
    </Flex>
  </MetricSubItem>
);
