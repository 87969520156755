import { ReactNode } from 'react';
import { Tooltip } from '../Tooltip';
import { BackgroundCircle, CircleContainer, CircleContent, CircleSvg, ProgressCircle } from './style';

type IProgress = {
  color: string;
  value: number;
  text?: string;
  tooltip?: string;
};

type CircleProps = {
  progresses?: IProgress[];
  strokeWidth: number;
  defaultColor?: string;
  size: number;
  children?: ReactNode;
  margin?: string;
  animated?: boolean;
};

export const Circle = ({ progresses, children, defaultColor, size, strokeWidth, margin, animated }: CircleProps) => {
  const center = size / 2;
  const radius = center - strokeWidth;

  return (
    <CircleContainer size={size} m={margin}>
      <CircleSvg>
        <BackgroundCircle defaultColor={defaultColor} strokeWidth={strokeWidth} cx={center} cy={center} r={radius} />
        {progresses?.map(({ text, value, color, tooltip }, index) => {
          const uniqueId = `${text}-${index}`;
          return (
            <Tooltip key={uniqueId} id={uniqueId} show={!!tooltip} content={tooltip ?? ''} offset={20}>
              <ProgressCircle
                progress={value}
                color={color}
                strokeWidth={strokeWidth}
                cx={center}
                cy={center}
                r={radius}
                animated={animated}
                data-tooltip-id={text}
              />
            </Tooltip>
          );
        })}
      </CircleSvg>
      <CircleContent>{children}</CircleContent>
    </CircleContainer>
  );
};
