import styled, { css } from 'styled-components';
import {
  Flex,
  Div,
  colors,
  BodySmallBasicStyles,
  CaptionBasicStyles,
  Button,
  H7,
  mediaQueries,
} from '@beauty/beauty-market-ui';
import { zIndex } from '../../../constants';

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
`;

export const TimeWrapper = styled(Flex)`
  position: relative;
  gap: 16px;
  flex-direction: column;

  ${mediaQueries.md} {
    gap: 8px;
    flex-direction: row;
  }
`;

export const CustomCalendarHeader = styled(H7)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  [dir='rtl'] & {
    svg {
      transform: scaleX(-1);
    }
  }
`;

export const StyledNavButton = styled(Button)`
  padding: 0px !important;
`;

export const DatePickerWrapper = styled(Div)`
  position: absolute;
  top: 60px;
  left: 1px;
  z-index: ${zIndex.dropdown};
  box-shadow:
    0px 0px 0px 1px #0a090b0d,
    0px 2px 2px -1px #0a090b0a,
    0px 2px 12px -1px #0a090b1a;
  border-radius: 8px;

  .react-datepicker {
    border: none;
    border-radius: 8px;
  }

  .react-datepicker__month-container {
    padding: 16px;
    padding-top: 18px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .react-datepicker__month {
    justify-content: center;
    margin: 16px 0 0 0;
    gap: 8px;
  }

  .react-datepicker__header {
    padding: 0;
    background: ${colors.white.standard};
    border-bottom: none;
  }

  .react-datepicker__day-names {
    margin-top: 8px;

    ${({ todayWeekday }) => css`
      .react-datepicker__day-name:nth-child(${todayWeekday}) {
        border-bottom: 3px solid ${colors.blue.standard};
      }
    `}
  }

  .react-datepicker__day-name {
    ${CaptionBasicStyles(false, true)}
    color: ${colors.grey.dark};
    width: 56px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid ${colors.grey.light};
    margin: 2px;
  }

  .react-datepicker__day {
    ${BodySmallBasicStyles(false, false)}
    width: 56px;
    height: 32px;
    line-height: 32px;
    margin: 2px;

    &:hover {
      border-radius: 16px;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    ${BodySmallBasicStyles(false, false)}
    background: ${colors.blue.standard};
    color: ${colors.white.standard};
    border-radius: 16px;
    line-height: 32px;
    margin: 2px;

    &:hover {
      background: ${colors.blue.standard};
      border-radius: 16px;
    }
  }

  .react-datepicker__today-button {
    background: ${colors.white.standard};
    border-top: none;
    padding: 0 16px 18px 16px;
    border-radius: 8px;
  }

  .react-datepicker__day--disabled {
    color: ${colors.grey.standard};
  }
`;

export const ToggleItemWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${colors.grey.standard};

  label {
    min-width: 40px;
  }
`;

export const SelectorWrapper = styled(Flex)`
  position: absolute;
  z-index: ${zIndex.dropdown};
  top: calc(100% + 4px);

  [dir='ltr'] & {
    right: -1px;
  }

  [dir='rtl'] & {
    left: -1px;
  }
`;

export const ButtonsWrapper = styled(Flex)`
  width: 100%;
  justifycontent: space-between;
  flex-direction: column;
  gap: 16px;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 8px;
  }
`;

export const SpecialistFormWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  & > div p:last-child {
    direction: ltr;
  }
`;
