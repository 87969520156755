import { useEffect, useState } from 'react';
import { Nullable } from 'tsdef';
import { getQueryParams } from 'helpers';
import { apiRequest } from '../../api/api';
import { ReportsEndpoints } from '../../api/endpoints/endpoints';
import { ClientReportResponse, SortOrder } from '../../types';

type Props = {
  id: string;
  start: string;
  end: string;
  page: number;
  pageSize: number;
  sortOrder: SortOrder;
  sortBy: Nullable<string>;
  orgId?: Nullable<string>;
};

export const useGetClientsReports = (props: Props) => {
  const { id, start, end, orgId, sortBy, sortOrder, pageSize, page } = props;

  const [data, setData] = useState<Nullable<ClientReportResponse>>(null);
  const [isLoading, setLoading] = useState(false);
  const [isInitial, setInitial] = useState(false);
  const [error, setError] = useState<unknown>('');

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      const queryParams = getQueryParams({
        start,
        end,
        orgId,
        sortBy,
        sortOrder,
        pageSize,
        page,
      });

      const [err, res] = await apiRequest.get<ClientReportResponse>({
        endpoint: ReportsEndpoints.Clients,
        endpointParams: { id, queryParams },
      });

      if (err) {
        setError(err);
        setData(null);
      } else if (res) {
        setError('');
        setData(res.data);
      }
    };
    setLoading(true);
    fetchData().finally(() => {
      setLoading(false)
      setInitial(true);
    });

    return () => {
      controller.abort();
    };
  }, [id, start, end, orgId, sortBy, sortOrder, pageSize, page]);

  return {
    data,
    isLoading,
    isInitial,
    error,
  };
};
