import { memo } from 'react';
import { BodySmall, Flex, HelpCircleIcon, CaretUpIcon, CaretDownIcon } from '@beauty/beauty-market-ui';
import { Tooltip } from 'components';
import { Arrow, HeaderCellWrapper } from './style';

const THeaderCell = ({ id, tooltip, title, sortBy, sortOrder, onHeaderCellClick, sortable }) => (
  <HeaderCellWrapper onClick={() => sortable && onHeaderCellClick(id)} cursor={sortable ? 'pointer' : 'auto'}>
    <Flex alignItems="center" gap="4px">
      {tooltip && (
        <Tooltip id={`tooltip-${id}`} content={tooltip}>
          <HelpCircleIcon data-tooltip-id={`tooltip-${id}`} />
        </Tooltip>
      )}
      <BodySmall large>{title}</BodySmall>
      <Arrow isShow={sortable && sortBy === id}>{sortOrder === 'asc' ? <CaretUpIcon /> : <CaretDownIcon />}</Arrow>
    </Flex>
  </HeaderCellWrapper>
);

export const HeaderCell = memo(THeaderCell);
