import { ReactNode } from 'react';
import {
  Avatar,
  BodyLarge,
  BodySmall,
  Caption,
  Checkbox,
  colors,
  Div,
  Flex,
  Separator,
} from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { EmployeeItemContent } from '../../style';

type EmployeeItemProps = {
  title: string;
  subtitle: string;
  image: ReactNode;
  separator: boolean;
  checked: boolean;
  onChange: () => void;
  mt?: string;
};

export const EmployeeItem = ({ title, subtitle, image, separator, checked, onChange, mt }: EmployeeItemProps) => (
  <>
    <Flex alignItems="center" mt={mt} width="100%" gap="8px">
      <Div flexShrink={0}>
        {typeof image === 'string' ? <Avatar size="s" rating="" url={image} anonymos={!image} /> : image}
      </Div>
      <EmployeeItemContent>
        <Adaptive mobile={BodyLarge} desktop={BodySmall}>
          {title}
        </Adaptive>
        <Adaptive mobile={Caption} desktop={BodySmall} color={colors.grey.dark} truncated>
          {subtitle}
        </Adaptive>
      </EmployeeItemContent>
      <Div flexShrink={0} ml="auto">
        <Checkbox checked={checked} onClick={onChange} shape="round" />
      </Div>
    </Flex>
    {separator && <Separator my="14px" />}
  </>
);
