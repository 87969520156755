import isEmpty from 'lodash/isEmpty';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { useAddressOptions } from 'hooks';

export const FilterByAddress = ({ placeholder, stub = false }) => {
  const { options, onSelect, selected } = useAddressOptions(stub);

  if (isEmpty(options)) return null;

  return <FilterDropdown options={options} selectedOptionId={selected} onSelect={onSelect} placeholder={placeholder} />;
};
