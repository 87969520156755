import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isArray, isEqual } from 'lodash';
import moment from 'moment';
import {
  H6,
  SearchIcon,
  ServiceCard,
  PaymentContainer,
  AppointmentStatus,
  Button,
  MessageIcon,
  useClickOutside,
  Flex,
  Sidebar,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { SidebarFooter, Loader, FormikDropdown, FormikInput, FormikTextfield } from 'components';
import { RatingSidebar } from 'components/RatingSidebar/RatingSidebar';
import { checkAccess, isRtl } from 'helpers';
import { appointmentsAPI } from 'helpers/appointmentsAPI';
import { useMediaScreen, useRequest } from 'hooks';
import { useTimeList } from 'hooks/useTimeList';
import { useAppSelector } from 'store/hooks';
import { selectAddressState } from 'store/redux-slices/addressSlice';
import { selectAppointments, selectOrgServices, ThunkAppointments } from 'store/redux-slices/appointmentsSlice';
import { selectHead } from 'store/redux-slices/organisationSlice';
import { AppointmentByIdResponse, AppointmentsAction, ClientOption, WorkDayType } from 'types';
import {
  AppointmentPrefilledData,
  CloseAppointmentParams,
  GoogleEventType,
  SpecialistSummaryType,
} from 'types/appointment';
import { Company } from 'types/calendar';
import { PaymentType } from 'types/general';
import { AccessRange, currencySymbol, EventStatus, HHmm } from '../../../../constants';
import { getDeletedClientTemplate } from '../../../../page/Calendar/helpers';
import { selectClients } from '../../../../store/redux-slices/clientSlice';
import Popup from '../../../PopUp';
import { AppointmentDetailsSidebar } from '../AppointmentDetailsSidebar/AppointmentDetailsSidebar';
import {
  convertAllClientsToOptions,
  convertAllServicesToOptions,
  convertAllSpecialistsToOptions,
  filterServicesBySpecialist,
  filterSpecialistsByService,
  getActualPrice,
  getPreselectedValues,
  setNotesFromGoogle,
  statusList,
  statusOptions,
} from '../helpers';
import { NewClientSidebar } from '../NewClientSidebar/NewClientSidebar';
import { FormWrapper } from '../style';
import { getInitialValues, AppointmentForm, AppointmentFormFields, schema } from './AppointmentSidebar.definitions';
import { NegotiatedPriceInput } from './components/NegotiatedPriceInput';
import { PaymentMethodRadio } from './components/PaymentMethodRadio';
import { ClientForm } from './Forms/ClientForm';
import { ServiceForm } from './Forms/ServiceForm';

import 'react-datepicker/dist/react-datepicker.css';

interface AppointmentSidebarProps {
  isEditMode: boolean;
  selectedWeekday: string;
  selectedAppointmentData: AppointmentByIdResponse | GoogleEventType | null;
  newAppointmentData: AppointmentPrefilledData | null;
  organisationSpecialists: SpecialistSummaryType[] | null;
  organisationWorkTime: WorkDayType[] | null;
  company: Company | null;
  handleClose: () => void;
  onAddButtonClick: () => void;
  isAddFromMenu: boolean;
  dateFromMenu: string | null;
  isAppointmentDetails: boolean;
  setAppointmentDetails: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const AppointmentSidebar = ({
  isEditMode,
  selectedWeekday,
  selectedAppointmentData,
  newAppointmentData,
  organisationSpecialists,
  organisationWorkTime,
  company,
  handleClose,
  onAddButtonClick,
  isAddFromMenu,
  dateFromMenu,
  isAppointmentDetails,
  setAppointmentDetails,
  isVisible,
  setVisible,
}: AppointmentSidebarProps) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMediaScreen('md');
  const timeList = useTimeList();
  const clients = useAppSelector(selectClients);
  const { currency } = useAppSelector(selectHead);
  const { allAddress } = useAppSelector(selectAddressState);
  const { selectedAddress } = useAppSelector(selectAppointments);
  const { data: orgServicesMap, ids: orgServiceIds } = useAppSelector(selectOrgServices);

  const extendedOrganisationClients =
    isArray(clients) && isEditMode ? [...clients, getDeletedClientTemplate(t)] : clients;

  // TODO: Replace with commented code to make available booking time align with organisation working hours
  // const timeList = getTimeDropdownOptions(appointmentDate.item, organisationWorkTime);
  // const endTimeOptions = startTime > -1 ? timeList.slice(startTime) : timeList;

  const {
    preselectedStart,
    preselectedEnd,
    preselectedDate,
    preSelectedClient,
    preSelectedService,
    preSelectedSpecialist,
  } = getPreselectedValues(
    selectedWeekday,
    selectedAppointmentData,
    newAppointmentData,
    extendedOrganisationClients,
    organisationSpecialists,
    organisationWorkTime,
    timeList,
    dateFromMenu,
  );

  const addressOptions = allAddress?.map(address => ({
    id: address.id,
    icon: address.mainPhoto?.url,
    title: address.name,
    description: address.address.fullAddress,
  }));

  const isUserAdmin = checkAccess(AccessRange.ADMIN);

  const { status = undefined, isGoogle = undefined } = selectedAppointmentData ?? {};

  const options = statusOptions(status, t);

  const notesRef = useRef<Element | null>(null);

  const isPastMode = status === EventStatus.PAST;
  const isUnclosed = status === EventStatus.UNCLOSED;
  const isInfoMode = isPastMode || status === EventStatus.COMPLETED;

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isService, setIsService] = useState(isEditMode && !isGoogle);
  const [isEmptyDate, setEmptyDate] = useState(false);
  const [isTextfield, setTextfield] = useState(false);

  const [startTime, setStartTime] = useState(preselectedStart);
  const [endTime, setEndTime] = useState(preselectedEnd);

  const [selectedClient, setSelectedClient] = useState(
    (isMobile && ((preSelectedClient === -1 && -1) || preSelectedClient + 1)) || preSelectedClient,
  );
  const [selectedStatusIndex, setSelectedStatusIndex] = useState<number>(-1);

  const [isReview, setIsReview] = useState(false);
  const [isEditService, setEditService] = useState(false);

  const [isCash, setIsCash] = useState(true);

  const [isEditClient, setEditClient] = useState(!isEditMode && selectedClient <= 0);
  const [isClientFormOpen, setClientFormOpen] = useState(false);
  const [isNewClient, setNewClient] = useState(false);

  const [updatedPrice, setUpdatedPrice] = useState('');

  const cancelAppointment = useRequest(ThunkAppointments.updateAppointment, AppointmentsAction.Cancel);
  const updateAppointment = useRequest(ThunkAppointments.updateAppointment, AppointmentsAction.Update);
  const createAppointment = useRequest(ThunkAppointments.createAppointment, AppointmentsAction.Create);
  const convertGoogle = useRequest(ThunkAppointments.convertGoogle, AppointmentsAction.Convert);
  const closeAppointment = useRequest(ThunkAppointments.closeAppointment, AppointmentsAction.Close);

  const clientsOptionsList: ClientOption[] = convertAllClientsToOptions(
    extendedOrganisationClients,
    isUserAdmin,
    isMobile,
    t,
  );

  const currencySign = currencySymbol[currency];
  const showNegotiatedPrice =
    status === EventStatus.UNCLOSED && (selectedAppointmentData as AppointmentByIdResponse)?.price === null;

  const isShowAddNotesButton = useMemo(
    () => !isTextfield && isService && !isInfoMode && !isGoogle && !isUnclosed,
    [isTextfield, isService, isInfoMode, isGoogle, isUnclosed],
  );

  const clientInfo = useMemo(() => {
    const client = clientsOptionsList[selectedClient];
    return {
      clientName: client?.name || '',
      idNumber: client?.idNumber || '',
      avatarUrl: client?.avatarUrl ?? '',
      id: (selectedAppointmentData as AppointmentByIdResponse)?.client?.id,
    };
  }, [selectedClient]);

  const handleAppointmentCancel = async () => {
    if (selectedAppointmentData) {
      setSubmitting(true);
      const params = {
        id: selectedAppointmentData.id,
        params: { status: EventStatus.CANCELLED },
      };
      cancelAppointment(params).finally(() => {
        setIsConfirmationModalVisible(false);
        setSubmitting(false);
        handleClose();
      });
    }
  };

  const handleClientFormClose = () => {
    setEditClient(false);
    setClientFormOpen(false);
  };
  const handleConfirmationModalClose = () => setIsConfirmationModalVisible(false);

  const onFormSubmit = useCallback(
    async (data: AppointmentForm) => {
      const { status: payloadStatus, client, service, specialist, start, end, date, notes, price } = data;
      const selectedAppointmentDate = moment(date, 'DD.MM.yyyy').format('YYYY-MM-DD');
      const startTimeUTC = moment(`${selectedAppointmentDate} ${start}`).utc();
      const endTimeUTC = moment(`${selectedAppointmentDate} ${end}`).utc();

      if (payloadStatus === 6) {
        setIsConfirmationModalVisible(true);
      } else {
        setSubmitting(true);
        if (isUnclosed && payloadStatus < 5) {
          const params: CloseAppointmentParams = {
            id: selectedAppointmentData!.id,
            paymentMethod: isCash ? PaymentType.CASH : PaymentType.CARD,
          };
          if (price) params.price = Number(price.substring(currencySign.length));

          closeAppointment(params)
            .then(() => appointmentsAPI.fetchAppointmentById((selectedAppointmentData as AppointmentByIdResponse).id))
            .then(response => {
              setUpdatedPrice(response.data.price);
              setAppointmentDetails(true);
            })
            .finally(() => setSubmitting(false));
        } else if (isGoogle) {
          const params = {
            eventId: selectedAppointmentData?.id,
            orgSpecId: specialist,
            orgServId: service,
            clientId: client,
            notes,
          };
          convertGoogle(params).finally(() => {
            setSubmitting(false);
            handleClose();
          });
        } else if (isEditMode) {
          const params = {
            orgSpecId: specialist,
            orgServId: service,
            start: startTimeUTC,
            end: endTimeUTC,
            notes,
            status:
              statusList[payloadStatus].status !== AppointmentStatus.COMPLETED
                ? statusList[payloadStatus].status
                : AppointmentStatus.PAST,
          };
          updateAppointment({ id: selectedAppointmentData?.id, params }).finally(() => {
            setSubmitting(false);
            handleClose();
          });
        } else {
          const params = {
            orgSpecId: specialist,
            orgServId: service,
            clientId: client,
            start: moment(startTimeUTC).format('YYYY-MM-DD HH:mm'),
            end: moment(endTimeUTC).format('YYYY-MM-DD HH:mm'),
            notes,
          };
          createAppointment(params).finally(() => {
            setSubmitting(false);
            handleClose();
          });
        }
      }
    },
    [isEditMode, isInfoMode, selectedAppointmentData, isGoogle, isUnclosed, isCash],
  );

  const formikContextValue = {
    initialValues: getInitialValues({
      date: preselectedDate,
      clientId: clientsOptionsList[isMobile ? selectedClient : preSelectedClient]?.id || '',
      serviceId: preSelectedService || '',
      specialistId: preSelectedSpecialist || '',
      startTime: timeList[preselectedStart]?.item,
      endTime: timeList[preselectedEnd]?.item,
      statusIndex: statusList.findIndex(statusItem =>
        status !== EventStatus.PAST && status !== EventStatus.UNCLOSED
          ? statusItem.status === status
          : statusItem.status === EventStatus.COMPLETED,
      ),
      notes: isGoogle
        ? setNotesFromGoogle(selectedAppointmentData)
        : (selectedAppointmentData as AppointmentByIdResponse)?.notes || '',
      price: `${currencySign}${showNegotiatedPrice ? '' : '0'}`,
    }),
    validationSchema: schema(t, currencySign, selectedStatusIndex === 3),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  const descriptorText =
    (isInfoMode && t('calendar.newAppointmentSidebar.infoDetails')) ||
    (isEditMode && t('calendar.newAppointmentSidebar.mainInformation')) ||
    t('calendar.newAppointmentSidebar.descriptor');

  const handleCloseAppointmentDetails = () => {
    setAppointmentDetails(false);
    setVisible(false);
    handleClose();
  };

  useEffect(
    () =>
      setSelectedStatusIndex(
        statusList.findIndex(item =>
          status !== EventStatus.PAST && status !== EventStatus.UNCLOSED
            ? item.status === status
            : item.status === EventStatus.COMPLETED,
        ),
      ),
    [status],
  );

  return (
    <>
      <Formik {...formikContextValue}>
        {({ isValid, handleSubmit, values, setFieldValue, initialValues, getFieldMeta, errors }) => {
          const { Address, Client, Service, Specialist, Start, End, AppointmentDate, Status, Notes, Price } =
            AppointmentFormFields;

          const rtl = isRtl();
          const [notes, setNotes] = useState('');

          const isFieldsFilled =
            !!values[Client] &&
            !!values[Service] &&
            isService &&
            !!values[Specialist] &&
            !!values[Start] &&
            !!values[End] &&
            !!values[AppointmentDate];

          const specialistOptionsList = convertAllSpecialistsToOptions(organisationSpecialists);
          const serviceOptionsList = convertAllServicesToOptions(orgServicesMap, orgServiceIds, currency, t);

          const filteredSpecialists = filterSpecialistsByService(specialistOptionsList, values[Service]);
          const filteredServices = filterServicesBySpecialist(
            serviceOptionsList,
            values[Specialist],
            organisationSpecialists,
          );

          const currentService = serviceOptionsList.find(service => service.id === values[Service]);
          const currentSpecialist = specialistOptionsList.find(item => item.id === values[Specialist]);

          const price = (selectedAppointmentData as AppointmentByIdResponse)?.price || currentService?.price;
          const actualPrice = getActualPrice(currencySign, updatedPrice, price);
          const timeInfo = rtl ? `${values[End]}-${values[Start]}` : `${values[Start]}-${values[End]}`;

          const held =
            values[Status] > -1 &&
            options[values[Status]].status !== EventStatus.NOSHOW &&
            options[values[Status]].status !== EventStatus.CANCELLED;

          const showPaymentInfo = values[Service] && isService && held;
          const showPaymentMethod = isUnclosed && held;

          const labelText =
            (isInfoMode &&
              `${isEmptyDate ? '' : values[AppointmentDate]} ${t('calendar.newAppointmentSidebar.infoAppointment')}`) ||
            (isEditMode &&
              `${t('calendar.newAppointmentSidebar.appointment')} ${isEmptyDate ? '' : values[AppointmentDate]}`) ||
            `${t('calendar.newAppointmentSidebar.newAppointment')} ${isEmptyDate ? '' : values[AppointmentDate]}`;

          const buttonText = isUnclosed ? t('button.finishAppointment') : t('button.openDetails');
          const extraAction = isEditMode && !isInfoMode && !isGoogle && values[Status] !== 5;

          const footerBody =
            !isInfoMode && !isUnclosed ? (
              <SidebarFooter
                disabled={
                  (!isFieldsFilled ||
                    (!isValid && !showNegotiatedPrice && !!errors[AppointmentFormFields.Price]) ||
                    (isEditMode && isEqual(values, initialValues))) &&
                  !isInfoMode
                }
                onSubmit={handleSubmit}
                onBack={handleClose}
                handleExtraAction={extraAction ? () => setIsConfirmationModalVisible(true) : null}
                extraActionLabel={extraAction ? t('calendar.newAppointmentSidebar.cancelAppointment') : ''}
                save
                cancel
                isLoading={isSubmitting}
              />
            ) : (
              <Flex flexDirection="column" width="100%" gap="16px">
                <Button
                  type={isUnclosed ? 'submit' : 'button'}
                  onClick={isUnclosed ? () => onFormSubmit(values) : () => setAppointmentDetails(true)}
                  disabled={isSubmitting || errors[Price]}
                >
                  {isSubmitting ? <Loader type="spinner" /> : buttonText}
                </Button>
                <Button type="button" design="secondary" onClick={handleClose} disabled={isSubmitting}>
                  {t('button.close')}
                </Button>
              </Flex>
            );

          const handleServiceFormClose = () => {
            setEditService(false);
            values[Service] && setIsService(true);
          };

          useEffect(() => {
            setNotes(values[Notes]);
            values[Notes] && setTextfield(true);
          }, [values[Notes]]);

          useClickOutside(notesRef, () => !notes && setTextfield(false));

          const sidebarProps = {
            isOpen: true,
            onClose: handleClose,
            handleClose,
            FooterBody: footerBody,
            label: labelText,
            descriptor: descriptorText,
          };

          useEffect(() => {
            values[Status] > 4 && setFieldValue(Price, `${currencySign}0`);
          }, [values[Status]]);

          const clientForm = (
            <ClientForm
              clients={clientsOptionsList}
              selectedClient={selectedClient}
              onSelect={(index: number) => {
                if (clientsOptionsList[index].id) {
                  setFieldValue(Client, clientsOptionsList[index].id);
                  setSelectedClient(index);
                  setEditClient(false);
                  setClientFormOpen(false);
                } else setNewClient(true);
              }}
              onDelete={() => {
                setFieldValue(Client, '');
                setEditClient(true);
                setClientFormOpen(true);
                setSelectedClient(-1);
              }}
              onAddNewClient={() => setNewClient(true)}
            />
          );

          const serviceForm = (
            <ServiceForm
              serviceOptionsList={filteredServices}
              specialistOptionsList={filteredSpecialists}
              infoMode={isInfoMode || isUnclosed}
              timeList={timeList}
              organisationWorkTime={organisationWorkTime}
              selectedWeekday={selectedWeekday}
              selectedAppointmentData={selectedAppointmentData}
              extendedOrganisationClients={extendedOrganisationClients}
              organisationSpecialists={organisationSpecialists}
              setIsService={setIsService}
              isEmptyDate={isEmptyDate && !isAddFromMenu}
              setEmptyDate={setEmptyDate}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              setTextfield={setTextfield}
              isEditService={isEditService}
              setEditService={setEditService}
              dateFromMenu={dateFromMenu}
              isValid={!getFieldMeta(Start).error && !getFieldMeta(End).error}
            />
          );

          const sidebarForm = (
            <Form>
              <FormWrapper>
                {isEditMode && !selectedAppointmentData?.isGoogle && (
                  <FormikDropdown
                    id={Status}
                    name={Status}
                    placeholder={t('calendar.newAppointmentSidebar.appointmentStatus')}
                    currentOption={selectedStatusIndex}
                    options={options}
                    onChange={index => {
                      setSelectedStatusIndex(index);
                      setFieldValue(Status, index);
                    }}
                    disabled={isPastMode || status === EventStatus.NOSHOW}
                  />
                )}

                {selectedAppointmentData?.isGoogle && (
                  <>
                    <H6 mt="8px">{t('calendar.newAppointmentSidebar.details.address')}</H6>
                    <FormikInput
                      id={Address}
                      name={Address}
                      placeholder={t('calendar.newAppointmentSidebar.addressSearch')}
                      iconLeft={<SearchIcon />}
                      design="white"
                      searchInput
                      currentOption={
                        addressOptions.length === 1 ? 0 : addressOptions.findIndex(item => item.id === selectedAddress)
                      }
                      options={addressOptions}
                      type="service"
                      disabled
                    />
                  </>
                )}

                {(isMobile &&
                  (!isEditClient && selectedClient <= 0 ? (
                    <Button
                      design="secondary"
                      onClick={() => {
                        setEditClient(true);
                        setClientFormOpen(true);
                      }}
                    >
                      {t('calendar.newAppointmentSidebar.chooseClient')}
                    </Button>
                  ) : (
                    <Form>
                      <ClientForm
                        clients={clientsOptionsList}
                        selectedClient={selectedClient}
                        onSelect={(index: number) => {
                          if (clientsOptionsList[index].id) {
                            setFieldValue(Client, clientsOptionsList[index].id);
                            setSelectedClient(index);
                          } else setNewClient(true);
                        }}
                        onDelete={() => {
                          setFieldValue(Client, '');
                          setSelectedClient(-1);
                          setEditClient(true);
                          setClientFormOpen(true);
                        }}
                        disabled={isEditMode && !isGoogle}
                        onAddNewClient={() => setNewClient(true)}
                      />
                    </Form>
                  ))) || (
                  <>
                    <H6 mt="8px">{t('calendar.newAppointmentSidebar.client')}</H6>
                    <FormikInput
                      id={Client}
                      name={Client}
                      placeholder={
                        clientsOptionsList.length === 0
                          ? t('calendar.newAppointmentSidebar.addNewClient')
                          : t('calendar.newAppointmentSidebar.clientSearch')
                      }
                      iconLeft={<SearchIcon />}
                      design="white"
                      buttonLabel={isUserAdmin ? t('calendar.newAppointmentSidebar.addNew') : undefined}
                      onButtonClick={isUserAdmin ? onAddButtonClick : undefined}
                      searchInput
                      currentOption={selectedClient}
                      options={clientsOptionsList}
                      onSelect={(index: number) => {
                        setFieldValue(Client, clientsOptionsList[index].id);
                        setSelectedClient(index);
                      }}
                      onDeleteIconClick={() => {
                        setFieldValue(Client, '');
                        setSelectedClient(-1);
                      }}
                      type="client"
                      disabled={isEditMode && !isGoogle}
                    />
                  </>
                )}

                {isMobile && !isService && selectedClient !== -1 && (
                  <Button design="secondary" onClick={() => setEditService(true)}>
                    {t('calendar.newAppointmentSidebar.chooseService')}
                  </Button>
                )}

                {isMobile && values[Service] && isService && (
                  <>
                    <H6 mt="8px">{t('calendar.newAppointmentSidebar.service')}</H6>
                    <ServiceCard
                      timing={`${timeInfo} • ${moment(values[End], HHmm).diff(
                        moment(values[Start], HHmm),
                        'minute',
                      )} ${t('time.min')}`}
                      service={currentService?.title}
                      avatarUrl={currentSpecialist?.avatarUrl}
                      specialist={currentSpecialist?.name}
                      price={currentService?.price}
                      onDeleteClick={() => {
                        setFieldValue(Service, '');
                        setFieldValue(Specialist, '');
                        setFieldValue(Start, '');
                        setStartTime(-1);
                        setFieldValue(End, '');
                        setEndTime(-1);
                        setEmptyDate(true);
                        setIsService(false);
                        setEditService(true);
                      }}
                      onEditClick={() => {
                        setIsService(false);
                        setEditService(true);
                      }}
                      isInfoMode={isInfoMode || isUnclosed}
                    />
                  </>
                )}

                {isDesktop && (
                  <>
                    <H6 mt="8px">{t('calendar.newAppointmentSidebar.service')}</H6>
                    {values[Service] && isService ? (
                      <ServiceCard
                        timing={`${timeInfo} • ${moment(values[End], HHmm).diff(
                          moment(values[Start], HHmm),
                          'minute',
                        )} ${t('time.min')}`}
                        service={currentService?.title}
                        avatarUrl={currentSpecialist?.avatarUrl}
                        specialist={currentSpecialist?.name}
                        price={currentService?.price}
                        onDeleteClick={() => {
                          setFieldValue(Service, '');
                          setFieldValue(Specialist, '');
                          setFieldValue(Start, '');
                          setStartTime(-1);
                          setFieldValue(End, '');
                          setEndTime(-1);
                          setEmptyDate(true);
                          setIsService(false);
                          isMobile && setEditService(true);
                        }}
                        onEditClick={() => {
                          setIsService(false);
                          isMobile && setEditService(true);
                        }}
                        isInfoMode={isInfoMode || isUnclosed}
                      />
                    ) : (
                      <ServiceForm
                        serviceOptionsList={filteredServices}
                        specialistOptionsList={filteredSpecialists}
                        infoMode={isInfoMode || isUnclosed}
                        timeList={timeList}
                        organisationWorkTime={organisationWorkTime}
                        selectedWeekday={selectedWeekday}
                        selectedAppointmentData={selectedAppointmentData}
                        extendedOrganisationClients={extendedOrganisationClients}
                        organisationSpecialists={organisationSpecialists}
                        setIsService={setIsService}
                        isEmptyDate={isEmptyDate && !isAddFromMenu}
                        setEmptyDate={setEmptyDate}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                        setTextfield={setTextfield}
                        isEditService={isEditService}
                        setEditService={setEditService}
                        dateFromMenu={dateFromMenu}
                        isValid={!getFieldMeta(Start).error && !getFieldMeta(End).error}
                      />
                    )}
                  </>
                )}

                {(((values[Notes] || isTextfield) && isService) || isGoogle) && (
                  <Flex flexDirection="column" gap="16px" ref={notesRef}>
                    <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.notes')}</H6>
                    <FormikTextfield
                      id={Notes}
                      name={Notes}
                      design="white"
                      placeholder={t('calendar.newAppointmentSidebar.typeNotes')}
                      rows={5}
                      infoCaption={`${notes.length}/1000`}
                      isCaptionRight
                      disabled={isInfoMode || isUnclosed}
                    />
                  </Flex>
                )}
                {isShowAddNotesButton && !values[Notes] && (
                  <Button
                    width="100%"
                    design="secondary"
                    type="button"
                    suffix={<MessageIcon />}
                    onClick={() => setTextfield(true)}
                  >
                    {t('button.addNotes')}
                  </Button>
                )}

                {showNegotiatedPrice && values[Status] < 5 && <NegotiatedPriceInput sign={currencySign} />}

                {showPaymentInfo && (
                  <PaymentContainer
                    title={t('payment.paymentInformation')}
                    total={t('payment.total')}
                    items={[
                      {
                        label: t('payment.pending'),
                        value: showNegotiatedPrice ? values[Price] : currentService?.price,
                      },
                    ]}
                    totalValue={showNegotiatedPrice ? values[Price] : currentService?.price}
                  />
                )}
                {showPaymentMethod && <PaymentMethodRadio isCash={isCash} setIsCash={setIsCash} />}
              </FormWrapper>
            </Form>
          );

          return !isAppointmentDetails && isVisible ? (
            (isDesktop && <Sidebar {...sidebarProps}>{sidebarForm}</Sidebar>) || (
              <>
                <BottomSheet {...sidebarProps} content={sidebarForm} />
                {(isEditClient || selectedClient <= 0) && (
                  <>
                    <BottomSheet
                      content={clientForm}
                      isOpen={isClientFormOpen}
                      onClose={handleClientFormClose}
                      handleClose={handleClientFormClose}
                      label={t('calendar.newAppointmentSidebar.chooseClientForAppointment')}
                      descriptor={t('calendar.newAppointmentSidebar.mainInformation')}
                    />
                    {isNewClient && <NewClientSidebar onClose={() => setNewClient(false)} />}
                  </>
                )}
                {isEditService && (
                  <BottomSheet
                    content={serviceForm}
                    isOpen
                    onClose={handleServiceFormClose}
                    handleClose={handleServiceFormClose}
                    label={t('calendar.newAppointmentSidebar.chooseServiceForAppointment')}
                    descriptor={t('calendar.newAppointmentSidebar.mainInformation')}
                  />
                )}
              </>
            )
          ) : (
            <>
              <AppointmentDetailsSidebar
                date={values[AppointmentDate]}
                time={values[End]}
                company={company as Company}
                customer={clientInfo}
                customerId={(selectedAppointmentData as AppointmentByIdResponse).client.id}
                paymentMethod={{
                  typeOfSale: PaymentType.CASH.charAt(0) + PaymentType.CASH.slice(1).toLowerCase(),
                  cardNumber: (selectedAppointmentData as AppointmentByIdResponse).cardNumber,
                }}
                order={[
                  {
                    service: currentService?.title || '',
                    value: actualPrice,
                  },
                ]}
                payment={{
                  subtotal: actualPrice,
                  total: actualPrice,
                }}
                handleClose={handleCloseAppointmentDetails}
                onOpenReview={() => setIsReview(true)}
                isReviewed={(selectedAppointmentData as AppointmentByIdResponse).clientReviewed}
                status={(selectedAppointmentData as AppointmentByIdResponse).status}
              />
              {isReview && (
                <RatingSidebar
                  appointmentId={selectedAppointmentData?.id as string}
                  date={values[AppointmentDate]}
                  address={(company as Company).address}
                  client={clientInfo}
                  headOrgClientId={
                    clients?.find(client => client.id === (selectedAppointmentData as AppointmentByIdResponse).clientId)
                      ?.headOrgClientId as string
                  }
                  setOpen={setIsReview}
                  handleClose={handleCloseAppointmentDetails}
                />
              )}
            </>
          );
        }}
      </Formik>
      {isConfirmationModalVisible &&
        ((isDesktop && (
          <Popup
            title={t('calendar.confirmation.cancellingAppointment')}
            description={t('calendar.confirmation.doYouWantToCancelAppointment')}
            onClose={() => setIsConfirmationModalVisible(false)}
            onSubmit={handleAppointmentCancel}
            isLoading={isSubmitting}
            confirm={t('button.confirm')}
            cancel={t('button.close')}
          />
        )) || (
          <BottomSheet
            isOpen
            label={t('calendar.confirmation.cancellingAppointment')}
            descriptor={t('calendar.confirmation.doYouWantToCancelAppointment')}
            onClose={handleConfirmationModalClose}
            handleClose={handleConfirmationModalClose}
            FooterBody={
              <Flex width="100%" gap="8px" flexDirection="column">
                <Button
                  design="secondary"
                  size="large"
                  width="100%"
                  onClick={() => setIsConfirmationModalVisible(false)}
                  disabled={isSubmitting}
                >
                  {t('button.close')}
                </Button>
                <Button size="large" width="100%" onClick={handleAppointmentCancel} disabled={isSubmitting}>
                  {isSubmitting ? <Loader type="spinner" /> : t('button.confirm')}
                </Button>
              </Flex>
            }
            detent="content-height"
            shrinkContent
          />
        ))}
    </>
  );
};
