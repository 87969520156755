import { useEffect, useState } from 'react';
import { GeolocationType } from 'types';
import { mapCenter } from '../constants';

export const useGeolocation = (ask: boolean, callback: (values: GeolocationType) => void) => {
  const [askedLocation, setAskedLocation] = useState(ask);

  useEffect(() => {
    if (!askedLocation) {
      const handleError = () => {
        callback({ lat: mapCenter.lat, lng: mapCenter.lng });
        setAskedLocation(true);
      };

      const askGeolocation = () =>
        navigator.geolocation.getCurrentPosition(position => {
          callback({ lat: position.coords.latitude, lng: position.coords.longitude });

          setAskedLocation(true);
        }, handleError);

      navigator?.geolocation ? askGeolocation() : handleError();
    }
  }, [askedLocation, callback]);

  return {
    askedLocation,
  };
};
