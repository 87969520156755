import { Nullable } from 'tsdef';
import { AccessLevel } from './specialist';

export enum Language {
  English = 'EN',
  Hebrew = 'HE',
  Russian = 'RU',
  Ukrainian = 'UA',
  French = 'FR',
  German = 'DE',
  Spanish = 'ES',
  Arabic = 'AR',
  Amharic = 'AM',
}

export type Text = {
  id: string;
  langId: string;
  text: string;
  createdAt?: string;
};

export type TextWithTranslations = {
  translation?: Text[] | [];
} & Text;

export type TopCategory = {
  id: string;
  title: TextWithTranslations;
};

export type IconType = {
  icon: JSX.Element;
  name: string;
  id: string;
};

export type OptionType = {
  id: string;
  value: string;
  icon?: JSX.Element;
  caption?: string;
  disabled?: boolean;
};

export type FormPropsType = {
  editBar?: boolean;
  title?: string;
  showTitle?: boolean;
  showCaption?: boolean;
  setIsValid?: (value: boolean) => void;
  setIsDirty?: (value: boolean) => void;
};

export type SidebarStepType = {
  handleNextStep: () => void;
  handleClose: () => void;
  handleBackStep: () => void;
  stepper: JSX.Element;
};

export type PhotoType = {
  id: string;
  url: string;
  organizationId: string;
  headOrgServiceId: string | null;
};

export type MenuItemType = {
  id: number;
  item: string;
  link: string;
  disabled: boolean;
  accessRange: AccessLevel[];
};

export type SidebarPropsType = {
  onSubmit?: () => void;
  onClose?: () => void;
  onBack?: () => void;
};

export type ImageType = string | File;

export type GenderType = 'male' | 'female';

export enum SocialNetwork {
  Instagram = 'INSTAGRAM',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
}

export enum PaymentType {
  CASH = 'CASH',
  CARD = 'CARD',
}

export type Order = Array<{ service: string; value: string }>;

export type Payment = {
  subtotal: string;
  total: string;
  tax?: string;
};

export type PaymentMethod = {
  typeOfSale: string;
  cardNumber?: string;
};

export type Person = {
  name: string;
  surname?: string;
  avatarUrl?: string | null;
};

export type Contact = {
  code: string;
  number: string;
  email?: string;
  addCode?: string | null;
  addNumber?: string | null;
};

export type Document = {
  id: string;
  url: string;
};
export type PhotosUpdate = {
  photos: ImageType[];
  deletePhotos: ImageType[];
};

export type Education = {
  id: string;
  startYear: number;
  endYear: number;
  field: string;
  school: string;
  degree: string;
  file?: string;
  fileName?: string | null;
};

export enum Level {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
  Native = 'NATIVE',
}

export type SpokenLanguage = {
  level: Level;
  language: Language;
};

export type UsedLocales = 'ru' | 'he';

export type CreatedBy = 'MARKET' | 'CRM';

export type SortOrder = Nullable<'asc' | 'desc'>;
