import { createPortal } from 'react-dom';
import { PlacesType } from 'react-tooltip';
import { Caption } from '@beauty/beauty-market-ui';
import { StyledTooltip } from './style';

type TooltipPropsType = {
  id: string;
  content: string[] | string;
  children: JSX.Element | null;
  place?: PlacesType;
  show?: boolean;
  offset?: number;
};

export const Tooltip = ({ id, children, content, show = true, place = 'top', offset = 10 }: TooltipPropsType) => {
  const contentArray = Array.isArray(content) ? content : [content];
  return (
    <>
      {children}
      {show &&
        createPortal(
          <StyledTooltip id={id} place={place} offset={offset}>
            {contentArray.map(message => (
              <Caption key={message} large>
                {message}
              </Caption>
            ))}
          </StyledTooltip>,
          document.getElementById('root') as HTMLElement,
        )}
    </>
  );
};
