import styled from 'styled-components';
import { colors, Flex } from '@beauty/beauty-market-ui';

export const ItemFlex = styled(Flex)`
  box-sizing: border-box;
  height: 32px;
  padding: 6px 30px;
  justify-content: space-between;

  p {
    color: ${colors.grey.dark};
  }

  span {
    color: #404040;
  }

  &:hover {
    p,
    span {
      color: ${colors.blue.light};
    }

    cursor: pointer;
  }

  &:active {
    p,
    span {
      color: ${colors.white.standard};
    }

    p {
      border-bottom: 1px solid ${colors.white.standard};
    }
  }
`;

export const MenuItemListWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  padding-bottom: 8px;
`;
