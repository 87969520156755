import { useFormikContext } from 'formik';
import { Button } from '@beauty/beauty-market-ui';
import { SidebarSheet } from 'components/index';
import { AsyncComponent } from 'hoc';
import { EditHeadOrganisationFormFields, EditHeadOrganisationFormTypes } from './EditHeadOrganisation.definitions';
import { GeneralInformationForm } from './GeneralInformationForm';
import { StyledArrowRightIcon } from './style';

export const GeneralInformation = ({
  label,
  descriptor,
  onSubmit,
  onClose,
  buttonLabel,
  fields,
  topCategories,
  currencyOptions,
  isLoading,
}) => {
  const { values, errors } = useFormikContext<EditHeadOrganisationFormTypes>();
  const { Code, Phone, Email, Name, Category, RegistrationId, Photo, Currency } = EditHeadOrganisationFormFields;

  const hasValues =
    values[Code] && values[Phone] && values[Email] && values[Name] && values[RegistrationId] && values[Category].length;
  const hasErrors =
    errors[Code] ||
    errors[Phone] ||
    errors[Email] ||
    errors[Name] ||
    errors[RegistrationId] ||
    errors[Category] ||
    errors[Photo] ||
    errors[Currency];

  return (
    <SidebarSheet
      label={label}
      descriptor={descriptor}
      onClose={onClose}
      FooterBody={
        <Button
          design="primary"
          type="submit"
          width="100%"
          size="large"
          suffix={<StyledArrowRightIcon width="20" height="20" />}
          onClick={onSubmit}
          disabled={hasErrors || !hasValues || isLoading}
        >
          {buttonLabel}
        </Button>
      }
    >
      <AsyncComponent isLoading={isLoading}>
        <GeneralInformationForm fields={fields} currencyOptions={currencyOptions} topCategories={topCategories} />
      </AsyncComponent>
    </SidebarSheet>
  );
};
