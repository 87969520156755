import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { SidebarFooter, SidebarSheet } from 'components/index';
import { organisationAPI } from 'helpers';
import { Overlay } from 'hoc';
import { useGeolocation } from 'hooks';
import { parseAddress } from 'store/utils';
import { GeolocationType } from 'types';
import { getSelectedLanguage, GoogleStatusCode, mapCenter } from '../../../../constants';
import { EditHeadOrganisationFormFields, EditHeadOrganisationFormTypes } from './EditHeadOrganisation.definitions';
import { LocationForm } from './LocationForm';

export const Location = ({ label, descriptor, onClose, onBack, title }) => {
  const { t } = useTranslation();
  const { handleSubmit, setFieldValue, initialValues, isSubmitting, isValid, dirty, values } =
    useFormikContext<EditHeadOrganisationFormTypes>();
  const { Latitude, Longitude, Country, City, Building, Street, Post, Description, Office, FullAddress } =
    EditHeadOrganisationFormFields;
  const language = getSelectedLanguage();

  const onChangeLocation = async ({ lat, lng }: GeolocationType) => {
    await setFieldValue(Longitude, lng);
    await setFieldValue(Latitude, lat);

    if ((lat === mapCenter.lat && lng === mapCenter.lng) || (lat === values[Latitude] && lng === values[Longitude]))
      return;

    const response = await organisationAPI.fetchAddress({ lat, lng, language });
    if (response && response.status === GoogleStatusCode.OK) {
      const address = {
        country: values[Country],
        city: values[City],
        street: values[Street],
        building: values[Building],
        postal: values[Post],
        office: values[Office],
        description: values[Description],
        fullAddress: values[FullAddress],
      };
      const preFieldAddress = parseAddress(response.results[0], address);
      Object.entries(preFieldAddress).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
    }
  };

  const { askedLocation } = useGeolocation(
    initialValues[Latitude] !== null && initialValues[Longitude] !== null,
    onChangeLocation,
  );

  if (!askedLocation) return <Overlay show={!askedLocation} />;

  return (
    <SidebarSheet
      label={label}
      descriptor={descriptor}
      onClose={onClose}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onBack}
          confirmationLabel={t('button.save')}
          cancelLabel={t('button.back')}
          isLoading={isSubmitting}
          disabled={!isValid || !dirty}
        />
      }
    >
      <LocationForm title={title} />
    </SidebarSheet>
  );
};
