import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, Caption, colors, Dropdown, Flex, Icon, TrashIcon } from '@beauty/beauty-market-ui';
import { useTimeList } from 'hooks';
import { Head } from './style';

type DropTimeProps = {
  title: string;
  start: number;
  end: number;
  onChangeStart: (index: number) => void;
  onChangeEnd: (index: number) => void;
  onDelete: () => void;
  error?: string | null;
  children?: ReactNode;
};

const DropTime = ({ title, start, end, onChangeStart, onChangeEnd, onDelete, error, children }: DropTimeProps) => {
  const { t } = useTranslation();
  const timeList = useTimeList();
  return (
    <Flex flexDirection="column" gap="8px">
      <Head>
        <BodySmall lowLine color={colors.grey.dark}>
          {title}
        </BodySmall>
        <Icon stroke={colors.grey.dark} onClick={onDelete} cursor="pointer">
          <TrashIcon />
        </Icon>
      </Head>
      {children}
      <Flex gap="16px">
        <Dropdown
          isSeparator
          options={timeList}
          placeholder={t('time.start')}
          currentOption={start}
          onChange={onChangeStart}
          // 28 is 07 - 00
          scrollTo={28}
          autoScroll
        />
        <Dropdown
          isSeparator
          options={timeList}
          placeholder={t('time.end')}
          currentOption={end}
          onChange={onChangeEnd}
          // 28 is 07 - 00
          scrollTo={28}
          autoScroll
        />
      </Flex>
      <Caption color={colors.red.standard} pl="4px" textAlign="center">
        {error}
      </Caption>
    </Flex>
  );
};

export default DropTime;
