import { BodyLarge, BodySmall, Checkbox, colors, Flex } from '@beauty/beauty-market-ui';
import { BorderedPaper } from './style';

type AdditionalToolProps = {
  title: string;
  subtitle: string;
  price: string;
  checked: boolean;
  onToggle?: () => void;
};

export const AdditionalTool = ({ title, subtitle, price, checked, onToggle }: AdditionalToolProps) => (
  <BorderedPaper flexDirection="row" alignItems="center" justifyContent="space-between">
    <Flex flexDirection="column" width="max-content" gap="4px">
      <BodySmall lowline large>
        {title}
      </BodySmall>
      <BodySmall color={colors.grey.dark}>{subtitle}</BodySmall>
    </Flex>
    <Flex gap="16px" flexShrink={0}>
      <BodyLarge large>{price}</BodyLarge>
      <Checkbox
        checked={checked}
        onCheckboxClick={e => (onToggle ? onToggle() : e.currentTarget.stopPropagation())}
        shape="round"
      />
    </Flex>
  </BorderedPaper>
);
