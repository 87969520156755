import { useEffect, useState } from 'react';
import { getTranslation } from 'helpers';
import { apiRequest } from '../api/api';
import { ServicesEndpoints } from '../api/endpoints/endpoints';
import { ServiceIcons, getSelectedLanguage, orderedTopCategories } from '../constants';
import { IconType, TopCategory } from '../types/general';

export const useGetTopCategories = () => {
  const language = getSelectedLanguage();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [topCategoriesData, setTopCategoriesData] = useState<IconType[]>([]);

  useEffect(() => {
    const fetchTopCategories = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: ServicesEndpoints.TopCategories,
      });

      if (error?.data) alert('Error with loading top categories');

      if (response) {
        const categoriesList: TopCategory[] = response.data;
        const topCategories = orderedTopCategories.map((category: string) => {
          const currentCategory = categoriesList.find(cat => cat.title.text === category) as TopCategory;
          return {
            icon: currentCategory.title.text ? ServiceIcons[currentCategory.title.text] : null,
            name: currentCategory.title ? getTranslation(currentCategory.title, language) : '',
            id: currentCategory.id,
          };
        });
        setTopCategoriesData(topCategories);
      }
    };

    setIsLoading(true);
    fetchTopCategories().finally(() => setIsLoading(false));

    return setIsLoading(false);
  }, [language]);

  return {
    isLoading,
    topCategoriesData,
  };
};
