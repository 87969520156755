import { MouseEvent, useCallback, useState } from 'react';
import { Nullable } from 'tsdef';
import { getSelectedLanguage } from '../../../../../constants';
import { DateMode, formatDate, getDMY } from './helpers';

export const useDateSelector = ({ placeholder, onChangeRange }) => {
  const language = getSelectedLanguage();

  const [date, setDate] = useState('');
  const [mode, setMode] = useState<DateMode>(DateMode.Day);

  const onChangeDate = useCallback(
    (value: [Nullable<string>, Nullable<string>]) => {
      const isRange = Array.isArray(value);
      if (isRange && mode === DateMode.Range) {
        setDate(formatDate(value, language));
        onChangeRange(value);
      } else if (value && mode === DateMode.Day) {
        const newValue = isRange ? value[0] : value;
        const newDate = newValue ? new Date(newValue).toLocaleDateString() : '';
        setDate(newDate);
        onChangeRange([newValue, newValue]);
      } else {
        setDate('');
        onChangeRange([placeholder, placeholder]);
      }
    },
    [getDMY, setDate, onChangeRange, mode, placeholder, language],
  );

  const onResetDate = useCallback(
    (e: MouseEvent<HTMLOrSVGElement>) => {
      e.stopPropagation();
      setDate('');
      onChangeRange([placeholder, placeholder]);
    },
    [onChangeRange, placeholder, setDate],
  );

  return {
    open,
    mode,
    date,
    onResetDate,
    onChangeDate,
    onChangeMode: setMode,
    locale: language.toLowerCase(),
  };
};
