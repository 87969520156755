import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Caption,
  colors,
  Flex,
  H6,
  UploadPhotos,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { checkPhotosValid, hasArrayChanged } from 'helpers';
import { ImageType, PhotosUpdate } from 'types';
import { MAX_PHOTO_EMPLOYEE_COUNT } from '../../../../../constants';

type EditPhotoGalleryProps = {
  defaultValues: string[];
  onSubmit: (data: PhotosUpdate) => Promise<unknown>;
  onClose: () => void;
};

export const EditPhotoGallery = ({ defaultValues, onSubmit, onClose }: EditPhotoGalleryProps) => {
  const { t } = useTranslation();

  const [photos, setPhotos] = useState<ImageType[]>(defaultValues);
  const [deletePhotos, setDeletePhotos] = useState<ImageType[]>([]);
  const [error, setError] = useState<string | null>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleUploadPhotos = (images: ImageType[]) => {
    const updatedPhotos = [...photos, ...images];
    const errorMessage = checkPhotosValid(updatedPhotos, t, MAX_PHOTO_EMPLOYEE_COUNT);
    setDirty(true);
    setError(errorMessage);
    setPhotos(updatedPhotos);
  };

  const handleDeletePhoto = (photoToDelete: ImageType) => {
    const filteredPhotos = photos.filter(photo => photo !== photoToDelete);
    const errorMessage = checkPhotosValid(filteredPhotos, t, MAX_PHOTO_EMPLOYEE_COUNT);
    const dirty = hasArrayChanged(filteredPhotos, defaultValues);

    setError(errorMessage);
    setDirty(dirty);
    setPhotos(filteredPhotos);
    setDeletePhotos([...deletePhotos, photoToDelete]);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    onSubmit({ photos, deletePhotos })
      .then(onClose)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const isPreventExtension = error === null || !!error;
  const sidebarFooter = (
    <SidebarFooter
      disabled={!!error || !isDirty}
      isLoading={isSubmitting}
      onBack={onClose}
      onSubmit={handleSubmit}
      confirmationLabel={t('button.save')}
      cancelLabel={t('button.cancel')}
    />
  );

  return (
    <SidebarSheet
      label={t('specialists.personalInfo.employeeGallery')}
      descriptor={t('specialists.personalInfo.addPhotosOfEmployee')}
      FooterBody={sidebarFooter}
      onClose={onClose}
      onBack={onClose}
    >
      <Flex gap="16px" flexDirection="column" mb="80px">
        <H6>{t('specialists.personalInfo.projectPhotos')}</H6>
        <UploadPhotos
          images={photos}
          max={MAX_PHOTO_EMPLOYEE_COUNT}
          onUpload={handleUploadPhotos}
          onDelete={handleDeletePhoto}
          preventExtension={isPreventExtension}
          mode={isDesktop ? 'hover' : 'double-tap'}
        />
        {error && <Caption color={colors.red.standard}>{error}</Caption>}
      </Flex>
    </SidebarSheet>
  );
};
