import { BodySmall, Loader as LoaderUI } from '@beauty/beauty-market-ui';
import { Dot, LoaderWrapper, Spinner } from './style';

type LoaderProps = {
  type?: 'text' | 'spinner';
  color?: string;
  size?: string;
  text?: string;
};

export const Loader = ({ type, color, size = '24px', text }: LoaderProps) => {
  switch (type) {
    case 'spinner':
      return <Spinner color={color} size={size} />;
    case 'text':
      return (
        <LoaderWrapper>
          <Spinner color={color} size={size} />
          <BodySmall color={color}>
            {text}
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
          </BodySmall>
        </LoaderWrapper>
      );
    default:
      return (
        <LoaderWrapper>
          <LoaderUI />
        </LoaderWrapper>
      );
  }
};
