import { Nullable } from 'tsdef';

export const getDMY = (value: Nullable<string>, language: string) => {
  if (!value) {
    return { year: undefined, month: null, day: undefined };
  }

  const date = new Date(value);
  const formatter = new Intl.DateTimeFormat(language, { month: 'short' });
  return {
    year: date.getFullYear(),
    month: formatter.format(date),
    day: date.getDate(),
  };
};

export const formatDate = (value: [Nullable<string>, Nullable<string>], language: string): string => {
  const start = getDMY(value[0], language);
  const end = getDMY(value[1], language);
  return start.year === end.year
    ? `${start.day} ${start.month} - ${end.day} ${end.month}, ${start.year}`
    : `${start.day} ${start.month}, ${start.year} - ${end.day} ${end.month}, ${end.year}`;
};

export enum DateMode {
  Day,
  Range,
}
